import { AsyncPipe, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { AppointmentService } from "@cg/appointment-ui";
import { ServiceCenter, ServiceCenterLocation } from "@cg/core/interfaces";
import { RoundCornerTileComponent } from "@cg/core/ui";
import { GoogleMapsComponent } from "@cg/locations";
import { ContentCard, ContentCardComponent, CustomerCase } from "@cg/shared";
import { DetailFacade } from "../../../../+state/detail.facade";
import { DetailService, MyCarglassTrackingService } from "../../../../services";
import { getDateChanged$, getLocationChanged$ } from "../../../../utils";
import { MyCarglassBadgeComponent } from "../my-carglass-badge/my-carglass-badge.component";
import { MyCarglassServiceCenterOpeningsHoursComponent } from "./components/my-carglass-service-center-openings-hours/my-carglass-service-center-openings-hours.component";
import { ContentTemplateEnum } from "./enum/content-template.enum";

@Component({
  selector: "cg-my-carglass-booked-appointment",
  templateUrl: "./my-carglass-booked-appointment.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    RoundCornerTileComponent,
    MyCarglassBadgeComponent,
    ContentCardComponent,
    GoogleMapsComponent,
    MyCarglassServiceCenterOpeningsHoursComponent
  ]
})
export class MyCarglassBookedAppointmentComponent implements OnInit, OnChanges {
  private readonly appointmentService = inject(AppointmentService);
  private readonly detailService = inject(DetailService);
  private readonly trackingService = inject(MyCarglassTrackingService);
  private readonly detailFacade = inject(DetailFacade);

  @Input()
  public customerCase: CustomerCase;
  @ViewChild("locationRoutePlanner", { read: TemplateRef }) private locationRoutePlanner: TemplateRef<unknown>;
  @ViewChild("addToCalendar", { read: TemplateRef }) private addToCalendar: TemplateRef<unknown>;
  @ViewChild("openingHours", { read: TemplateRef }) private openingHours: TemplateRef<unknown>;

  public destroyRef = inject(DestroyRef);
  public contentCards: ContentCard[];

  public googleMapsRouteHref: string;
  public calendarHref: string;
  public serviceCenterLocation$: Observable<ServiceCenterLocation>;

  public get isMobile$(): Observable<boolean> {
    return this.detailService.isMobile$;
  }

  public hasDateChanged$ = getDateChanged$(this.detailFacade);

  public hasLocationChanged$ = getLocationChanged$(this.detailFacade);

  public ngOnInit(): void {
    this.createContentCards();
    this.googleMapsRouteHref = this.appointmentService.buildGoogleMapsRouteHref(
      this.customerCase.appointment.street,
      this.customerCase.appointment.city,
      true
    );
    this.calendarHref = this.appointmentService.buildCalendarHref({ appointmentId: this.customerCase.appointment.id });

    this.serviceCenterLocation$ = this.detailFacade.serviceCenterDetails$.pipe(
      filter((selectedServiceCenter: ServiceCenter) => !!selectedServiceCenter),
      map(
        (selectedServiceCenter: ServiceCenter) =>
          ({
            latitude: selectedServiceCenter.coordinates.latitude,
            longitude: selectedServiceCenter.coordinates.longitude,
            costCenter: selectedServiceCenter.costCenter,
            street: selectedServiceCenter.address.street,
            streetNumber: selectedServiceCenter.address.streetNumber,
            postalCode: selectedServiceCenter.address.postalCode,
            city: selectedServiceCenter.address.city
          }) as ServiceCenterLocation
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.customerCase) {
      return;
    }

    this.customerCase = changes.customerCase.currentValue;
    this.createContentCards();
  }

  public trackRoutePlaningEvent(eventLabel: string): void {
    this.trackingService.trackFieldEvent({ eventLabel, eventAction: "route-planning" });
  }

  public trackCalendarEvent(eventLabel: string): void {
    this.trackingService.trackFieldEvent({ eventLabel, eventAction: "save-to-calendar" });
  }

  private createContentCards(): void {
    const dateContentCard = this.detailService.createDateContentCard(this.customerCase, true);
    const locationContentCard = this.detailService.createLocationContentCard(this.customerCase, false);
    const openingHoursContentCard = this.detailService.createOpeningHoursContentCard();
    this.contentCards = [dateContentCard, locationContentCard, openingHoursContentCard];
  }

  public getContentTemplate(info: ContentCard): TemplateRef<unknown> {
    switch (info.id) {
      case ContentTemplateEnum.LOCATION:
        return this.locationRoutePlanner;
      case ContentTemplateEnum.DATE:
        return this.addToCalendar;
      default:
        return this.openingHours;
    }
  }
}
