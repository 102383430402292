<div data-cy="content-block-list-wrapper">
  <div
    class="mt-1 flex flex-row flex-wrap justify-between gap-3 t:mt-8 t:gap-6 d:gap-12"
    [ngClass]="{ 'justify-start': content.items.length === 1 }"
  >
    @for (item of content.items; track $index) {
      <cg-list-element class="box-border w-full max-w-content t:w-1/2" data-cy="column" [content]="item">
      </cg-list-element>
    }
  </div>
</div>
