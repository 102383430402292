<div class="mx-auto flex max-w-container flex-wrap" data-cy="outer-container">
  @for (item of content?.items; track $index) {
    <div class="content-block-contact-container" data-cy="content-block-contact-container">
      @if (!item?.paragraph.text.includes("[OPENING_HOURS]")) {
        <div class="icon-wrapper" data-cy="icon-wrapper">
          <cg-icon class="icon" [content]="item?.icon"></cg-icon>
        </div>
        <div class="text-wrapper">
          <cg-headline class="headline" [type]="headlineType.h3" [content]="item?.headline"></cg-headline>
          <cg-richtext [content]="item?.paragraph"></cg-richtext>
          <cg-list [content]="item?.list"></cg-list>
          @if (item?.cta) {
            <cg-cta [content]="item?.cta"></cg-cta>
          }
        </div>
      } @else {
        <cg-ccc-phone-info phoneInfoTextKey="cccOpeningHours.byPhone.contact"></cg-ccc-phone-info>
      }
      <ng-template #cccOpeningHours>
        <cg-ccc-phone-info phoneInfoTextKey="cccOpeningHours.byPhone.contact"></cg-ccc-phone-info>
      </ng-template>
    </div>
  }
</div>
