import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from "@angular/core";
import { ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { stepper1Icon, stepper2Icon, stepper3Icon } from "@cg/icon";
import { OlbHeadlineComponent, ScrollService } from "@cg/olb/shared";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { ChannelSwitchBaseComponent } from "../channel-switch-base/channel-switch-base.component";

@Component({
  selector: "cg-channel-switch-call-timeout",
  templateUrl: "./channel-switch-call-timeout.component.html",
  styleUrls: ["./channel-switch-call-timeout.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, OlbHeadlineComponent, ParagraphComponent, IconComponent]
})
export class ChannelSwitchCallTimeoutComponent extends ChannelSwitchBaseComponent {
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly trackingService = inject(TrackingService);
  protected readonly scrollService = inject(ScrollService);

  public icons = [
    {
      ...stepper1Icon,
      color: "#979797"
    },
    {
      ...stepper2Icon,
      color: "#979797"
    },
    {
      ...stepper3Icon,
      color: "#979797"
    }
  ];
}
