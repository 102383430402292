import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { Link } from "@cg/content-api/typescript-interfaces";
import { EnvironmentService } from "@cg/environments";

@Component({
  selector: "cg-meta-navigation",
  templateUrl: "./meta-navigation.component.html",
  styleUrls: ["./meta-navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass]
})
export class MetaNavigationComponent {
  private readonly environmentService = inject(EnvironmentService);

  @Input()
  public metaNavigation: (Link & { onclick? })[];

  @Input()
  public metaNavClass: string;

  @Input()
  public route: string;

  @Input()
  public hasError = false;

  public isSubLevelActive = false;
  public appName = this.environmentService.env.brand;

  public isActive(item: string): boolean {
    if (!item || !this.route) {
      return;
    }

    const routeLevels = this.route.split("/");
    const isRootPage = routeLevels[1] === "autoglas" && item === "/";

    this.isSubLevelActive = item.includes("jobs") && routeLevels.includes("jobs");

    return routeLevels[1] === item.slice(1) || isRootPage;
  }
}
