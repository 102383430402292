<cg-drawer (closedClicked)="onClosed()" [headline]="headline" [footer]="footer" [content]="content"></cg-drawer>
<ng-template #headline>
  <cg-olb-headline [title]="headlineTranslation" [attr.data-cy]="'insurance-base-message-headline'"></cg-olb-headline>
</ng-template>
<ng-template #content>
  @if (subHeadlineKey) {
    <cg-olb-headline
      [title]="subHeadlineKey | transloco"
      type="h2"
      [attr.data-cy]="'insurance-base-message-subHeadline'"
    ></cg-olb-headline>
  }
  @if (descriptionKey) {
    <cg-paragraph
      [content]="{
        text: descriptionKey | transloco,
        template: 'cgParagraph'
      }"
      [attr.data-cy]="'insurance-base-message-description'"
    ></cg-paragraph>
  }
  <div
    class="mt-6 flex flex-col items-start gap-6 self-stretch d:flex-row"
    [attr.data-cy]="'insurance-base-message-grid-items-container'"
  >
    @for (item of insuranceMessageGridItems; track item.paragraph) {
      <div class="flex flex-[1_0_0] gap-4" [ngClass]="item.flexItemCssClass">
        <div class="relative">
          <cg-icon class="absolute left-[43px] top-[-3px] h-6 w-6" [content]="successIcon"></cg-icon>
          <cg-icon class="h-16 w-16" [content]="item.icon"></cg-icon>
        </div>
        <cg-paragraph
          class="[&>p]:leading[20px] [&>p]:text-[13px]"
          [ngClass]="{
            'm:[&>p]:leading[24px] m:[&>p]:text-[16px]': insuranceMessageGridItems.length === 1
          }"
          [content]="item.paragraph"
        ></cg-paragraph>
      </div>
    }
  </div>
  @if (contentUnderGridTemplateRef) {
    <ng-container *ngTemplateOutlet="contentUnderGridTemplateRef"></ng-container>
  }
</ng-template>
<ng-template #footer>
  <ng-container *ngTemplateOutlet="footerTemplateRef"></ng-container>
</ng-template>
