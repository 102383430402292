/* eslint-disable max-params */

import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";
import { ContactDataFacade, DamageFacade, OlbFacade, ProcessFacade } from "@cg/olb/state";
import { ResumeFacade } from "@cg/resume-core";
import * as CgValidators from "@cg/validators";
import { TranslocoPipe } from "@jsverse/transloco";
import { OptimizelyService, TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { LinkComponent, LinkWithId } from "@cg/core/ui";
import { isOpportunityFunnel } from "@cg/core/utils";
import { EnvironmentService } from "@cg/environments";
import {
  CustomerContactExitIds,
  OLB_PROCESS_FLOW_MODEL,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ProcessFlow,
  ScrollService
} from "@cg/olb/shared";
import {
  AddressForm,
  AddressFormComponent,
  ComponentOverarchingChangeDetectionService,
  countries,
  InputType,
  OptionsSelectionComponent,
  ProcessMetadata,
  RadioButtonGroup,
  RadioButtonGroupComponent,
  RadioButtonHorizontalGroupComponent,
  Resume,
  ResumeType,
  SplitViewComponent,
  TextInputComponent
} from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { ContactAddressForm } from "./interfaces/customer-address-form.interface";
import { additionalOptions } from "./models/additional-options.model";
import { CustomerAddressInputs, inputFields } from "./models/input-fields.model";
@Component({
  selector: "cg-customer-address",
  templateUrl: "./customer-address.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    AddressFormComponent,
    RadioButtonGroupComponent,
    TextInputComponent,
    RadioButtonHorizontalGroupComponent,
    OptionsSelectionComponent,
    LinkComponent,
    SplitViewComponent,
    OlbFooterComponent,
    AsyncPipe
  ]
})
export class CustomerAddressComponent extends BaseDirective<AddFormControls<ContactAddressForm>> implements OnInit {
  public additionalOptions: { firstLink: LinkWithId; secondLink: LinkWithId } = additionalOptions;
  public radioButtonGroup: RadioButtonGroup = {
    controlName: "policyholder",
    buttons: [
      {
        title: "customerAddress.radioButtons.policyHolder",
        radio: { id: "customer-address-is-policy-holder", value: "1" }
      },
      {
        title: "customerAddress.radioButtons.notPolicyHolder",
        radio: { id: "customer-address-is-not-policy-holder", value: "0" }
      }
    ]
  };
  public insuranceTitleGroup: RadioButtonGroup = {
    controlName: "insuranceTitle",
    buttons: [
      {
        title: "customerAddress.radioButtons.female",
        radio: { id: "customer-address-insurance-female", value: "Frau" }
      },
      { title: "customerAddress.radioButtons.male", radio: { id: "customer-address-insurance-male", value: "Herr" } },
      {
        title: "customerAddress.radioButtons.diverse",
        radio: { id: "customer-address-insurance-diverse", value: "Divers" }
      }
    ]
  };
  public subtitleText = "customerAddress.subtitle";
  public readonly countries = countries;
  public readonly inputFields: CustomerAddressInputs = inputFields;
  protected readonly InputType = InputType;
  public isIOMResume = false;

  public constructor(
    cdr: ChangeDetectorRef,
    processFacade: ProcessFacade,
    exitNodeResolver: ExitNodeResolverService,
    scrollService: ScrollService,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow,
    private contactDataFacade: ContactDataFacade,
    private olbFacade: OlbFacade,
    protected trackingService: TrackingService,
    private damageFacade: DamageFacade,
    private optimizelyService: OptimizelyService,
    private readonly cdrService: ComponentOverarchingChangeDetectionService,
    private readonly resumeFacade: ResumeFacade,
    private readonly environmentService: EnvironmentService
  ) {
    super(cdr, processFacade, exitNodeResolver, trackingService, scrollService, processFlow);

    this.resumeFacade.resumeResponse$
      .pipe(
        filter((resumeResponse: Resume) => !!resumeResponse),
        tap((resumeResponse: Resume) => {
          this.isIOMResume = resumeResponse.resumeType === ResumeType.B2B_IOM;

          if (this.isIOMResume) {
            this.subtitleText = "customerAddress.subtitleIOM";
          }
        }),
        take(1),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  public get policyHolderInput(): FormControl<string> {
    return this.form.controls.policyHolder;
  }

  public get insuranceCompanyInput(): FormControl<string> {
    return this.form.controls.insuranceCompany;
  }

  public get insuranceTitleInput(): FormControl<string> {
    return this.form.controls.insuranceTitle;
  }

  public get insuranceFirstnameInput(): FormControl<string> {
    return this.form.controls.insuranceFirstname;
  }

  public get insuranceLastnameInput(): FormControl<string> {
    return this.form.controls.insuranceLastname;
  }

  public get insuranceStreetInput(): FormControl<string> {
    return this.form.controls.insuranceStreet;
  }

  public get insuranceZipInput(): FormControl<string> {
    return this.form.controls.insuranceZip;
  }

  public get insuranceCityInput(): FormControl<string> {
    return this.form.controls.insuranceCity;
  }

  public get insuranceCountryInput(): FormControl<string> {
    return this.form.controls.insuranceCountry;
  }

  public get insurancePhoneInput(): FormControl<string> {
    return this.form.controls.insurancePhone;
  }

  public get currentAddressFormValue(): ContactAddressForm["address"] {
    return this.form.controls.address.value;
  }

  public get activeVapsOffer(): boolean {
    return this.environmentService.env.features.vapsOffer;
  }

  public get isB2B(): boolean {
    return !!this.environmentService.env.b2b;
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<ContactAddressForm>>({
      address: new FormControl<AddressForm>(null, [Validators.required]),
      policyHolder: new FormControl<string>("1", Validators.required)
    });

    this.policyHolderInput.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isPolicyHolder: string) => {
        this.saveDriverForm();

        if (this.insuranceCompanyInput) {
          this.saveInsuranceHolderForm();
        }

        const parsedIsPolicyHolder = !!parseInt(isPolicyHolder, 10);

        this.handleInsuranceHolderFormFields(parsedIsPolicyHolder);

        if (!parseInt(isPolicyHolder, 10)) {
          this.fillInsuranceHolderForm();
        }
      });
  }

  public goForwardFailure(): void {
    super.goForwardFailure();
    this.cdrService.changeDetectionRequest$.next();
  }

  public saveForm(): void {
    this.saveDriverForm();
    const policyHolderInput = this.form.controls.policyHolder.value;
    if (policyHolderInput) {
      this.contactDataFacade.setIsPolicyHolder(!!parseInt(policyHolderInput, 10));
    }

    if (this.isIOMResume) {
      this.copyDriverToCustomer();
    }

    if (!parseInt(policyHolderInput, 10)) {
      this.saveInsuranceHolderForm();
    }

    if (this.form.valid) {
      this.olbFacade.updateCustomerContact();
    }
  }

  public setFormValues(): void {
    this.fillDriverForm();

    this.contactDataFacade.isPolicyHolder$
      .pipe(
        filter((isPolicyHolder: boolean) => isPolicyHolder !== null),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((isPolicyHolder: boolean) => {
        this.form.controls.policyHolder.setValue(isPolicyHolder ? "1" : "0");
        this.handleInsuranceHolderFormFields(isPolicyHolder);

        if (!isPolicyHolder) {
          this.fillInsuranceHolderForm();
        }
      });
  }

  public getExitIdForSavedForm(): Observable<CustomerContactExitIds> {
    return combineLatest([
      this.damageFacade.requiredServiceIsReplace$,
      this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.BINDING_BOOKING),
      this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.OLB_SUMMARY),
      this.processFacade.processMetaData$
    ]).pipe(
      map(
        ([isReplace, bindingBookingExperimentActive, isSummaryTestActive, processMetaData]: [
          boolean,
          boolean,
          boolean,
          ProcessMetadata[]
        ]) => {
          if (isOpportunityFunnel(processMetaData)) {
            return "opportunityFunnelContactTime";
          }

          if (this.activeVapsOffer) {
            return "vapsOffer";
          }

          if (isSummaryTestActive) {
            return "summary";
          }

          if (isReplace && bindingBookingExperimentActive) {
            return "bindingBooking";
          }

          return "appointmentSuccess";
        }
      )
    );
  }

  public handleInsuranceHolderFormFields(isPolicyHolder: boolean) {
    if (!isPolicyHolder) {
      this.form.addControl("insuranceTitle", new UntypedFormControl(null, Validators.required));
      this.form.addControl("insuranceCompany", new UntypedFormControl(null, CgValidators.companyValidators()));
      this.form.addControl("insuranceFirstname", new UntypedFormControl(null, CgValidators.nameValidators()));
      this.form.addControl("insuranceLastname", new UntypedFormControl(null, CgValidators.nameValidators()));
      this.form.addControl("insuranceStreet", new UntypedFormControl(null, CgValidators.streetValidators()));
      this.form.addControl("insuranceZip", new UntypedFormControl(null, Validators.required));
      this.form.addControl("insuranceCity", new UntypedFormControl(null, CgValidators.cityValidators()));
      this.form.addControl("insuranceCountry", new UntypedFormControl(null, Validators.required));
      this.form.addControl("insurancePhone", new UntypedFormControl(null, CgValidators.phoneValidators()));

      this.insuranceCountryInput.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((country: string) => {
        if (country === "DE") {
          this.insuranceZipInput.setValidators([Validators.required, Validators.pattern(/^[0-9]{5}$/)]);
        } else {
          this.insuranceZipInput.setValidators([Validators.required]);
        }
        this.insuranceZipInput.updateValueAndValidity({ emitEvent: false });
      });
    } else {
      this.form.removeControl("insuranceTitle");
      this.form.removeControl("insuranceCompany");
      this.form.removeControl("insuranceFirstname");
      this.form.removeControl("insuranceLastname");
      this.form.removeControl("insuranceStreet");
      this.form.removeControl("insuranceZip");
      this.form.removeControl("insuranceCity");
      this.form.removeControl("insuranceCountry");
      this.form.removeControl("insurancePhone");
    }
  }

  public fillDriverForm() {
    const addressForm = this.form.controls.address;

    this.contactDataFacade.driverTitle$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((driverTitle: string) => {
        if (driverTitle) {
          addressForm.get("title").setValue(driverTitle);
        }
      });

    this.contactDataFacade.driverFirstname$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((driverFirstname: string) => {
        if (driverFirstname) {
          addressForm.get("firstname").setValue(driverFirstname);
        }
      });

    this.contactDataFacade.driverLastname$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((driverLastname: string) => {
        if (driverLastname) {
          addressForm.get("lastname").setValue(driverLastname);
        }
      });

    this.contactDataFacade.driverStreet$
      .pipe(
        take(1),
        map((street: string) => street ?? ""),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((street: string) => addressForm.get("street").setValue(street));

    this.contactDataFacade.driverZip$
      .pipe(
        take(1),
        map((zip: string) => zip ?? ""),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((zip: string) => addressForm.get("zip").setValue(zip));

    this.contactDataFacade.driverCity$
      .pipe(
        take(1),
        map((city: string) => city ?? ""),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((city: string) => addressForm.get("city").setValue(city));

    this.contactDataFacade.driverCountry$
      .pipe(
        take(1),
        map((country: string) => (country ? country : "DE")),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((country: string) => addressForm.get("country").setValue(country));
  }

  public fillInsuranceHolderForm() {
    this.contactDataFacade.insuranceHolderCompany$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((company: string) => this.insuranceCompanyInput.setValue(company));

    this.contactDataFacade.insuranceHolderTitle$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((title: string) => this.insuranceTitleInput.setValue(title));

    this.contactDataFacade.insuranceHolderFirstname$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((firstname: string) => this.insuranceFirstnameInput.setValue(firstname));

    this.contactDataFacade.insuranceHolderLastname$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((lastname: string) => this.insuranceLastnameInput.setValue(lastname));

    this.contactDataFacade.insuranceHolderStreet$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((street: string) => this.insuranceStreetInput.setValue(street));

    this.contactDataFacade.insuranceHolderZip$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((zip: string) => this.insuranceZipInput.setValue(zip));

    this.contactDataFacade.insuranceHolderCity$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((city: string) => this.insuranceCityInput.setValue(city));

    this.contactDataFacade.insuranceHolderCountry$
      .pipe(
        map((country: string) => (country ? country : "DE")),
        takeUntilDestroyed(this.destroyRef),
        take(1)
      )
      .subscribe((country: string) => this.insuranceCountryInput.setValue(country));

    this.contactDataFacade.insuranceHolderPhone$
      .pipe(takeUntilDestroyed(this.destroyRef), take(1), filter(Boolean))
      .subscribe((phone: string) => this.insurancePhoneInput.setValue(phone));
  }

  public saveDriverForm() {
    if (!this.currentAddressFormValue) {
      return;
    }

    const { title, firstname, lastname, street, zip, city, country } = this.currentAddressFormValue;

    this.contactDataFacade.setDriverTitle(title);
    this.contactDataFacade.setDriverFirstname(firstname);
    this.contactDataFacade.setDriverLastname(lastname);
    this.contactDataFacade.setDriverStreet(street);
    this.contactDataFacade.setDriverZip(zip);
    this.contactDataFacade.setDriverCity(city);
    this.contactDataFacade.setDriverCountry(country);
  }

  public saveInsuranceHolderForm() {
    this.contactDataFacade.setInsuranceHolderCompany(this.insuranceCompanyInput.value);
    this.contactDataFacade.setInsuranceHolderTitle(this.insuranceTitleInput.value);
    this.contactDataFacade.setInsuranceHolderFirstname(this.insuranceFirstnameInput.value);
    this.contactDataFacade.setInsuranceHolderLastname(this.insuranceLastnameInput.value);
    this.contactDataFacade.setInsuranceHolderStreet(this.insuranceStreetInput.value);
    this.contactDataFacade.setInsuranceHolderZip(this.insuranceZipInput.value);
    this.contactDataFacade.setInsuranceHolderCity(this.insuranceCityInput.value);
    this.contactDataFacade.setInsuranceHolderCountry(this.insuranceCountryInput.value);
    this.contactDataFacade.setInsuranceHolderPhone(this.insurancePhoneInput.value);
  }

  public copyDriverToCustomer() {
    const { title, firstname, lastname, street, zip, city, country } = this.currentAddressFormValue;
    this.contactDataFacade.setInsuranceHolderTitle(title);
    this.contactDataFacade.setInsuranceHolderFirstname(firstname);
    this.contactDataFacade.setInsuranceHolderLastname(lastname);
    this.contactDataFacade.setInsuranceHolderStreet(street);
    this.contactDataFacade.setInsuranceHolderZip(zip);
    this.contactDataFacade.setInsuranceHolderCity(city);
    this.contactDataFacade.setInsuranceHolderCountry(country);
  }

  public onTrack(eventAction: string) {
    this.trackingService.trackEvent({
      eventAction: eventAction,
      eventLabel: "customer-address"
    } as Partial<TrackingEvent>);
  }
}
