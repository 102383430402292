import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { iif, of, timer } from "rxjs";
import { catchError, filter, map, mergeMap, take, takeWhile, tap, withLatestFrom } from "rxjs/operators";
import { PurecloudCallBackService, PurecloudEwtService } from "@cg/olb/services";
import { ChannelSwitchActions, ChannelSwitchFacade, CustomerCaseActions } from "@cg/olb/state";
import { forceShowSpinner, hideForcedSpinner } from "@cg/spinner";
import {
  channelSwitchConversationMapping,
  ChannelSwitchReason,
  ConnectionStatus,
  IConnectionStatus
} from "@cg/olb/shared";
import { CustomerCase } from "@cg/shared";

@Injectable()
export class ChannelSwitchEffects {
  private readonly actions$ = inject(Actions);
  private readonly channelSwitchFacade = inject(ChannelSwitchFacade);
  private readonly pureCloudEwtService = inject(PurecloudEwtService);
  private readonly pureCloudCallBackService = inject(PurecloudCallBackService);

  public estimatedTimeToCallback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelSwitchActions.fetchEstimatedTimeToCallback),
      mergeMap((_action: Action) =>
        this.pureCloudEwtService.getEstimatedWaitingTime().pipe(
          map((value: number) => ChannelSwitchActions.fetchEstimatedTimeToCallbackSuccess({ payload: value })),
          catchError((_error: HttpErrorResponse) => of(ChannelSwitchActions.fetchEstimatedTimeToCallbackFailure()))
        )
      )
    )
  );

  public isCCCOpen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelSwitchActions.isCCCOpen),
      mergeMap((_action: Action) =>
        this.pureCloudEwtService.isCCCOpen().pipe(
          map((value: { isOpen: boolean }) =>
            ChannelSwitchActions.isCCCOpenSuccess({ payload: { isOpen: value.isOpen } })
          ),
          catchError((_error: HttpErrorResponse) => of(ChannelSwitchActions.isCCCOpenFailure()))
        )
      )
    )
  );

  public showEwtSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelSwitchActions.fetchEstimatedTimeToCallback),
      map(() => forceShowSpinner())
    )
  );

  public hideEwtSpinner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ChannelSwitchActions.fetchEstimatedTimeToCallbackSuccess,
        ChannelSwitchActions.fetchEstimatedTimeToCallbackFailure
      ),
      map(() => hideForcedSpinner())
    )
  );

  public getConversationId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelSwitchActions.fetchConversationId),
      map(({ payload }: { payload: { ccId: string; reason: ChannelSwitchReason; phoneNumber: string } }) => payload),
      mergeMap((payload: { ccId: string; reason: ChannelSwitchReason; phoneNumber: string }) =>
        this.pureCloudEwtService
          .getConversationId({
            ccId: payload.ccId,
            phoneNumber: payload.phoneNumber,
            switchChannelType: channelSwitchConversationMapping[payload.reason]
          })
          .pipe(
            map((value: { id: string }) => ChannelSwitchActions.fetchConversationIdSuccess({ payload: value })),
            catchError((_error: HttpErrorResponse) => of(ChannelSwitchActions.fetchConversationIdFailure()))
          )
      )
    )
  );

  public getCoversationIdSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelSwitchActions.fetchConversationIdSuccess),
      withLatestFrom(this.channelSwitchFacade.estimatedTimeToCallback$),
      map(([_, ewt]: [Action, number]) => ewt),
      mergeMap((ewt: number) =>
        iif(() => ewt === 0, of(ChannelSwitchActions.setTimerDone()), of(ChannelSwitchActions.setTimerStarted()))
      )
    )
  );

  public getConnectionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelSwitchActions.fetchConversationIdSuccess),
      map(({ payload }: { payload: { id: string } }) => payload.id),
      filter((conversationId: string) => !!conversationId),
      mergeMap((conversationId: string) => this.pureCloudCallBackService.watchForCallbackTopic(conversationId)),
      map((connection: IConnectionStatus) => {
        switch (connection.state) {
          case ConnectionStatus.CONNECTED:
            return ChannelSwitchActions.isConnectedToAgent();
          case ConnectionStatus.TERMINATED:
            return ChannelSwitchActions.setCallSuccess({ payload: true });
        }
      })
    )
  );

  public hasTimerStarted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelSwitchActions.setTimerStarted),
      withLatestFrom(this.channelSwitchFacade.estimatedTimeToCallback$),
      map(
        ([_timerStarted, estimatedTimeToCallback]: [typeof ChannelSwitchActions.setTimerStarted, number]) =>
          estimatedTimeToCallback
      ),
      mergeMap((estimatedTimeToCallback: number) =>
        timer(0, 1000).pipe(
          takeWhile((time: number) => estimatedTimeToCallback - time >= 0),
          map((time: number) => estimatedTimeToCallback - time),
          map((time: number) => ChannelSwitchActions.setCountdown({ payload: time }))
        )
      )
    )
  );

  public setCountdown$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelSwitchActions.setCountdown),
      withLatestFrom(this.channelSwitchFacade.isTimerDone$),
      filter(([_, isTimerDone]: [Action, boolean]) => isTimerDone === false),
      map(([action]: [Action & { payload: number }, boolean]) => action.payload),
      filter((value: number) => value === 0),
      map(() => ChannelSwitchActions.setTimerDone())
    )
  );

  public connectedToAgent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChannelSwitchActions.isConnectedToAgent),
      withLatestFrom(this.channelSwitchFacade.isTimerDone$),
      filter(([_, isTimerDone]: [Action, boolean]) => isTimerDone === false),
      map(() => ChannelSwitchActions.setTimerDone())
    )
  );

  public callSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChannelSwitchActions.setCallSuccess),
        filter((callSuccess: { payload: boolean }) => !!callSuccess),
        take(1),
        tap(() => {
          this.pureCloudCallBackService.disconnect();
        })
      ),
    {
      dispatch: false
    }
  );

  public switchChannelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.switchChannelSuccess),
      withLatestFrom(this.channelSwitchFacade.showFallback$),
      map(([_, showFallback]: [Action, boolean]) =>
        ChannelSwitchActions.setFallbackSubmitted({ payload: showFallback })
      )
    )
  );

  public fetchCustomerCaseSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerCaseActions.fetchCustomerCaseSuccess),
      map((result: { payload: CustomerCase }) => {
        if (result.payload.customer) {
          const { contactPhone1, contactPhone2 }: { contactPhone1?: string; contactPhone2?: string } =
            result.payload.customer;

          if (contactPhone1 || contactPhone2) {
            return ChannelSwitchActions.setPhoneNumber({
              payload: contactPhone1 ?? contactPhone2
            });
          }
        }

        return ChannelSwitchActions.noOperation();
      })
    )
  );
}
