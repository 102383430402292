<p
  class="font-roboto text-cg-anthracite [text-align:var(--paragraph-text-align)] [&_a]:underline"
  [ngClass]="{
    '!font-roboto-condensed': condensed(),
    '!text-[13px] leading-5': size() === 's',
    'text-base leading-5': size() === 'm',
    'text-base md:text-xl': size() === 'l',
    '[--paragraph-text-align:center]': alignment() === 'center'
  }"
  part="text"
>
  <ng-content>
    <span [innerHTML]="sanitizedContent()"></span>
  </ng-content>
</p>
