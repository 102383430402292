@if (footer) {
  <footer>
    @if (footer?.type !== "mobile" && !hideMainFooter) {
      <div class="footer-main" [ngClass]="{ '!bg-cg-red': hasError }">
        <div class="container-fluid">
          <div class="row">
            <div class="footer-headline-wrapper">
              <div class="cta-wrapper">
                <h2 class="headline" [ngClass]="{ '!text-white': hasError }">{{ footer?.headline }}</h2>
                @if (!disableOlbCta) {
                  <cg-cta [content]="footer?.cta"></cg-cta>
                }
              </div>
            </div>
            <div class="footer-links-wrapper">
              @for (navigation of footer?.navigations; track navigation.href) {
                <div class="footer-navigation-container">
                  <cg-footer-navigation [navigation]="navigation"></cg-footer-navigation>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }
    <cg-meta-footer [meta]="footer?.meta" [route]="route" [hasError]="hasError"></cg-meta-footer>
  </footer>
}
