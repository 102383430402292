/* eslint-disable sonarjs/no-duplicate-string */
import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, map, tap, withLatestFrom } from "rxjs/operators";
import {
  ChannelSwitchActions,
  ChannelSwitchFacade,
  InsuranceFacade,
  OpportunityFunnelFacade,
  ProcessActions,
  ProcessFacade
} from "@cg/olb/state";
import { format } from "date-fns";
import { OptimizelyService, TrackingService } from "@cg/analytics";
import { isOpportunityFunnel } from "@cg/core/utils";
import { ChannelSwitchReason, InsuranceType, switchChannelReasonTrackingMapping } from "@cg/olb/shared";
import { ProcessId, ProcessMetadata } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";

@Injectable()
export class ChannelSwitchTrackingEffects {
  private readonly actions$ = inject(Actions);
  private readonly trackingService = inject(TrackingService);
  private readonly processFacade = inject(ProcessFacade);
  private readonly channelSwitchFacade = inject(ChannelSwitchFacade);
  private readonly insuranceFacade = inject(InsuranceFacade);
  private readonly optimizelyService = inject(OptimizelyService);
  private readonly opportunityFunnelFacade = inject(OpportunityFunnelFacade);

  public currentProcessIdChannelSwitch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.setCurrentProcess),
        this.isNotOpportunityFunnelDirectCall(),
        filter(({ payload }: { payload: ProcessId }) =>
          ["channel-switch", "opportunity-funnel-channel-switch"].includes(payload)
        ),
        withLatestFrom(
          this.processFacade.channelSwitchReason$,
          this.insuranceFacade.type$,
          this.optimizelyService.isVariationOfExperimentActive(
            OptimizelyExperiment.OPPORTUNITY_FUNNEL_PRODUCT_NOT_FOUND
          )
        ),
        tap(
          ([{ payload }, reason, type, oppFunnelProductNotFoundTestActive]: [
            { payload: ProcessId },
            ChannelSwitchReason,
            InsuranceType,
            boolean
          ]) => {
            let trackingReason = this.getReasonTracking(reason, oppFunnelProductNotFoundTestActive);

            if (type === InsuranceType.LIABILITY && reason === "UNINSURED") {
              trackingReason = "liability-insurance";
            }

            this.trackingService.trackVirtualPageView(payload, trackingReason);
          }
        )
      ),
    {
      dispatch: false
    }
  );

  public channelTrackEstimatedCallbackTime$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ChannelSwitchActions.fetchEstimatedTimeToCallbackSuccess,
          ChannelSwitchActions.fetchEstimatedTimeToCallbackFailure
        ),
        this.isNotOpportunityFunnelDirectCall(),
        withLatestFrom(this.channelSwitchFacade.needFallback$, this.processFacade.processMetaData$),
        tap(
          ([{ payload }, needFallback, processMetaData]: [
            { payload?: number; type: string },
            boolean,
            ProcessMetadata[]
          ]) => {
            let eventAction = isOpportunityFunnel(processMetaData)
              ? "opportunity-funnel-channel-switch"
              : "channel-switch";

            const currentId = processMetaData[processMetaData.length - 1].id;
            if (currentId === "opportunity-funnel-contact-time") {
              eventAction = "opportunity-funnel-contact-time";
            } else if (currentId === "opportunity-funnel-channel-switch" && processMetaData.length === 1) {
              eventAction = "opportunity-funnel-summary";
            }

            const time = format(new Date(), "HH:mm");
            this.trackingService.trackEvent({
              eventAction,
              eventLabel: needFallback ? "callback-unavailable" : "callback-available",
              callback: {
                "estimated-waiting-time": payload,
                time: time,
                availability: needFallback ? "callback-unavailable" : "callback-available"
              }
            });
          }
        )
      ),
    { dispatch: false }
  );

  public channelSwitchCallStarted$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChannelSwitchActions.isConnectedToAgent),
        this.isNotOpportunityFunnelDirectCall(),
        tap(() => this.trackingService.trackEvent({ eventAction: "callback-call-started" }))
      ),
    { dispatch: false }
  );

  public channelSwitchDisplayTile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ChannelSwitchActions.setTimerStarted,
          ChannelSwitchActions.setTimerDone,
          ChannelSwitchActions.setCallSuccess,
          ChannelSwitchActions.setCallFailure,
          ChannelSwitchActions.setFallbackSubmitted
        ),
        this.isNotOpportunityFunnelDirectCall(),
        filter((action: Action<string> & { payload?: boolean }) =>
          this.disallowActionWhenPayloadIsFalse(
            action,
            ChannelSwitchActions.setCallSuccess,
            ChannelSwitchActions.setCallFailure
          )
        ),
        withLatestFrom(
          this.processFacade.channelSwitchReason$,
          this.processFacade.processMetaData$,
          this.opportunityFunnelFacade.callNow$,
          this.insuranceFacade.type$,
          this.optimizelyService.isVariationOfExperimentActive(
            OptimizelyExperiment.OPPORTUNITY_FUNNEL_PRODUCT_NOT_FOUND
          )
        ),
        map(
          ([action, reason, processMetaData, oppFunnelCallNow, insuranceType, oppFunnelProductNotFoundTestActive]: [
            Action<string> & { payload?: boolean },
            ChannelSwitchReason,
            ProcessMetadata[],
            boolean,
            InsuranceType,
            boolean
          ]) => ({
            name: this.returnChannelSwitchPageName(action, processMetaData, oppFunnelCallNow),
            trackingReason: this.getReasonTracking(reason, oppFunnelProductNotFoundTestActive),
            reason,
            processMetaData,
            insuranceType
          })
        ),
        tap(
          ({
            name,
            reason,
            processMetaData,
            trackingReason,
            insuranceType
          }: {
            name: string;
            reason: ChannelSwitchReason;
            processMetaData: ProcessMetadata[];
            trackingReason: string;
            insuranceType: InsuranceType;
          }) => {
            const processId = processMetaData[0].id;
            // should not track for call later in opportunity funnel
            if (processId === "opportunity-funnel-success") {
              return;
            }

            if (insuranceType === InsuranceType.LIABILITY && reason === "UNINSURED") {
              trackingReason = "liability-insurance";
            }

            this.trackingService.trackVirtualPageView(name, trackingReason);
          }
        )
      ),
    { dispatch: false }
  );

  private returnChannelSwitchPageName(
    action: Action<string>,
    processMetaData: ProcessMetadata[],
    oppFunnelCallNow: boolean
  ): string {
    const processId = processMetaData[0].id;

    const isOpportunityFunnelDirectCall =
      processMetaData.length === 1 && processId === "opportunity-funnel-channel-switch" && oppFunnelCallNow;
    const prefix = isOpportunityFunnelDirectCall ? "opportunity-funnel-call-now" : "callback";

    switch (action.type) {
      case ChannelSwitchActions.setTimerDone.type:
        return `${prefix}-connecting`;
      case ChannelSwitchActions.setTimerStarted.type:
        return `${prefix}-countdown`;
      case ChannelSwitchActions.setCallSuccess.type:
        return `${prefix}-call-end`;
      case ChannelSwitchActions.setCallFailure.type:
        return `${prefix}-connection-error`;
      case ChannelSwitchActions.setFallbackSubmitted.type:
        return `${prefix}-request-confirmation`;
    }
    console.error(`tracking channel switch for ${action.type} failed`);
    return "";
  }

  private disallowActionWhenPayloadIsFalse(
    got: { payload?: boolean } & Action<string>,
    ...disallow: Action<string>[]
  ): boolean {
    const types = disallow.map((a: Action<string>) => a.type);
    // eslint-disable-next-line @typescript-eslint/no-extra-parens
    return !types.includes(got.type) || (types.includes(got.type) && "payload" in got && got.payload === true);
  }

  private getReasonTracking(reason: ChannelSwitchReason, oppFunnelProductNotFoundTestActive: boolean): string {
    if (oppFunnelProductNotFoundTestActive && reason === "PRODUCT_NOT_FOUND") {
      return "opp-funnel-product-not-found";
    }

    return switchChannelReasonTrackingMapping.get(reason);
  }

  private isNotOpportunityFunnelDirectCall<S>(): (src: Observable<S>) => Observable<S> {
    return (src: Observable<S>) =>
      src.pipe(
        withLatestFrom(this.processFacade.processMetaData$),
        filter(
          ([_, processMetaData]: [unknown, ProcessMetadata[]]) =>
            !processMetaData.some(({ id }: ProcessMetadata) => id === "opportunity-funnel-summary")
        ),
        map(([data, _]: [S, ProcessMetadata[]]) => data)
      );
  }
}
