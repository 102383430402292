<div class="mx-auto max-w-full w:max-w-container">
  @for (item of content?.items; track $index) {
    <div
      [ngClass]="[
        item?.imagePosition === 'right'
          ? 'content-block-image-video-container imageRight'
          : 'content-block-image-video-container imageLeft'
      ]"
      data-cy="content-block-image-video-container"
    >
      <div class="w-full flex-initial d:max-w-content w:w-1/2" data-cy="image-video-wrapper">
        @if (item?.mediaType === mediaType.IMAGE) {
          <cg-content-block-img [content]="item?.picture"></cg-content-block-img>
        } @else {
          <cg-youtube [content]="item?.video"></cg-youtube>
        }
      </div>
      <div class="text-wrapper" data-cy="text-wrapper">
        @if (item?.headline) {
          <cg-headline
            class="part-[headline]:x-[mb-2,mt-4] d:part-[headline]:x-[mb-4,mt-0]"
            [type]="'h2'"
            [content]="item?.headline"
          ></cg-headline>
        }
        @if (item?.paragraph) {
          <cg-richtext [content]="item?.paragraph"></cg-richtext>
        }
        @if (item?.list) {
          <cg-list [content]="item?.list"></cg-list>
        }
        @if (item?.ctaLast) {
          <cg-cta class="mr-0 t:mr-4" [content]="item?.ctaLast"></cg-cta>
        }
        @if (item?.ctaFirst) {
          <cg-cta class="cta" [content]="item?.ctaFirst"></cg-cta>
        }
      </div>
    </div>
  }
</div>
