import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { filter } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { GdvExitIdService } from "@cg/olb/services";
import {
  ContactDataFacade,
  CustomerCaseFacade,
  DamageFacade,
  GdvFacade,
  InsuranceFacade,
  OlbFacade,
  ProcessFacade,
  ProductFacade
} from "@cg/olb/state";
import * as CgValidators from "@cg/validators";
import { OptimizelyService, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { LpnInputForm } from "@cg/lpn-input";
import { OLB_PROCESS_FLOW_MODEL, OlbFooterComponent, ProcessFlow, ScrollService } from "@cg/olb/shared";
import {
  ComponentOverarchingChangeDetectionService,
  GetGdv,
  RadioButtonGroup,
  RadioButtonHorizontalGroupComponent,
  SplitViewComponent,
  TextInput,
  TextInputComponent
} from "@cg/shared";
import { ExitNodeResolverService } from "../../../../../services/exit-node-resolver.service";
import { LicensePlateForm } from "../../../interfaces/license-form.interface";
import { LicensePlateBase } from "../../../license-plate.base";
import { DamageDatePartComponent } from "../../parts/damage-date-part/damage-date-part.component";
import { GdvPartComponent } from "../../parts/gdv-part/gdv-part.component";
import { LpnPartComponent } from "../../parts/lpn-part/lpn-part.component";

@Component({
  selector: "cg-license-plate-default-with-name",
  templateUrl: "./license-plate-default-with-name.component.html",
  styleUrls: ["./license-plate-default-with-name.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    LpnPartComponent,
    RadioButtonHorizontalGroupComponent,
    TextInputComponent,
    DamageDatePartComponent,
    GdvPartComponent,
    SplitViewComponent,
    OlbFooterComponent
  ]
})
export class LicensePlateDefaultWithNameComponent extends LicensePlateBase {
  public readonly nameInputFields: { firstnameInput: TextInput; lastnameInput: TextInput } = {
    firstnameInput: {
      id: "license-plate-firstname",
      controlName: "firstname",
      placeholder: "licensePlate.salutation.inputFields.firstName.placeholder",
      errorMessage: "licensePlate.salutation.inputFields.firstName.errorMessage"
    },
    lastnameInput: {
      id: "license-plate-lastname",
      controlName: "lastname",
      placeholder: "licensePlate.salutation.inputFields.lastName.placeholder",
      errorMessage: "licensePlate.salutation.inputFields.lastName.errorMessage"
    }
  };

  public readonly titleGroup: RadioButtonGroup = {
    controlName: "title",
    buttons: [
      { title: "licensePlate.salutation.woman", radio: { id: "license-plate-policyholder-female", value: "Frau" } },
      { title: "licensePlate.salutation.man", radio: { id: "license-plate-policyholder-male", value: "Herr" } },
      { title: "licensePlate.salutation.diverse", radio: { id: "license-plate-policyholder-diverse", value: "Divers" } }
    ]
  };

  public get damageDate(): Date {
    return this.form.controls.date.value;
  }

  public get fetchGdv(): GetGdv {
    return this.form.controls.fetchGdv.value;
  }

  public get title(): string {
    return this.form.controls.title.value;
  }

  public get firstname(): string {
    return this.form.controls.firstname.value;
  }

  public get lastname(): string {
    return this.form.controls.lastname.value;
  }

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly cdr: ChangeDetectorRef,
    protected readonly processFacade: ProcessFacade,
    protected readonly exitNodeResolver: ExitNodeResolverService,
    protected readonly scrollService: ScrollService,
    protected readonly olbFacade: OlbFacade,
    protected readonly damageFacade: DamageFacade,
    protected readonly gdvFacade: GdvFacade,
    protected readonly insuranceFacade: InsuranceFacade,
    protected readonly contactDataFacade: ContactDataFacade,
    protected readonly trackingService: TrackingService,
    protected readonly productFacade: ProductFacade,
    protected readonly gdvExitIdService: GdvExitIdService,
    protected readonly customerCaseFacade: CustomerCaseFacade,
    protected readonly optimizelyService: OptimizelyService,
    protected readonly changeDetectionService: ComponentOverarchingChangeDetectionService,
    @Inject(OLB_CONFIG) protected readonly _olbConfig: OlbConfiguration,
    @Inject(OLB_PROCESS_FLOW_MODEL) processFlow: ProcessFlow
  ) {
    super(
      cdr,
      processFacade,
      exitNodeResolver,
      scrollService,
      damageFacade,
      olbFacade,
      trackingService,
      insuranceFacade,
      productFacade,
      gdvFacade,
      gdvExitIdService,
      customerCaseFacade,
      optimizelyService,
      changeDetectionService,
      _olbConfig,
      processFlow
    );
  }

  public override initFormGroup() {
    super.initFormGroup();

    this.form = new FormGroup<AddFormControls<LicensePlateForm>>({
      lpn: new FormControl<LpnInputForm>(null, Validators.required),
      title: new FormControl<string>(null, Validators.required),
      firstname: new FormControl<string>(null, CgValidators.nameValidators()),
      lastname: new FormControl<string>(null, CgValidators.nameValidators()),
      date: new FormControl<Date>(null, Validators.required),
      fetchGdv: new FormControl<GetGdv>(null, Validators.required)
    });
  }

  public saveAndContinue(): void {
    this.saveForm();

    if (this.form.valid) {
      this.goForward();
    } else if (this.form.invalid) {
      this.goForwardFailure();
    }
  }

  public saveForm(): void {
    this.gdvFacade.setFetch(this.fetchGdv);
    this.damageFacade.setDamageDate(this.damageDate);
    super.saveForm();

    this.contactDataFacade.setInsuranceHolderTitle(this.title);
    this.contactDataFacade.setInsuranceHolderFirstname(this.firstname);
    this.contactDataFacade.setInsuranceHolderLastname(this.lastname);

    if (this.form.valid) {
      this.olbFacade.getInsurance();
      this.gdvFacade.setUseNoDate(false);
    }
  }

  public setFormValues(): void {
    super.setFormValues();

    this.damageFacade.damageDate$
      .pipe(
        filter((date: string) => !!date),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((date: string) => {
        this.form.controls.date?.setValue(new Date(date));
      });

    this.gdvFacade.fetch$
      .pipe(
        filter((fetchGdv: GetGdv) => !!fetchGdv),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((fetchGdv: GetGdv) => {
        this.form.controls.fetchGdv?.setValue(fetchGdv);
      });

    this.contactDataFacade.insuranceHolderTitle$
      .pipe(
        filter((title: string) => !!title),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((title: string) => {
        this.form.controls.title?.setValue(title);
      });

    this.contactDataFacade.insuranceHolderFirstname$
      .pipe(
        filter((firstname: string) => !!firstname),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((firstname: string) => {
        this.form.controls.firstname?.setValue(firstname);
      });

    this.contactDataFacade.insuranceHolderLastname$
      .pipe(
        filter((lastname: string) => !!lastname),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((lastname: string) => {
        this.form.controls.lastname?.setValue(lastname);
      });
  }

  protected override updateFormValues() {
    if (this.form && this.processMetadata?.formData) {
      const formData = { ...this.processMetadata.formData };

      if (typeof formData.date === "string") {
        formData.date = new Date(formData.date);
      }

      this.form.patchValue(formData);

      this.cdr.markForCheck();
    }
  }
}
