import type { Type } from "@angular/core";
import type { PrefixComponentIds, ProcessFlowTileMapping } from "@cg/olb/shared";
import { CarIdentifiedPrefixComponent } from "../tiles/car-identified/car-identified-prefix/car-identified-prefix.component";
import { DamageAssessmentResultPrefixComponent } from "../tiles/damage-assessment-result-prefix/damage-assessment-result-prefix.component";
import { EasinessOfProcessComponent } from "../tiles/easiness-of-process/easiness-of-process.component";
import { OpportunityFunnelDamageAssessmentResultComponent } from "../tiles/opportunity-funnel/opportunity-funnel-damage-assessment-result/opportunity-funnel-damage-assessment-result.component";

export const corePrefixComponentMapping: ProcessFlowTileMapping<
  PrefixComponentIds,
  Type<
    | EasinessOfProcessComponent
    | DamageAssessmentResultPrefixComponent
    | OpportunityFunnelDamageAssessmentResultComponent
    | CarIdentifiedPrefixComponent
  >
> = {
  "easiness-of-process": EasinessOfProcessComponent,
  "opportunity-funnel-damage-assessment-result": OpportunityFunnelDamageAssessmentResultComponent,
  "damage-assessment-result-prefix": DamageAssessmentResultPrefixComponent,
  "car-identified-prefix": CarIdentifiedPrefixComponent
};
