import { Component, inject, input } from "@angular/core";
import { CustomerCaseFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { Picture } from "@cg/content-api/typescript-interfaces";
import { ParagraphComponent, PictureComponent } from "@cg/core/ui";
import { EnvironmentService } from "@cg/environments";
import { OlbHeadlineComponent, VAPsEventFlag } from "@cg/olb/shared";
import { AdditionalProduct, BaseButtonComponent, InfoButtonComponent, PriceComponent } from "@cg/shared";
import { WiperPrice } from "../../interfaces/wiper-price.interface";

@Component({
  selector: "cg-wiper-offer",
  standalone: true,
  imports: [
    TranslocoPipe,
    PictureComponent,
    ParagraphComponent,
    OlbHeadlineComponent,
    BaseButtonComponent,
    PriceComponent,
    InfoButtonComponent
  ],
  templateUrl: "./wiper-offer.component.html"
})
export class WiperOfferComponent {
  private readonly customerCaseFacade = inject(CustomerCaseFacade);
  private readonly environmentService = inject(EnvironmentService);

  public readonly boschLogoWhitePicture: Picture = {
    sizes: [],
    source: `${this.environmentService.env.assetPath}/olb/wipers-offer/bosch-logo-white.webp`,
    alt: "wiperOffer.imageAlt.boschLogoWhitePicture",
    ngTemplate: "cgPicture"
  };

  public readonly boschLogoPicture: Picture = {
    sizes: [],
    source: `${this.environmentService.env.assetPath}/olb/wipers-offer/bosch-logo.webp`,
    alt: "wiperOffer.imageAlt.boschLogoPicture",
    ngTemplate: "cgPicture"
  };

  public price = input.required<WiperPrice>();
  public wipersPicture = input.required<Picture>();
  public wiperPriceIsReduced = input.required<boolean>();
  public bookedProtect = input.required<boolean>();
  public bookedWiper = input.required<boolean>();

  public addWipers(): void {
    this.customerCaseFacade.requestAddVAP(AdditionalProduct.WIPER, [VAPsEventFlag.AFTER_CONFIRMATION]);
  }
}
