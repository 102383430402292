<section class="success--container">
  <cg-split-view [master]="master" [showBranding]="false"></cg-split-view>
</section>

<ng-template #master>
  <div class="success">
    <div class="success--headline-wrapper">
      <cg-icon class="success--headline-icon" [content]="headlineIcon"></cg-icon>
      <cg-olb-headline
        class="px-12 t:px-0"
        [title]="'opportunityFunnelSuccess.title' | transloco"
        [subHeadline]="'opportunityFunnelSuccess.subTitle' | transloco"
      ></cg-olb-headline>
    </div>

    <div class="success--content-wrapper">
      <cg-olb-headline
        class="success--content-headline"
        type="h3"
        [title]="'opportunityFunnelSuccess.gridTitle' | transloco"
      ></cg-olb-headline>
      <div class="success--content-grid">
        @for (item of gridItems; track item.paragraph; let i = $index) {
          <div class="success--content-grid-item">
            <div class="success--content-icon-wrapper">
              <div class="success--content-icon-number">{{ i + 1 }}</div>
              <cg-icon class="success--content-icon" [content]="item.icon"></cg-icon>
            </div>
            <cg-paragraph class="success--content-paragraph" [content]="item.paragraph"></cg-paragraph>
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>
