<div class="teaser-three-column-wrapper">
  <cg-headline [type]="'h1'" [content]="content.headline"> </cg-headline>

  <div class="cards-wrapper">
    <div class="columns" data-cy="columns">
      @for (card of content.teasers; track card.headline) {
        <cg-teaser-card class="column" data-cy="column" [content]="card"></cg-teaser-card>
      }
    </div>
  </div>
</div>
