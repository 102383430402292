import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from "@angular/core";
import { RouterReducerState } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { filter, Subscription } from "rxjs";
import { ToastMessage, ToastMessageComponent } from "@cg/toast";
import { OptimizelyService } from "@cg/analytics";
import { Header, MainNavigationItem } from "@cg/core/interfaces";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import { EnvironmentService } from "@cg/environments";
import { alertIcon } from "@cg/icon";
import {
  BreadcrumbsComponent,
  MainMicroNavigationComponent,
  MainNavigationComponent,
  Navigation,
  NavigationFacade,
  NavigationItem,
  NavigationState,
  NavigationStructure
} from "@cg/navigation";
import { ApplicationName, OptimizelyExperiment } from "@cg/core/enums";
import { HeaderFacade } from "../../+state/header.facade";
import { HeaderConfiguration } from "../../interfaces/header-configuration.interface";
import { HEADER_CONFIGURATION } from "../../tokens/header-configuration.token";
import { BusyBannerComponent } from "../busy-banner/busy-banner.component";
import { headerContent } from "./models/header-content.model";

@Component({
  selector: "cg-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    MainNavigationComponent,
    ToastMessageComponent,
    BusyBannerComponent,
    BreadcrumbsComponent,
    MainMicroNavigationComponent
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store<NavigationState>);
  private readonly navigationFacade = inject(NavigationFacade);
  private readonly headerFacade = inject(HeaderFacade);
  private readonly optimizelyService = inject(OptimizelyService);
  private readonly isBrowser: boolean = inject(IS_BROWSER_PLATFORM);
  private readonly configuration: HeaderConfiguration = inject(HEADER_CONFIGURATION, { optional: true });
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly environmentService = inject(EnvironmentService);

  public showEpidemicHint = this.environmentService.env.features.epidemicHint;

  public route: RouterReducerState;
  public headingColor: string;
  public currentNav: Navigation = {
    currentNavPath: {
      path: []
    }
  };
  public subscriptions: Subscription[] = [];
  public header: Header;
  public brand: string;
  public application = ApplicationName;
  public pathIsNotOlb: boolean;

  public toastContent: ToastMessage = {
    title: "",
    message: "<strong>Corona Update:</strong> Unsere Service-Center sind weiterhin geöffnet.",
    color: "#3B8546",
    animated: true,
    closable: true,
    icon: alertIcon,
    infoLink: {
      text: "Weitere Informationen",
      href: "/corona"
    }
  };

  public readonly coronaToastHidden$ = this.headerFacade.coronaToastHidden$;
  public readonly busyBannerHidden$ = this.headerFacade.busyBannerHidden$;
  public readonly isBusyBannerVariationActive$ = this.optimizelyService.isVariationOfExperimentActive(
    OptimizelyExperiment.BUSY_BANNER
  );

  public get isShowBreadcrumbs(): boolean {
    return this.currentNav.currentNavPath.path.length > 2;
  }

  public ngOnInit() {
    this.brand = this.environmentService.env.brand;

    this.header = this.configuration?.model ?? headerContent;
    this.navigationFacade.getHeaderNavigationStructure();
    this.subscriptions.push(
      this.store
        /* eslint-disable @typescript-eslint/no-explicit-any */ // TODO: how to type?
        .select((state: any) => state.router)
        .subscribe((routerState: RouterReducerState) => {
          this.route = routerState;
          this.pathIsNotOlb = !routerState.state.url.includes("/olb");
          this.setCurrentNavigation(routerState.state.url);

          // May be removed when searchbar is integrated in OLB
          if (!this.pathIsNotOlb && this.isBrowser) {
            const lastHeaderIndex = document.getElementsByTagName("cg-header").length - 1;
            const header = document.getElementsByTagName("cg-header")[lastHeaderIndex] as HTMLElement;
            header.style.position = "relative";
            header.style.top = "inherit";
          }

          this.cdr.markForCheck();
        })
    );

    this.navigationFacade.header$
      .pipe(filter((header: NavigationStructure) => !!header))
      .subscribe((header: NavigationStructure) => {
        this.header.mainNavigation.mainNavigationItems = this.mapNavigationStructure(header);
        this.setParent(this.header.mainNavigation.mainNavigationItems);
        this.header.mainNavigation = { ...this.header.mainNavigation };
        this.setCurrentNavigation(this.route.state.url);

        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  // TODO: remove this mapping function, mapping should be done in backend
  public mapNavigationStructure(navigationStructure: NavigationStructure): MainNavigationItem[] {
    if (!navigationStructure.pages) {
      return [];
    }

    return navigationStructure.pages.map((item0: NavigationItem) => ({
      title: item0.properties[0].values[0],
      text: item0.properties[0].values[0],
      target: "_self",
      href: item0.link,
      items: item0.pages
        ? item0.pages.map((item1: NavigationItem) => ({
            title: item1.properties[0].values[0],
            text: item1.properties[0].values[0],
            target: "_self",
            href: item1.link,
            items: item1.pages
              ? item1.pages.map((item2: NavigationItem) => ({
                  title: item2.properties[0].values[0],
                  text: item2.properties[0].values[0],
                  target: "_self",
                  href: item2.link,
                  items: item2.pages
                    ? item2.pages.map((item3: NavigationItem) => ({
                        title: item3.properties[0].values[0],
                        text: item3.properties[0].values[0],
                        target: "_self",
                        href: item3.link
                      }))
                    : null
                }))
              : null
          }))
        : null
    }));
  }

  public setParent(mainNavigationItems: MainNavigationItem[]) {
    for (const item of mainNavigationItems) {
      this.setItemParent(item);
    }
  }

  public setItemParent(mainNavigationItem: MainNavigationItem) {
    if (mainNavigationItem.items) {
      for (const item of mainNavigationItem.items) {
        Object(item).__parent__ = mainNavigationItem;
        this.setItemParent(item);
      }
    }
  }

  public setCurrentNavigation(url: string) {
    this.currentNav = {
      currentNavPath: {
        path: []
      }
    };
    if (this.header.mainNavigation?.mainNavigationItems?.length !== 0) {
      const tmp = url.split("/").filter((item: string) => item !== "");
      if (!tmp[0]) {
        tmp[0] = "/";
      }
      if (this.header.mainNavigation.mainNavigationItems) {
        this.getCurrentNavigationLevels(this.header.mainNavigation.mainNavigationItems, tmp, 0);
      }
      this.navigationFacade.setCurrentNavigation({ currentNavPath: this.currentNav.currentNavPath });
    }
  }

  public getCurrentNavigationLevels(currentItem: MainNavigationItem[], searchItem: any, depth: number) {
    const searchString = this.buildSearchString(searchItem, depth);
    const tmpItem = currentItem.find((item: MainNavigationItem) => item.href === searchString);
    if (tmpItem) {
      this.currentNav.currentNavPath.path.push({
        title: tmpItem.title ? tmpItem.title : null,
        text: tmpItem.text ? tmpItem.text : null,
        target: tmpItem.target ? tmpItem.target : null,
        href: tmpItem.href ? tmpItem.href : null
      });
      depth++;
      if (tmpItem.items) {
        this.getCurrentNavigationLevels(tmpItem.items, searchItem, depth);
      }
    } else {
      return 0;
    }
  }

  public buildSearchString(searchItem: any, depth: number): string {
    let searchString = "";
    // TODO: fix this to a more generic approach!
    if (searchItem[depth] !== "/" && searchItem[depth] !== "autoglas" && searchItem[depth] !== "startseite") {
      for (let i = 0; i <= depth; i++) {
        if (i < searchItem.length) {
          searchString += "/" + searchItem[i];
        }
      }
    } else {
      searchString += "/";
    }
    return searchString;
  }

  public coronaToastClosed() {
    this.headerFacade.setCoronaToastHidden();
  }
}
