import { AsyncPipe, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { forkJoin } from "rxjs";
import { take } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent, ParagraphComponent, RoundCornerTileComponent } from "@cg/core/ui";
import { applePayIcon, ecIcon, googlePayIcon, maestroIcon, mastercardIcon, visaIcon } from "@cg/icon";
import {
  BreakpointService,
  ContentCard,
  ContentCardComponent,
  CustomerCaseAppointment,
  CustomerCaseCar,
  CustomerCaseEditContact,
  Damage
} from "@cg/shared";
import { DetailFacade } from "../../../../+state/detail.facade";
import { DetailService } from "../../../../services/detail.service";
import { getAddressChanged$, getContactChanged$ } from "../../../../utils";
import { MyCarglassBadgeComponent } from "../my-carglass-badge/my-carglass-badge.component";

@Component({
  selector: "cg-my-carglass-booked-details",
  templateUrl: "./my-carglass-booked-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    RoundCornerTileComponent,
    ParagraphComponent,
    ContentCardComponent,
    IconComponent,
    MyCarglassBadgeComponent
  ]
})
export class MyCarglassBookedDetailsComponent implements OnInit, OnChanges {
  private readonly detailService = inject(DetailService);
  private readonly breakpointService = inject(BreakpointService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly detailFacade = inject(DetailFacade);
  private readonly destroyRef = inject(DestroyRef);

  @Input()
  public customerCaseEditContact: CustomerCaseEditContact;

  @Input()
  public customerCaseId: string;

  @Input()
  public requiredService: string;

  @Input()
  public car: CustomerCaseCar;

  @Input()
  public damages: Damage[];

  @Input()
  public appointment: CustomerCaseAppointment;

  public contentCardsPartsLeftColumn: ContentCard[];
  public contentCardsRightColumn: ContentCard[];
  public paymentIcons = [ecIcon, mastercardIcon, maestroIcon, visaIcon, applePayIcon, googlePayIcon];

  public addressChanged$ = getAddressChanged$(this.detailFacade);
  public contactChanged$ = getContactChanged$(this.detailFacade);

  public ngOnInit(): void {
    this.createContentCards();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (changes.appointment) {
      this.appointment = changes.appointment.currentValue;
    }

    if (changes.car) {
      this.car = changes.car.currentValue;
    }

    if (changes.customerCaseEditContact) {
      this.customerCaseEditContact = changes.customerCaseEditContact.currentValue;
    }

    this.createContentCards();
  }

  private createContentCards(): void {
    const paymentContentCard = this.detailService.createPaymentContentCard();
    const lpnContentCard = this.detailService.createLpnContentCard(this.car.lpn, true);
    const addressContentCard = this.detailService.createAddressContentCard(this.customerCaseEditContact, true);
    let damageDateContentCard;
    let damageDate: string;
    if (this.damages?.length > 0 && this.damages[0].occurrenceDate !== "1970-01-01") {
      damageDate = this.damages[0].occurrenceDate;
      damageDateContentCard = this.detailService.createDamageDateContentCard(damageDate, true);
    }

    forkJoin([
      this.breakpointService.isMatchingBreakpoint$("UNTIL_DESKTOP").pipe(take(1)),
      this.detailService.createToTakeWithContentCard$(damageDate, true).pipe(take(1))
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([isUntilDesktop, toTakeWithContentCard]: [boolean, ContentCard]) => {
        const contactContentCard = this.detailService.createContactContentCard(
          this.customerCaseEditContact,
          this.customerCaseId,
          !!isUntilDesktop
        );
        this.contentCardsPartsLeftColumn = [lpnContentCard, addressContentCard, contactContentCard];
        this.contentCardsRightColumn = damageDateContentCard
          ? [damageDateContentCard, toTakeWithContentCard]
          : [toTakeWithContentCard];
        this.contentCardsRightColumn = [...this.contentCardsRightColumn, paymentContentCard];
        this.cdr.detectChanges();
      });
  }
}
