import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, inject } from "@angular/core";
import { ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import { OlbHeadlineComponent, ScrollService } from "@cg/olb/shared";
import { CtaComponent, FormatTelLinkPipe, PhoneNumber } from "@cg/shared";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { channelSwitchAnimation } from "../../animations/channel-switch.animation";
import { customerCallsCallCenter } from "../../models/customer-calls-call-center.model";
import { ChannelSwitchBaseComponent } from "../channel-switch-base/channel-switch-base.component";

@Component({
  selector: "cg-channel-switch-call-failure",
  templateUrl: "./channel-switch-call-failure.component.html",
  styleUrls: ["./channel-switch-call-failure.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [channelSwitchAnimation],
  standalone: true,
  imports: [TranslocoPipe, OlbHeadlineComponent, CtaComponent]
})
export class ChannelSwitchCallFailureComponent extends ChannelSwitchBaseComponent {
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly trackingService = inject(TrackingService);
  protected readonly scrollService = inject(ScrollService);
  protected readonly isBrowser: boolean = inject(IS_BROWSER_PLATFORM);

  public callCenterAgent = customerCallsCallCenter;

  @HostBinding("@fadeIn") protected fadeIn = true;

  // failure component
  public callCallCenterInCaseOfError() {
    this.callServiceCenter();
  }

  private callServiceCenter() {
    if (this.isBrowser) {
      const tel = FormatTelLinkPipe.getPlainPhoneNumber(PhoneNumber.CS_SPECIAL_VEHICLE);
      this.trackingService.trackEvent({
        eventAction: "tap-to-call",
        eventLabel: tel
      });
      window.location.href = `tel:${tel}`;
    }
  }
}
