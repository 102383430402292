import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent } from "@cg/core/ui";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { CtalinkComponent, SplitViewComponent } from "@cg/shared";
import { CarIdentifiedComponent } from "../car-identified.component";

@Component({
  selector: "cg-car-identified-prefix",
  standalone: true,
  imports: [TranslocoPipe, IconComponent, OlbHeadlineComponent, CtalinkComponent, SplitViewComponent],
  templateUrl: "./car-identified-prefix.component.html",
  styleUrl: "./car-identified-prefix.component.css",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarIdentifiedPrefixComponent extends CarIdentifiedComponent {}
