import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { catchError, filter, map, mergeMap, take, tap, withLatestFrom } from "rxjs/operators";
import { CustomerCaseActions, CustomerCaseFacade } from "@cg/olb/state";
import { SpinnerFacade } from "@cg/spinner";
import { AppointmentService } from "@cg/appointment-ui";
import { ServiceCenter } from "@cg/core/interfaces";
import { UnifiedError } from "@cg/core/types";
import { errorToString } from "@cg/core/utils";
import { LoginActions, MY_CARGLASS_LOGIN_URL, MyCarglassNotificationStatus } from "@cg/my-carglass-login";
import { ScrollService } from "@cg/olb/shared";
import {
  CustomerCase,
  CustomerCaseEditContact,
  CustomerCaseService,
  OverlayService,
  Resume,
  ServiceCenterService
} from "@cg/shared";
import { CancelAppointmentSuccessComponent } from "../components/detail/components/my-carglass-overlays/cancel-appointment-success/cancel-appointment-success.component";
import { CancelAppointmentStatus } from "../enum/CancelAppointmentStatus.enum";
import { AppointmentCancelation } from "../interfaces/appointment-cancelation.interface";
import { MyCarglassUpdateAppointment } from "../interfaces/my-carglass-update-appointment.interface";
import * as DetailActions from "./detail.actions";
import { DetailFacade } from "./detail.facade";

interface CustomerCaseId {
  customerCaseId?: string;
}

interface RequestCustomerCaseAction {
  payload: CustomerCase | CustomerCaseId;
}

@Injectable()
export class DetailEffects {
  private readonly actions$ = inject(Actions);
  private readonly customerCaseService = inject(CustomerCaseService);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);
  private readonly router = inject(Router);
  private readonly overlayService = inject(OverlayService);
  private readonly appointmentService = inject(AppointmentService);
  private readonly spinnerFacade = inject(SpinnerFacade);
  private readonly serviceCenterService = inject(ServiceCenterService);
  private readonly detailFacade = inject(DetailFacade);
  private readonly scrollService = inject(ScrollService);

  public setMyCarglassDetailObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.setResume),
      withLatestFrom(this.customerCaseFacade.customerCase$),
      mergeMap(([_action, customerCase]: [{ resume: Resume }, CustomerCase]) =>
        this.customerCaseService.getCustomerCaseEditContact$(customerCase.id).pipe(
          map((customerCaseEditContact: CustomerCaseEditContact) =>
            DetailActions.setMycarglassDetailObject({
              payload: {
                customerCase: customerCase,
                customerCaseEditContact: customerCaseEditContact,
                toastStatus: null
              }
            })
          )
        )
      )
    )
  );

  public getServiceCenterDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailActions.getServiceCentersDetails),
      map(({ payload }: { payload: string[] }) => payload),
      mergeMap((payload: string[]) =>
        this.serviceCenterService.getServiceCenters(payload).pipe(
          filter((serviceCenters: ServiceCenter[]) => serviceCenters.length === 1),
          take(1),
          mergeMap((serviceCenters: ServiceCenter[]) =>
            of(DetailActions.getServiceCentersDetailsSuccessReceived({ payload: serviceCenters[0] }))
          ),
          catchError((e: UnifiedError) =>
            of(DetailActions.getServiceCentersDetailsFailureReceived({ payload: errorToString(e) }))
          )
        )
      )
    )
  );

  public updateAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailActions.updateAppointment),
      mergeMap((action: { payload: MyCarglassUpdateAppointment }) => {
        this.spinnerFacade.showSpinner({});
        return this.appointmentService.confirmAppointment(action.payload.setAppointmentPayload).pipe(
          map(() => {
            this.overlayService.close();
            this.spinnerFacade.hideSpinner();
            return DetailActions.updateAppointmentSuccessReceived({
              payload: action.payload.setAppointmentPayload
            });
          }),
          catchError(() => {
            this.spinnerFacade.hideSpinner();
            return of(DetailActions.updateAppointmentFailureReceived());
          })
        );
      })
    )
  );

  public getCustomerCase$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailActions.getCustomerCase),
      map((action: { payload: { customerCaseId: string } }) => this.getCustomerCaseId(action)),
      mergeMap((customerCaseId: string) =>
        forkJoin([
          this.customerCaseService.getCustomerCase$(customerCaseId),
          this.customerCaseService.getCustomerCaseEditContact$(customerCaseId)
        ]).pipe(
          mergeMap(([customerCase, customerCaseEditContact]: [CustomerCase, CustomerCaseEditContact]) => [
            DetailActions.setMycarglassDetailObject({
              payload: {
                customerCase: customerCase,
                customerCaseEditContact: customerCaseEditContact,
                toastStatus: null
              }
            }),
            CustomerCaseActions.fetchCustomerCaseSuccess({ payload: customerCase })
          ])
        )
      ),
      catchError(() => {
        this.router.navigate([MY_CARGLASS_LOGIN_URL]);
        return of(DetailActions.getCustomerCaseFailureReceived());
      })
    )
  );

  public updateCustomerContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailActions.updateCustomerContact),
      withLatestFrom(this.customerCaseFacade.customerCaseId$),
      mergeMap(([{ payload }, customerCaseId]: [{ payload: CustomerCaseEditContact }, string]) =>
        this.customerCaseService.updateCustomerCaseEditContact$(customerCaseId, payload).pipe(
          tap(() => this.overlayService.close()),
          map((res: CustomerCaseEditContact) => DetailActions.updateCustomerContactSuccess({ payload: res })),
          catchError((error: HttpErrorResponse) =>
            of(DetailActions.updateCustomerContactFailure({ error: errorToString(error) }))
          )
        )
      )
    )
  );

  public updateCustomerCaseOnUpdateCustomerContactSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailActions.updateCustomerContactSuccess),
      withLatestFrom(this.customerCaseFacade.customerCaseId$),
      map(([_, customerCaseId]: [Action, string]) => DetailActions.getCustomerCase({ payload: { customerCaseId } }))
    )
  );

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailActions.logout),
      mergeMap(
        (response: { payload: { isAutoLogout?: boolean; isCancelLogout?: boolean; customRouting?: string[] } }) => {
          const statusToast = response.payload.isAutoLogout
            ? MyCarglassNotificationStatus.LOGOUT_INACTIVITY
            : MyCarglassNotificationStatus.LOGOUT;

          this.router.navigate(response.payload.customRouting ?? [MY_CARGLASS_LOGIN_URL]).then(() => {
            if (response.payload.isCancelLogout) {
              this.scrollService.scrollToTop("instant");
              this.overlayService.open(CancelAppointmentSuccessComponent);
            }
          });

          return [
            DetailActions.resetState(),
            LoginActions.resetState(),
            LoginActions.setNotificationStatus({ payload: statusToast })
          ];
        }
      )
    )
  );

  public cancelAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DetailActions.cancelAppointment),
      withLatestFrom(this.detailFacade.customerCase$),
      mergeMap(([action, customerCase]: [{ payload: AppointmentCancelation }, CustomerCase]) => {
        const { cancelId, reason } = action.payload;
        const { orderNumber, id: customerCaseId } = customerCase;
        return this.appointmentService.cancelAppointment(orderNumber, customerCaseId, cancelId, reason).pipe(
          mergeMap(() => [DetailActions.cancelAppointmentStatus({ payload: CancelAppointmentStatus.SUCCESS })]),
          catchError(() => [DetailActions.cancelAppointmentStatus({ payload: CancelAppointmentStatus.FAILURE })])
        );
      })
    )
  );

  private isCustomerCase(action: RequestCustomerCaseAction): action is { payload: CustomerCase } {
    return (action.payload as CustomerCase).id !== undefined;
  }

  private isCustomerCaseId(action: RequestCustomerCaseAction): action is { payload: CustomerCaseId } {
    return (action.payload as CustomerCaseId).customerCaseId !== undefined;
  }

  private getCustomerCaseId(action: RequestCustomerCaseAction | { payload: CustomerCase }): string | undefined {
    if (this.isCustomerCaseId(action)) {
      return action.payload.customerCaseId;
    }

    if (this.isCustomerCase(action)) {
      return action.payload.id;
    }
  }
}
