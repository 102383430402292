import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { AppointmentFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineType, ParagraphComponent } from "@cg/core/ui";
import { ABTest } from "@cg/core/utils";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { AvailableServiceCenters, BaseButtonComponent, DrawerComponent, OverlayService } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { NewAppointmentMultiSelectComponent } from "../new-appointment-multi-select/new-appointment-multi-select.component";

@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE)
@ABTest(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
@Component({
  selector: "cg-new-appointment-sc-select-dialog",
  templateUrl: "./new-appointment-sc-select-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    DrawerComponent,
    OlbHeadlineComponent,
    NewAppointmentMultiSelectComponent,
    BaseButtonComponent,
    ParagraphComponent
  ]
})
export class NewAppointmentScSelectDialogComponent implements OnInit {
  private readonly overlayService = inject(OverlayService);
  private readonly appointmentFacade = inject(AppointmentFacade);
  private readonly fb = inject(FormBuilder);

  public destroyRef = inject(DestroyRef);
  public readonly headlineType = HeadlineType;
  public form: FormGroup<{ selectedSc: FormControl<string[]> }>;
  public availableServiceCenters$: Observable<AvailableServiceCenters[]>;
  public locality$ = this.appointmentFacade.locality$;

  public ngOnInit(): void {
    this.initAvailableServiceCenters();
    this.initForm();
    this.initDefaultSelectAllIds();
  }

  public clickedOk(): void {
    this.appointmentFacade.setSelectedServiceCenterIds(this.form.controls.selectedSc.value);
    this.overlayService.close();
  }

  public clickedReset(): void {
    this.appointmentFacade.setSelectedServiceCenterIds([]);
    this.form.controls.selectedSc.setValue([]);
    this.overlayService.close();
  }

  private initForm() {
    this.form = this.fb.group({
      selectedSc: []
    });
  }

  private initDefaultSelectAllIds() {
    this.appointmentFacade.selectedServiceCenterIds$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((scIds: string[]) => {
        this.form.controls.selectedSc.setValue(scIds);
      });
  }

  private initAvailableServiceCenters() {
    this.availableServiceCenters$ = this.appointmentFacade.availableServiceCenters$.pipe(
      map((serviceCenters: AvailableServiceCenters[]) =>
        [...serviceCenters].sort((a: AvailableServiceCenters, b: AvailableServiceCenters) => a.distance - b.distance)
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }
}
