import { CommonModule } from "@angular/common";
import { Component, effect, inject, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { MatCard, MatCardContent, MatCardHeader } from "@angular/material/card";
import { TranslocoPipe } from "@jsverse/transloco";
import { ConfigFacade } from "@cg/config";
import { OpportunityConfig } from "@cg/core/interfaces";
import {
  FakeDropdownComponent,
  HeadlineComponent,
  HeadlineType,
  IconComponent,
  ParagraphComponent,
  PictureComponent
} from "@cg/core/ui";
import { CustomerratingMinimalComponent, TrustpilotService } from "@cg/customerrating";
import { EnvironmentService } from "@cg/environments";
import {
  arrowsIcon,
  bulletpointIcon,
  confirmedIcon,
  insuranceIcon,
  noPaymentCheckedIcon,
  timeCheckedIcon,
  windscreenAvailableIcon
} from "@cg/icon";
import {
  BrandingComponent,
  BreakpointService,
  CtaComponent,
  ListComponent,
  OptionSelectionItem,
  OptionsSelectionComponent,
  OverlayService,
  RadioButtonGroup,
  RadioButtonGroupComponent,
  ResponsiveBgDirective,
  SafeContentPipe
} from "@cg/shared";
import type { Cta, Picture } from "@cg/content-api/typescript-interfaces";
import { OVERLAY_POSITION } from "@cg/core/enums";
import { InsuranceType } from "../enums/insurance-type.enum";
import { InsuranceOverlayComponent } from "./insurance-overlay/insurance-overlay.component";

@Component({
  selector: "lib-cost-check",
  standalone: true,
  imports: [
    CommonModule,
    BrandingComponent,
    CtaComponent,
    CustomerratingMinimalComponent,
    HeadlineComponent,
    ResponsiveBgDirective,
    PictureComponent,
    RadioButtonGroupComponent,
    FakeDropdownComponent,
    OptionsSelectionComponent,
    IconComponent,
    ParagraphComponent,
    MatCard,
    MatCardHeader,
    MatCardContent,
    ListComponent,
    TranslocoPipe,
    SafeContentPipe
  ],
  templateUrl: "./cost-check.component.html"
})
export class CostCheckComponent {
  private readonly trustpilotService = inject(TrustpilotService);
  private readonly overlayService = inject(OverlayService);
  private readonly breakpointService = inject(BreakpointService);
  private readonly configFacade = inject(ConfigFacade);
  private readonly environmentService = inject(EnvironmentService);

  public consent = this.trustpilotService.initialized;
  public isUntilFullHD = toSignal(this.breakpointService.isUntilFullHD$);
  public opportunityConfig = toSignal(this.configFacade.opportunityConfig$);

  public isKasko = signal<boolean | undefined>(undefined);
  public selectedInsurance = signal<OptionSelectionItem | undefined>(undefined);

  public repairList: { key: string; value: string }[] = [];
  public replaceList: { key: string; value: string }[] = [];

  public readonly tuevPicture: Picture = {
    sizes: [],
    source: `${this.environmentService.env.assetPath}/cost-check/tuev_2024.webp`,
    alt: "costCheck.customerRating.subtitle",
    ngTemplate: "cgPicture"
  };

  public cta: Cta = {
    id: "costCheck-cta",
    title: "costCheck.cta.button",
    link: {
      href: "https://www.carglass.de/olb",
      text: "costCheck.cta.button",
      routerLink: false,
      target: "_self"
    },
    variation: "primary",
    arrowDirection: "right",
    icon: arrowsIcon,
    ngTemplate: "cgCta"
  };

  public insuranceOptions: RadioButtonGroup = {
    controlName: "costCheck.insurace.title",
    buttons: [
      {
        title: "costCheck.insurance.buttons.yes",
        radio: { id: "insurance-kasko", value: InsuranceType.KASKO }
      },
      {
        title: "costCheck.insurance.buttons.no",
        radio: { id: "insurance-not-kasko", value: InsuranceType.NOT_KASKO }
      }
    ]
  };

  protected readonly insuranceIcon = insuranceIcon;
  protected readonly timeCheckedIcon = timeCheckedIcon;
  protected readonly noPaymentCheckedIcon = noPaymentCheckedIcon;
  protected readonly windscreenAvailableIcon = windscreenAvailableIcon;
  protected readonly bulletpointIcon = bulletpointIcon;
  protected readonly confirmedIcon = confirmedIcon;
  protected readonly headlineType = HeadlineType;

  public constructor() {
    effect(() => {
      if (this.consent()) {
        this.trustpilotService.reinitialize();
      }
    });

    effect(() => {
      if (this.opportunityConfig()) {
        this.initPrices(this.opportunityConfig());
      }
    });
  }

  public onSelectedValueChanged(insuranceType: InsuranceType): void {
    this.isKasko.set(insuranceType === InsuranceType.KASKO);
  }

  public onInsuranceSelectionClicked(): void {
    const itemSelected = (item: OptionSelectionItem) => this.selectInsurance(item);
    this.overlayService.open(
      InsuranceOverlayComponent,
      {
        itemSelected
      },
      { position: OVERLAY_POSITION.CENTER }
    );
  }

  private initPrices(config: OpportunityConfig): void {
    this.repairList = [
      { key: "costCheck.withoutInsurance.priceList.repair.oneStone", value: config.price_1_stone?.replace(".", ",") },
      { key: "costCheck.withoutInsurance.priceList.repair.twoStones", value: config.price_2_stone?.replace(".", ",") }
    ];

    this.replaceList = [
      {
        key: "costCheck.withoutInsurance.priceList.replace.frontWithoutAssistant",
        value: config.price_front_without_assistant
      },
      {
        key: "costCheck.withoutInsurance.priceList.replace.frontWithAssistant",
        value: config.price_front_with_assistant
      },
      { key: "costCheck.withoutInsurance.priceList.replace.side", value: config.price_side },
      { key: "costCheck.withoutInsurance.priceList.replace.rear", value: config.price_rear },
      { key: "costCheck.withoutInsurance.priceList.replace.emergency", value: config.price_emergency_glass }
    ];
  }

  private selectInsurance(item: OptionSelectionItem): void {
    this.selectedInsurance.set(item);
  }
}
