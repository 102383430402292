import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, filter, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { DamageService } from "@cg/olb/services";
import { AppointmentFacade, CustomerCaseFacade, DamageActions, DamageState, ProcessActions } from "@cg/olb/state";
import { ResumeFacade } from "@cg/resume-core";
import { errorToString } from "@cg/core/utils";
import { DamageRequest, DamageResponse, ProcessId, Resume, ResumeType, toCompromisedPart } from "@cg/shared";

@Injectable()
export class DamageEffects {
  private actions$ = inject(Actions);
  private damageService = inject(DamageService);
  private customerCaseFacade = inject(CustomerCaseFacade);
  private appointmentFacade = inject(AppointmentFacade);
  private resumeFacade = inject(ResumeFacade);

  public updateDamage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DamageActions.updateDamage),
      withLatestFrom(this.resumeFacade.resumeResponse$),
      tap(([_action, resumeResponse]: [{ payload: DamageState } & Action<"[Damage] Update Damage">, Resume]) => {
        if (resumeResponse?.resumeType === ResumeType.B2C_OLB) {
          this.appointmentFacade.resetState();
        }
      }),
      map(
        ([action, _resumeResponse]: [{ payload: DamageState } & Action<"[Damage] Update Damage">, Resume]) =>
          action.payload
      ),
      withLatestFrom(
        this.customerCaseFacade.customerCaseId$,
        this.appointmentFacade.formattedAddress$,
        this.appointmentFacade.selectedServiceCenterIds$
      ),
      filter((res: [DamageState, string, string, string[]]) => !!res[1]),
      mergeMap(
        ([damageState, customerCaseId, formattedAddress, selectedServiceCenterIds]: [
          DamageState,
          string,
          string,
          string[]
        ]) => {
          const damage = this.createDamageRequestFromState(damageState);
          const currentFormattedAddress = formattedAddress;
          const currentSelectedServiceCenterIds = selectedServiceCenterIds;

          return this.damageService.updateDamage(damage, customerCaseId).pipe(
            tap(() => {
              this.appointmentFacade.setFormattedAddress(currentFormattedAddress);
              // TODO: check if whole array is needed
              this.appointmentFacade.setSelectedServiceCenterIds(currentSelectedServiceCenterIds);
            }),
            map((payload: DamageResponse) => DamageActions.updateDamageSuccess({ payload })),
            catchError((error: HttpErrorResponse) =>
              of(DamageActions.updateDamageFailure({ error: errorToString(error) }))
            )
          );
        }
      )
    )
  );

  public resetDamagedWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessActions.rewindProcess),
      filter(({ payload }: { payload: ProcessId }) => payload === "damage-window"),
      map(() => DamageActions.setDamageWindow({ payload: null }))
    )
  );

  public resetDamagedSideWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessActions.rewindProcess),
      filter(({ payload }: { payload: ProcessId }) => payload === "opportunity-funnel-damage-side"),
      map(() => DamageActions.setDamagedSideWindowCount({ payload: null }))
    )
  );

  private createDamageRequestFromState(state: DamageState): DamageRequest {
    const damage: DamageRequest = {
      damageType: state.damageType,
      compromisedPart: toCompromisedPart[state.damageWindow],
      occurrenceDate: state.damageDate,
      damageSize: state.damageSize,
      damageLocation: state.damageLocation,
      damageChipCount: state.damageChipCount
    };

    if (state.id) {
      damage.id = state.id;
    }

    return damage;
  }
}
