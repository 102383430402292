import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { HeadlineComponent, SliderComponent, SliderItemComponent } from "@cg/cgx-ui/core";
import type { CgxHeadline, CgxTeaser } from "@cg/content-api/typescript-interfaces";
import { TeaserItemComponent } from "./teaser-item/teaser-item.component";

@Component({
  selector: "cgx-teaser",
  templateUrl: "./teaser.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadlineComponent, TeaserItemComponent, SliderComponent, SliderItemComponent]
})
export class TeaserComponent {
  public content = input.required<CgxTeaser & { headline?: CgxHeadline }, CgxTeaser>({
    transform: (value: CgxTeaser) => ({
      ...value,
      headline: value.title
        ? {
            title: value.title,
            type: "h2",
            ngTemplate: "cgxHeadline"
          }
        : undefined
    })
  });
}
