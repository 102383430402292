import { AsyncPipe, NgClass } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable, Subject, timer, withLatestFrom } from "rxjs";
import { filter, map, mergeMap, shareReplay, take, takeUntil, tap } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { SwitchChannelPayloadService } from "@cg/olb/services";
import { ChannelSwitchFacade, CustomerCaseFacade, DamageFacade, ProcessFacade } from "@cg/olb/state";
import { SpinnerFacade } from "@cg/spinner";
import * as CgValidators from "@cg/validators";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { List } from "@cg/content-api/typescript-interfaces";
import { AddFormControls } from "@cg/core/types";
import { HeadlineComponent, HeadlineType, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { isOpportunityFunnel } from "@cg/core/utils";
import { cccAgentSmallIcon, phoneIcon } from "@cg/icon";
import { LpnInputComponent, LpnInputForm } from "@cg/lpn-input";
import {
  ChannelSwitchReason,
  ChannelSwitchTrackingReason,
  damageChipCountToGmCount,
  isDirectResumeFn,
  mapChannelSwitchReasonToPhoneNumber,
  ScrollService
} from "@cg/olb/shared";
import {
  ChannelSwitchDamage,
  ComponentOverarchingChangeDetectionService,
  DamageWindow,
  ListComponent,
  Lpn,
  OverlayService,
  ProcessMetadata,
  RequiredService,
  SelfCallDialogComponent
} from "@cg/shared";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { channelSwitchAnimation } from "../../animations/channel-switch.animation";
import { ChannelSwitchForm } from "../../interfaces/channel-switch-form.interface";
import { CallbackTimePipe } from "../../pipes/callback-time.pipe";
import { ChannelSwitchTrackingService } from "../../services/channel-switch-tracking.service";
import { ChannelSwitchBaseComponent } from "../channel-switch-base/channel-switch-base.component";
import { ChannelSwitchPhoneInputComponent } from "../channel-switch-phone-input/channel-switch-phone-input.component";
@Component({
  selector: "cg-channel-switch-general",
  templateUrl: "./channel-switch-general.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [channelSwitchAnimation],
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    HeadlineComponent,
    ParagraphComponent,
    IconComponent,
    ListComponent,
    LpnInputComponent,
    ChannelSwitchPhoneInputComponent
  ]
})
export class ChannelSwitchGeneralComponent
  extends ChannelSwitchBaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly trackingService = inject(TrackingService);
  protected readonly scrollService = inject(ScrollService);
  private readonly channelSwitchFacade = inject(ChannelSwitchFacade);
  private readonly damageFacade = inject(DamageFacade);
  private readonly spinnerFacade = inject(SpinnerFacade);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);
  private readonly switchChannelPayloadService = inject(SwitchChannelPayloadService);
  private readonly channelSwitchTrackingService = inject(ChannelSwitchTrackingService);
  private readonly overlayService = inject(OverlayService);
  private readonly translocoService = inject(TranslocoService);
  private readonly changeDetectionService = inject(ComponentOverarchingChangeDetectionService);
  private _olbConfig: OlbConfiguration = inject(OLB_CONFIG);

  public readonly HeadlineType = HeadlineType;
  public readonly phoneIcon = phoneIcon;
  public readonly cccAgentSmallIcon = cccAgentSmallIcon;
  public readonly EWT_REFRESH_TIME = 300000; // 5 min

  public destroyRef = inject(DestroyRef);

  public isLpnInputVisible = true;
  public isListVisible = true;

  public isPhoneInputCheckboxVisible = false;
  public list: List;

  public needFallback$ = this.channelSwitchFacade.needFallback$.pipe(
    shareReplay(1),
    takeUntilDestroyed(this.destroyRef)
  );
  public callbackText$: Observable<string>;

  @ViewChild("phoneInput")
  public phoneInput: ChannelSwitchPhoneInputComponent;

  @HostBinding("@fadeIn")
  protected fadeIn = true;

  private channelSwitchReason: ChannelSwitchReason;
  private damage: ChannelSwitchDamage;
  private customerCaseId: string;

  private destroy$ = new Subject<void>();

  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.initCustomerCaseId();
    this.initDamage();
    this.initVisibleComponents();
    this.initNeedFallback();
    this.initListItems();
    this.initCallbackText();
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<ChannelSwitchForm>>({
      phoneNumber: new FormControl<string>(null, CgValidators.phoneValidators()),
      channelSwitchCheckbox: new FormControl<boolean>(false, Validators.requiredTrue),
      lpn: new FormControl<LpnInputForm>(null, Validators.required)
    });
  }

  public setFormValues(): void {
    this.initIsPhoneInputCheckboxVisible();
    this.phoneInput.setFormValues();

    this.damageFacade.lpn$
      .pipe(
        filter((lpn: Lpn) => !!lpn),
        tap((lpn: Lpn) => {
          this.form.controls.lpn.patchValue(lpn);
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    if (isDirectResumeFn(this._olbConfig.entryChannel)) {
      this.isLpnInputVisible = false;
      this.form.controls.lpn.disable({ onlySelf: true, emitEvent: false });
    }
  }

  public saveForm(): void {
    this.phoneInput.saveForm();
  }

  public triggerCallBack(): void {
    // get callback
    this.form.markAllAsTouched();
    this.phoneInput.markForCheck();
    this.changeDetectionService.changeDetectionRequest$.next();

    if (!this.form.valid) {
      if (this.form.get("phoneNumber").invalid) {
        this.channelSwitchTrackingService.trackChannelSwitch(ChannelSwitchTrackingReason.ERROR, "phone-number");
      }

      if (this.form.controls.channelSwitchCheckbox.invalid) {
        this.channelSwitchTrackingService.trackChannelSwitch(ChannelSwitchTrackingReason.ERROR, "missing-opt-in");
      }

      if (this.form.controls.lpn.invalid) {
        this.channelSwitchTrackingService.trackChannelSwitch(ChannelSwitchTrackingReason.ERROR, "missing-lpn");
      }

      return;
    }

    this.switchChannelAndSyncWithEbs();
    this.processFacade.enterChannelSwitch();
  }

  public ngOnDestroy(): void {
    this.phoneInput?.saveForm();
    this.destroy$.next();
  }

  public openSelfCallPopup(): void {
    this.overlayService.open(
      SelfCallDialogComponent,
      { localPhoneNumber: mapChannelSwitchReasonToPhoneNumber(this.channelSwitchReason) },
      { positionByBreakpoints: OverlayService.POSITION_M_CENTER }
    );
  }

  private initCustomerCaseId() {
    this.customerCaseFacade.customerCaseId$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((customerCaseId: string) => {
        this.customerCaseId = customerCaseId;

        this.cdr.markForCheck();
      });
  }

  private initDamage() {
    this.damageFacade.damage$
      .pipe(
        tap(
          (damage: {
            id: string;
            compromisedPart: DamageWindow;
            requiredService: RequiredService;
            damageChipCount: number;
            damageDate: string;
          }) => {
            const { id, compromisedPart, requiredService, damageChipCount, damageDate } = damage;
            this.damage = {
              id,
              compromisedPart,
              requiredService,
              gmCount: damageChipCountToGmCount(damageChipCount),
              occurrenceDate: damageDate
            };
          }
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private initNeedFallback() {
    this.needFallback$
      .pipe(
        filter((needFallback: boolean) => !needFallback),
        mergeMap(() => timer(this.EWT_REFRESH_TIME, this.EWT_REFRESH_TIME)),
        tap(() => this.channelSwitchFacade.getEstimatedWaitingTime()),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private switchChannelAndSyncWithEbs(): void {
    this.channelSwitchTrackingService.trackChannelSwitch(ChannelSwitchTrackingReason.TAP_TO_CALLBACK);

    const payload = this.switchChannelPayloadService.buildSwitchChannelPayload({
      customerCaseId: this.customerCaseId,
      reason: this.channelSwitchReason,
      phoneNumber: this.form.controls.phoneNumber.value,
      damage: this.damage,
      lpnInputForm: this.form.controls.lpn.value
    });

    this.customerCaseFacade.switchChannel(payload);

    this.needFallback$
      .pipe(
        filter((needFallback: boolean) => !needFallback),
        tap(() => this.spinnerFacade.forceShowSpinner()),
        // Order number aus EBS wird asynchron verarbeitet. Wir warten 10sec
        mergeMap(() => timer(10000)),
        tap(() => {
          this.spinnerFacade.hideForcedSpinner();

          this.channelSwitchFacade.getConversationId({
            id: this.customerCaseId,
            reason: this.channelSwitchReason,
            phoneNumber: this.form.controls.phoneNumber.value
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  private initCallbackText() {
    this.callbackText$ = this.channelSwitchFacade.needFallback$.pipe(
      withLatestFrom(this.channelSwitchFacade.estimatedTimeToCallback$),
      map(([needFallback, estimatedTimeToCallback]: [boolean, number]) => {
        if (needFallback) {
          return this.translocoService.translate("channelSwitch.fallback.title");
        }
        const pipe = new CallbackTimePipe();
        const textCallbackTime = pipe.transform(estimatedTimeToCallback);

        return this.translocoService.translate("channelSwitch.callbackTime", {
          time: textCallbackTime,
          unit: this.getCallbackTimeUnit(estimatedTimeToCallback)
        });
      })
    );
  }

  private initListItems(): void {
    this.list = {
      items: this.translocoService.translate<string[]>("channelSwitch.list"),
      type: "check",
      ngTemplate: "cgList"
    };
  }

  private getCallbackTimeUnit(estimatedTimeToCallback: number): string {
    if (estimatedTimeToCallback > 60) {
      return this.translocoService.translate("channelSwitch.unit.minutes");
    } else if (estimatedTimeToCallback === 60) {
      return this.translocoService.translate("channelSwitch.unit.minute");
    }

    return this.translocoService.translate("channelSwitch.unit.seconds");
  }

  private initIsPhoneInputCheckboxVisible() {
    this.form.controls.phoneNumber.valueChanges
      .pipe(
        filter(() => this.form.controls.phoneNumber.valid),
        tap(() => {
          this.isPhoneInputCheckboxVisible = true;
          this.cdr.markForCheck();
        }),
        take(1),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private initVisibleComponents() {
    this.processFacade.channelSwitchReason$
      .pipe(
        filter((reason: ChannelSwitchReason) => !!reason),
        tap((reason: ChannelSwitchReason) => {
          this.channelSwitchReason = reason;
          switch (reason) {
            case "VEHICLE_NOT_FOUND":
            case "PRODUCT_NOT_FOUND":
            case "MOBILE_SERVICE":
              this.isListVisible = false;
              this.isLpnInputVisible = false;
              this.cdr.markForCheck();
              break;
            case "SIDE_WINDOW":
            case "REAR_WINDOW":
            case "PAID_BY_DAMAGING_PARTY":
            case "MULTIPLE_WINDOWS":
            case "SPECIAL_VEHICLE":
            case "SUNROOF":
              this.isListVisible = false;
              this.cdr.markForCheck();
              break;
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.processFacade.processMetaData$
      .pipe(
        filter((processMetaData: ProcessMetadata[]) => isOpportunityFunnel(processMetaData)),
        tap(() => {
          this.isLpnInputVisible = false;
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
