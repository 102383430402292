import { inject, Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { setCurrentUrl } from "./web-content.actions";
import * as fromWebContent from "./web-content.reducer";
import * as WebContentSelectors from "./web-content.selectors";

@Injectable({
  providedIn: "root"
})
export class WebContentFacade {
  private readonly store = inject(Store<fromWebContent.WebContentPartialState>);

  public currentContent$ = this.store.pipe(select(WebContentSelectors.getCurrentContent));
  public error$ = this.store.pipe(select(WebContentSelectors.getError));
  public hasLoaded$ = this.store.pipe(select(WebContentSelectors.hasLoaded));
  public currentUrl$ = this.store.pipe(select(WebContentSelectors.getCurrentUrl));

  public setCurrentUrl(url: string): void {
    // we check if current url has changed, because ssr could already have set the url
    this.currentUrl$.pipe(take(1)).subscribe((currentUrl: string) => {
      if (currentUrl !== url) {
        this.store.dispatch(setCurrentUrl({ payload: url }));
      }
    });
  }
}
