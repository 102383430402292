<div class="grid grid-cols-6 justify-items-center gap-1">
  @for (weekDay of weekDays; track weekDay) {
    <div class="w-full rounded bg-cg-white py-1 pl-1 font-roboto-condensed text-[13px] t:pl-3">
      <p class="font-bold">{{ weekDay | date: "EE" | slice: 0 : 2 }}</p>
      <p>{{ weekDay | date: "dd.MM" }}</p>
    </div>
  }
  @for (dayItems of appointmentGridItems; track $index) {
    @for (item of dayItems; track item?.appointmentId) {
      <button
        class="flex h-full w-full cursor-pointer items-center justify-center rounded p-1 text-left font-roboto-condensed text-[13px] t:x-[p-3,py-1.5]"
        [ngClass]="{
          'border border-cg-yellow bg-cg-yellow-light-95': item?.customerAppointmentStart,
          '!cursor-default bg-cg-gray-light-70': !item?.customerAppointmentStart,
          '!bg-cg-yellow': item?.appointmentId === selectedAppointmentId && selectedAppointmentId !== null,
          'focus:bg-cg-gray-light-70 active:bg-cg-gray-light-70': !item
        }"
        [ngStyle]="!item ? { '-webkit-tap-highlight-color': 'transparent' } : {}"
        [disabled]="!item"
        (click)="isMobile ? bookAppointment(item?.appointmentId) : selectAppointment(item.appointmentId)"
      >
        @if (item?.customerAppointmentStart) {
          {{ item?.customerAppointmentStart | date: "HH:mm" }} - {{ item?.customerAppointmentEnd | date: "HH:mm" }}
        }
      </button>
    }
  }
</div>
