<div class="flex w-full items-center justify-around gap-4">
  @for (item of content().items; track item.title) {
    <div
      class="flex flex-1 flex-col items-center text-center"
      [ngClass]="{ '!flex-row gap-4': content().variation === 'large' }"
    >
      <cgx-icon class="[--icon-size:48px]" [content]="item.icon"></cgx-icon>
      <div>
        <cgx-paragraph
          class="text-balance text-[13px] leading-4"
          [ngClass]="{ '!text-wrap text-left text-base !leading-6': content().variation === 'large' }"
          [content]="{ text: item.title, ngTemplate: 'cgxParagraph' }"
        ></cgx-paragraph>
      </div>
    </div>
  }
</div>
