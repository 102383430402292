@if (content) {
  @for (item of content().content; track $index) {
    @if (isCgxPage()) {
      <cg-cms-cgx-host-container class="mx-auto max-w-container px-4 cgxT:px-8 cgxD:px-0" [content]="item">
      </cg-cms-cgx-host-container>
    } @else {
      <cg-cms-host-container class="mx-auto block max-w-cms-host px-4 t:px-6" [content]="item"> </cg-cms-host-container>
    }
  }
}
