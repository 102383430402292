<div class="mx-auto max-w-container t:mt-8 t:px-2 w:px-6 fhd:px-0">
  <div class="relative flex w-full flex-col gap-6 rounded-lg px-4 py-6 shadow-cockpit t:px-6 d:px-8 w:flex-row w:px-6">
    @if (content.badge) {
      <cg-badge
        class="absolute -left-2 -top-3 [&>span]:text-[13px] [&>span]:t:text-base"
        [text]="translate ? (content.badge.title | transloco) : content.badge.title"
        [variation]="content.badge.variation"
      ></cg-badge>
    }
    <div class="flex flex-1 flex-row justify-between gap-2 m:gap-3 w:gap-6">
      @for (item of content?.items; track item.title) {
        <div class="flex flex-1 flex-col items-center gap-2 m:gap-3 w:flex-row">
          <cg-icon class="h-12 w-12" [content]="item.icon"></cg-icon>
          <cg-paragraph
            class="text-center w:text-left [&>p]:font-roboto-condensed [&>p]:text-[13px] [&>p]:leading-4 [&>p]:w:text-sm [&>p]:w:leading-normal"
            [content]="translate ? (item.title | transloco) : item.title"
          >
          </cg-paragraph>
        </div>
      }
    </div>
    @if (content.cta) {
      <button
        class="w-full part-[icon-text-wrapper]:x-[h-14,justify-center,px-4,py-2] w:w-[300px]"
        cg-base-button
        [btnText]="content.cta.title"
        [iconPosition]="'left'"
        [icon]="content.cta.icon"
        (click)="openLink($event)"
      ></button>
    }
  </div>
</div>
