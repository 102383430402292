/* eslint-disable sonarjs/no-duplicate-string */
import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { asyncScheduler } from "rxjs";
import { AsyncScheduler } from "rxjs/internal/scheduler/AsyncScheduler";
import { delay, filter, map, tap, withLatestFrom } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { CustomerCaseSelectors, ProcessActions, ProcessSelectors } from "@cg/olb/state";
import { ResumeSelectors } from "@cg/resume-core";
import * as SpinnerActions from "@cg/spinner";
import { differenceInDays } from "date-fns";
import { CgAnalyzeService, TrackingEvent, TrackingEventFactory, TrackingService } from "@cg/analytics";
import { CustomerCase, ProcessId, Resume } from "@cg/shared";

@Injectable()
export class ProcessTrackingEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly trackingService = inject(TrackingService);
  private readonly analyzeService = inject(CgAnalyzeService);
  private readonly olbConfig: OlbConfiguration = inject(OLB_CONFIG);

  public enterOlb$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.enterOlb),
        withLatestFrom(
          this.store.select(ProcessSelectors.getCurrentProcessId),
          this.store.select(ResumeSelectors.resumeIsPossible),
          this.store.select(ProcessSelectors.restoreInProgress)
        ),
        filter(([_, __, restoreInProgress]: [Action, ProcessId, boolean, boolean]) => !restoreInProgress),
        tap(([_, processId]: [Action, ProcessId, boolean, boolean]) => this.trackingService.trackProcessById(processId))
      ),
    { dispatch: false }
  );

  public processBeforeGoBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.goBack),
        tap((action: { payload: ProcessId }) => {
          this.trackingService.trackEvent({
            eventAction: "back",
            eventLabel: this.trackingService.returnProcessId(action.payload)
          });
        })
      ),
    { dispatch: false }
  );

  public processBeforeGoForwardSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.goForwardSuccess),
        tap(({ payload }: { payload: { processId: ProcessId; eventAction: string } }) => {
          this.trackingService.trackEvent({
            eventAction: payload.eventAction,
            eventLabel: this.trackingService.returnProcessId(payload.processId)
          });
        })
      ),
    { dispatch: false }
  );

  public processBeforeGoForwardFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.goForwardFailure),
        tap((action: { payload: { processId: ProcessId; invalidFormFields: string[] } }) => {
          this.trackingService.trackMissingFormFields(action.payload.invalidFormFields);
          this.trackingService.trackEvent({
            eventAction: "next/fail",
            eventLabel: this.trackingService.returnProcessId(action.payload.processId)
          });
        })
      ),
    { dispatch: false }
  );

  public currentProcessId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.setCurrentProcess),
        filter(
          ({ payload }: { payload: ProcessId }) =>
            !["channel-switch", "opportunity-funnel-channel-switch"].includes(payload)
        ),
        withLatestFrom(
          this.store.select(ProcessSelectors.restoreInProgress),
          this.store.select(ResumeSelectors.getResumeSuccess),
          this.store.select(CustomerCaseSelectors.getCustomerCase)
        ),
        filter(
          ([_, _restoreInProgress, _resumeSuccess, customerCase]: [
            { payload: ProcessId },
            boolean,
            Resume,
            CustomerCase
          ]) => !!customerCase && !!customerCase.id
        ),
        tap(
          async ([{ payload }, restoreInProgress, resumeSuccess, customerCase]: [
            { payload: ProcessId },
            boolean,
            Resume,
            CustomerCase
          ]) => {
            await this.trackingService.trackProcessById(payload);
            await this.analyzeService.trackTileView({
              customerCaseId: customerCase.id,
              processId: payload,
              entryChannel: this.olbConfig.entryChannel
            });

            if (restoreInProgress) {
              this.trackingService.trackEvent(
                {
                  eventAction: "my-carglass",
                  eventLabel: "start-restore-session",
                  restore: {
                    "days-since-saved": differenceInDays(new Date(Date.now()), new Date(resumeSuccess.created))
                  }
                },
                true
              );
            }
          }
        )
      ),
    { dispatch: false }
  );

  public resumeIsBeingPrepared$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcessActions.restoreHasBegun),
      withLatestFrom(this.store.select(ResumeSelectors.resumeTrackingProperties)),
      tap(([_, trackingProperties]: [Action, Partial<TrackingEvent>]) => {
        if (trackingProperties) {
          this.trackingService.trackEvent(
            TrackingEventFactory({
              ...trackingProperties,
              event: "ga-event",
              eventCategory: "olb",
              eventAction: "my-carglass",
              eventLabel: "restore-previous-selections"
            })
          );
          this.trackingService.disableTrackingEvents(true);
        }
      }),
      map(() => SpinnerActions.forceShowSpinner())
    )
  );

  public resumeIsDone$ = createEffect(
    () =>
      ({ timeToDelay = 5000, scheduler = asyncScheduler }: { timeToDelay?: number; scheduler?: AsyncScheduler } = {}) =>
        this.actions$.pipe(
          ofType(ProcessActions.restoreIsDone),
          delay(timeToDelay, scheduler), // make sure no async tracking events slip through
          map(() => {
            this.trackingService.disableTrackingEvents(false);
            return SpinnerActions.hideForcedSpinner();
          })
        )
  );
}
