import { inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigFacade } from "@cg/config";
import type { configReducer } from "@cg/config";
import { Content } from "../interfaces/content.interface";

@Injectable({
  providedIn: "root"
})
export class WebContentPlaceholderService {
  private readonly configFacade = inject(ConfigFacade, { optional: true });

  public getContentWithReplacedPlaceholders(content: Content[]): Observable<Content[]> {
    if (!this.configFacade) {
      return of(content);
    }

    return this.configFacade.configState$.pipe(
      map((configState: configReducer.ConfigState) => this._replaceConfigPlaceholderWithValues(content, configState))
    );
  }

  private _replaceConfigPlaceholderWithValues(content: Content[], configState: configReducer.ConfigState): Content[] {
    const CONFIG_REGEXP = /\[CONFIG:(?<group>[0-9a-z_]*):(?<key>[0-9a-z_]*)\]/gi;
    let contentString = JSON.stringify(content);

    for (const match of contentString.matchAll(CONFIG_REGEXP)) {
      const { group, key } = match?.groups ?? {};
      const value = configState?.[group]?.[key];

      if (!value) {
        console.error(`Invalid placeholder: ${match[0]}`);
        continue;
      }

      contentString = contentString.replaceAll(match[0], value);
    }

    return JSON.parse(contentString);
  }
}
