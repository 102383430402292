import { AsyncPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  ControlContainer,
  ControlValueAccessor,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR
} from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, filter, first, map, tap, withLatestFrom } from "rxjs/operators";
import { CustomerCaseFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import addWeeks from "date-fns/addWeeks";
import endOfDay from "date-fns/endOfDay";
import { TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import { EnvironmentService } from "@cg/environments";
import { mobileServiceIcon } from "@cg/icon";
import {
  Appointment,
  AppointmentData,
  AppointmentSearchForm,
  CtaTwoLinesComponent,
  CustomerCase,
  Damage,
  ErrorMessageComponent,
  InfoButtonComponent,
  LoadingSpinnerComponent,
  NextButtonComponent,
  RequiredService,
  Resume,
  SameWeekAppointments,
  ScrollDirective
} from "@cg/shared";
import type { Icon } from "@cg/content-api/typescript-interfaces";
import { AppointmentListService } from "../../services/appointment-list/appointment-list.service";
import { AppointmentAdverseBuyInfoComponent } from "../appointment-adverse-buy-info/appointment-adverse-buy-info.component";
import { AppointmentListItemComponent } from "../appointment-list-item/appointment-list-item.component";
import { AppointmentResumeInfoComponent } from "../appointment-resume-info/appointment-resume-info.component";

@Component({
  selector: "cg-appointment-list",
  templateUrl: "./appointment-list.component.html",
  styleUrls: ["./appointment-list.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => AppointmentListComponent),
      multi: true
    }
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    ScrollDirective,
    InfoButtonComponent,
    NextButtonComponent,
    LoadingSpinnerComponent,
    CtaTwoLinesComponent,
    AppointmentAdverseBuyInfoComponent,
    AppointmentListItemComponent,
    ErrorMessageComponent,
    AppointmentResumeInfoComponent
  ]
})
export class AppointmentListComponent implements OnInit, ControlValueAccessor, OnDestroy {
  public destroyRef = inject(DestroyRef);
  private readonly MAX_APPOINTMENT_SEARCH_WEEKS = 6;

  @Input() public appointmentData: AppointmentData;
  @Output() public mobileService = new EventEmitter<MouseEvent>();
  @Input() public showAppointmentDetails = true;
  @Output() public listScrolled = new EventEmitter();

  public form: FormGroup<AddFormControls<AppointmentSearchForm>>;

  private _weeks: SameWeekAppointments[] = [];
  private _value: string;
  private _disabled = false;
  private _prevHasAppointmentError = false;
  private _hasAdverseBuyAppointments$: Observable<boolean>;
  private clickListenerFn: () => void;

  private get activeMobileService(): boolean {
    return this.environmentService.env.features.mobileService;
  }

  @ViewChild("appointmentResumeInfo") public appointmentResumeInfoElementRef: ElementRef;

  public showAppointmentResumeInfo = false;

  public requiredService: RequiredService;
  public serviceIcon: Icon = mobileServiceIcon;

  public limitDate: Date;
  public previousLastAppointmentDateString: string;

  public get weeks(): SameWeekAppointments[] {
    return this._weeks;
  }

  public get value(): string {
    return this._value;
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  public get showMobileService$() {
    return combineLatest([
      this.appointmentData.mobileServiceAvailable$,
      this.appointmentData.appointmentNextLoading$
    ]).pipe(
      map(
        ([mobileServiceAvailable, appointmentNextLoading]: [boolean, boolean]) =>
          this.activeMobileService && mobileServiceAvailable && !appointmentNextLoading
      )
    );
  }

  public get appointmentNextLoading$() {
    return this.appointmentData.appointmentNextLoading$;
  }

  public get nextLoadingLimitReached$() {
    return this.appointmentData.nextLoadingLimitReached$;
  }

  public get hasAdverseBuyAppointments$() {
    return this._hasAdverseBuyAppointments$;
  }

  public get hasAppointmentError(): boolean {
    const { invalid, touched } = this.form.controls.selectedAppointmentId;

    const hasError = invalid && touched;

    if (this._prevHasAppointmentError !== hasError) {
      this._prevHasAppointmentError = hasError;

      if (hasError) {
        this.scrollToTop();
      }
    }

    return hasError;
  }

  public onChange = (_value: string) => {};
  public onTouched = () => {};

  public initLimitDate() {
    const todayEndDate = endOfDay(new Date(Date.now()));
    this.limitDate = addWeeks(todayEndDate, this.MAX_APPOINTMENT_SEARCH_WEEKS);
  }

  // eslint-disable-next-line max-params
  public constructor(
    private readonly appointmentListService: AppointmentListService,
    private readonly parent: FormGroupDirective,
    private readonly cdr: ChangeDetectorRef,
    private readonly trackingService: TrackingService,
    private readonly renderer: Renderer2,
    private readonly customerCaseFacade: CustomerCaseFacade,
    @Inject(IS_BROWSER_PLATFORM) private readonly isBrowser: boolean,
    private readonly environmentService: EnvironmentService
  ) {}

  public ngOnInit() {
    this._hasAdverseBuyAppointments$ = this.appointmentData.hasAdverseBuyAppointments$.pipe(
      distinctUntilChanged(),
      tap((hasAppointments: boolean) => this.trackAdverseBuyAppointmentsInfoIcon(hasAppointments))
    );
    this.form = this.parent.form;

    this.clickListenerFn = this.renderer.listen("window", "click", (e: Event) => {
      if (e.target !== this.appointmentResumeInfoElementRef?.nativeElement && this.showAppointmentResumeInfo) {
        this.showAppointmentResumeInfo = false;
        this.cdr.detectChanges();
      }
    });
    this.initLimitDate();
    this.appointmentData.confirmed$
      .pipe(
        filter((isConfirmed: boolean) => isConfirmed),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => (this.showAppointmentResumeInfo = false));

    this.appointmentData.availableAppointments$
      .pipe(
        filter((appointments: Appointment[]) => !!appointments),
        map((appointments: Appointment[]) =>
          appointments.filter(
            (appointment: Appointment) => new Date(appointment.customerAppointmentStart) < this.limitDate
          )
        ),
        withLatestFrom(
          this.appointmentData.resumeResponse$,
          this.appointmentData.selectedServiceCenterIds$,
          this.appointmentData.requiredService$,
          this.customerCaseFacade.customerCase$
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(
        ([appointments, resume, scIds, currentRequiredService, customerCase]: [
          Appointment[],
          Resume,
          string[],
          RequiredService,
          CustomerCase
        ]) => {
          const isRequiredServiceChangedOnResume = () => {
            if (!resume?.state) {
              return false;
            }
            const damageWithRequiredServiceChange = customerCase.damages.find(
              (damage: Damage) => damage.requiredService !== currentRequiredService
            );
            return damageWithRequiredServiceChange !== undefined;
          };

          if (
            !isRequiredServiceChangedOnResume() &&
            resume?.state?.appointment &&
            resume.state.appointment.serviceCenter === scIds?.[0]
          ) {
            const { customerAppointmentStart, customerAppointmentEnd } = resume?.state?.appointment ?? {
              customerAppointmentStart: undefined,
              customerAppointmentEnd: undefined
            };

            const possibleAppointment = this.findPossibleAppointment(
              customerAppointmentStart,
              customerAppointmentEnd,
              appointments
            );
            if (possibleAppointment) {
              this.writeValue(possibleAppointment.appointmentId);
            } else {
              this.showAppointmentResumeInfo = true;
              setTimeout(() => {
                this.showAppointmentResumeInfo = false;
                this.cdr.detectChanges();
              }, 4000);
            }
          }

          // @todo this can be refactored to use async pipe in template and omit cdr.markForCheck
          this._weeks = this.appointmentListService.prepareDataForView(appointments);

          this.cdr.detectChanges();
        }
      );

    this.appointmentData.requiredService$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((requiredService: RequiredService) => {
        // @todo this can be refactored to use async pipe in template and omit cdr.markForCheck and withLatestFrom() inside this component
        this.requiredService = requiredService;

        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    if (this.clickListenerFn) {
      this.clickListenerFn();
    }
  }

  private findPossibleAppointment(
    customerAppointmentStart: string,
    customerAppointmentEnd: string,
    appointments: Appointment[]
  ): Appointment {
    const start = new Date(customerAppointmentStart);
    const end = new Date(customerAppointmentEnd);
    const toleranceMin = 10;
    return appointments.find((appointment: Appointment) => {
      const addMin = (date: Date): Date => new Date(date.getTime() + 1000 * 60 * toleranceMin);
      const subMin = (date: Date): Date => new Date(date.getTime() - 1000 * 60 * toleranceMin);
      const appointmentStart = new Date(appointment.customerAppointmentStart);
      const appointmentEnd = new Date(appointment.customerAppointmentEnd);
      return (
        appointmentStart.toDateString() === start.toDateString() &&
        start >= subMin(appointmentStart) &&
        end <= addMin(appointmentEnd)
      );
    });
  }

  public writeValue(value: string): void {
    if (value !== undefined) {
      this._value = value;
      this.onChange(value);

      this.appointmentData.setAppointmentId(value);

      this.cdr.markForCheck();
    }
  }

  public registerOnChange(fn: (checked: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.cdr.markForCheck();
  }

  public onSelectedAppointmentChange(appointmentId: string) {
    if (appointmentId !== this.value) {
      this.writeValue(appointmentId);
    }
  }

  public mobileServiceClicked($event: MouseEvent): void {
    this.mobileService.emit($event);
  }

  public loadNextClicked() {
    this.appointmentData.availableAppointments$
      .pipe(
        first(),
        filter((appointments: Appointment[]) => appointments?.length > 0)
      )
      .subscribe((appointments: Appointment[]) => {
        const lastAppointment = [...appointments].pop();
        const lastAppointmentDateString = lastAppointment.availabilityPeriodEnd;
        this.appointmentData.getNextAppointments(lastAppointmentDateString);
      });
  }

  public onScroll() {
    this.listScrolled.emit();
  }

  public scrollToTop() {
    if (this.isBrowser) {
      document.querySelector(".appointment-list").scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth"
      });
    }
  }

  public trackAdverseBuyAppointmentsInfoIcon(hasAppointments: boolean): void {
    if (hasAppointments) {
      this.trackingService.trackEvent({
        event: "ga-event",
        eventCategory: "olb",
        eventAction: "appointment",
        eventLabel: "security-concerns-icon-view"
      });
    }
  }

  public onAppointmentResumeInfoCloseClick(): void {
    this.showAppointmentResumeInfo = false;
  }
}
