<div
  class="flex flex-col gap-6 cgxT:gap-8 cgxD:gap-12"
  [ngClass]="{
    'cgxD:flex-row-reverse': content().pictureAlignment === 'right',
    'cgxD:flex-row': content().pictureAlignment === 'left'
  }"
>
  <div
    cgxResponsiveBg
    class="h-[120px] w-full rounded-lg bg-cover bg-center cgxT:h-60 cgxD:h-auto cgxD:min-h-full cgxD:w-[328px]"
    [src]="{ source: content().picture.source, sizes: content().picture.sizes }"
  ></div>
  <div class="flex flex-1 flex-col gap-2 rounded-lg bg-cg-gray-light-80 p-4 cgxT:gap-4 cgxT:px-6 cgxT:py-8 cgxD:p-6">
    @if (content().headline) {
      <cgx-headline [content]="content().headline"></cgx-headline>
    }

    @if (content().paragraph) {
      <cgx-paragraph [content]="content().paragraph"></cgx-paragraph>
    }

    @if (content().list) {
      <cgx-list [content]="content().list"></cgx-list>
    }

    @if (content().paragraph2) {
      <cgx-paragraph [content]="content().paragraph2"></cgx-paragraph>
    }

    @if (content().cta) {
      <cgx-cta class="mt-2 cgxT:mt-0" [content]="content().cta"></cgx-cta>
    }
  </div>
</div>
