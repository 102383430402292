/* eslint-disable sonarjs/no-duplicate-string */
import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { filter, tap, withLatestFrom } from "rxjs/operators";
import { GdvFacade, ProcessActions } from "@cg/olb/state";
import { TrackingEvent, TrackingGetGdv, TrackingService } from "@cg/analytics";
import { GetGdv, ProcessId } from "@cg/shared";
@Injectable()
export class LicensePlateTrackingEffects {
  private readonly actions$ = inject(Actions);
  private readonly trackingService = inject(TrackingService);
  private readonly gdvFacade = inject(GdvFacade);

  private eventGetGdvMapping = new Map<GetGdv, TrackingGetGdv>([
    [GetGdv.NO_TRANSMISSION, "no"],
    [GetGdv.TRANSMISSION, "yes"],
    [GetGdv.NOT_NECESSARY, "not-necessary"]
  ]);

  public checkGdvTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.goForwardSuccess),
        filter(({ payload }: Action & { payload: { processId: ProcessId; eventAction: string } }) =>
          payload.processId.startsWith("license-plate/")
        ),
        withLatestFrom(this.gdvFacade.fetch$),
        tap(([_, fetchGdv]: [Action & { payload: { processId: ProcessId; eventAction: string } }, GetGdv]) => {
          const eventGetGdv = this.eventGetGdvMapping.get(fetchGdv);

          const trackingPayload = {
            eventAction: "gdv-check",
            eventLabel: eventGetGdv,
            "validation-process": {
              "opt-in": eventGetGdv
            }
          } as Partial<TrackingEvent>;

          this.trackingService.trackEvent(trackingPayload);
        })
      ),
    { dispatch: false }
  );
}
