import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, UrlSegment } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map, withLatestFrom } from "rxjs/operators";
import { GoogleTagManagerService } from "@cg/analytics";
import { WebContentFacade } from "../../+state/web-content.facade";
import { MagnoliaResponse } from "../../interfaces/magnolia-response.interface";
import { CmsRenderContainerComponent } from "../cms-render-container/cms-render-container.component";
import { ErrorComponent } from "../error/error.component";

@Component({
  selector: "cg-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, ErrorComponent, CmsRenderContainerComponent]
})
export class PageComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly googleTagManagerService = inject(GoogleTagManagerService);
  private readonly route = inject(ActivatedRoute);
  private readonly webContentFacade = inject(WebContentFacade);

  public content$: Observable<MagnoliaResponse>;
  public hasError$ = this.webContentFacade.error$;

  public ngOnInit() {
    this.webContentFacade.setCurrentUrl(this.getCurrentUrl());
    this.content$ = this.webContentFacade.currentContent$.pipe(
      withLatestFrom(this.webContentFacade.hasLoaded$),
      filter(([_, hasLoaded]: [MagnoliaResponse, boolean]) => hasLoaded),
      map(([content]: [MagnoliaResponse, boolean]) => content)
    );
    this.content$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((content: MagnoliaResponse) => {
      if (content) {
        this.googleTagManagerService.trackCurrentUrl();
      } else {
        this.googleTagManagerService.trackPageView({
          variant: "404-error",
          type: "error"
        });
      }
    });
  }

  private getCurrentUrl(): string {
    return "/" + this.route.snapshot.url.map((fragment: UrlSegment) => fragment.path).join("/");
  }
}
