<div class="grid justify-between gap-4 py-4 t:flex">
  @for (item of iconsWithParagraph; track item.paragraph; let i = $index) {
    <div
      class="grid grid-cols-[auto_1fr] gap-4 t:grid-rows-[auto_1fr] t:x-[grid-flow-col,text-center] d:max-w-[242px] fhd:max-w-[285px]"
    >
      <div class="relative h-16 w-16 justify-self-center">
        <cg-icon class="absolute right-0 top-0 h-5 w-5" [content]="successIcon"></cg-icon>
        <cg-icon [content]="item.icon"></cg-icon>
      </div>
      <cg-paragraph [content]="item.paragraph"></cg-paragraph>
    </div>
  }
</div>
