import { animate, state, style, transition, trigger } from "@angular/animations";
import { DOCUMENT } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router, RouterLink } from "@angular/router";
import { TranslocoPipe } from "@jsverse/transloco";
import { Icon, Link } from "@cg/content-api/typescript-interfaces";
import { MainNavigation, MainNavigationItem } from "@cg/core/interfaces";
import { IconComponent } from "@cg/core/ui";
import { IS_SERVER_PLATFORM } from "@cg/core/utils";
import { EnvironmentService } from "@cg/environments";
import { BreakpointService, SafeContentPipe } from "@cg/shared";
import { Level, Route } from "../../interfaces/route.interface";
import { LogoutItemComponent } from "../logout-item/logout-item.component";
import { MetaNavigationComponent } from "../meta-navigation/meta-navigation.component";
import { NavigationLinkTextComponent } from "../navigation-link-text/navigation-link-text.component";
import { ToggleButtonComponent } from "../toggle-button/toggle-button.component";

@Component({
  selector: "cg-main-navigation",
  templateUrl: "./main-navigation.component.html",
  styleUrls: ["./main-navigation.component.scss"],
  animations: [
    trigger("flyIn", [
      state("truetrue", style({ opacity: 1, transform: "translateX(0)" })),
      transition("* => truetrue", [
        style({
          opacity: 0,
          transform: "translateX(100%)"
        }),
        animate("300ms ease-in")
      ]),
      state("falsetrue", style({ opacity: 0, transform: "translateX(100%)" })),
      transition("* => falsetrue", [
        style({
          opacity: 1,
          transform: "translateX(0)"
        }),
        animate("300ms ease-in")
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    LogoutItemComponent,
    ToggleButtonComponent,
    RouterLink,
    MetaNavigationComponent,
    NavigationLinkTextComponent,
    IconComponent,
    LogoutItemComponent,
    SafeContentPipe
  ]
})
export class MainNavigationComponent implements OnInit, OnDestroy {
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly breakpointService = inject(BreakpointService);
  private readonly router = inject(Router);
  private readonly document = inject(DOCUMENT);
  private readonly isServer: boolean = inject(IS_SERVER_PLATFORM);
  private readonly environmentService = inject(EnvironmentService);

  public destroyRef = inject(DestroyRef);
  public activeMenuItem: MainNavigationItem;

  @Input() public currentNavigation;
  @Input() public set mainNavigation(mainNavigation: MainNavigation) {
    this._mainNavigation = mainNavigation;
    this.setCurrentMenu(this.mainNavigation.mainNavigationItems);
    this.setParentNavItems();
  }

  public get mainNavigation(): MainNavigation {
    return this._mainNavigation;
  }

  @Input() public metaNavigation: Link[];

  public activeNavItem: MainNavigationItem;
  public activeNavItemParents: MainNavigationItem[];
  public isInside = false;
  public isLgDown: boolean;

  private hoverEventType: string;

  @Input()
  public set route(route: Route) {
    this._route = route;
    this.setParentNavItems();
    this.hideNavIfNoChildren();
    this.isOlbRoute = /\/olb(\?[^\\?]+)?$/.test(this.route.url);
    this.isMyCarglassDetailRoute = this.route.url === "/my-carglass/detail";
  }

  public get route(): Route {
    return this._route;
  }

  public levels: Level[] = [0, -1, -1, -1];
  public visible = false;
  public isMyCarglassDetailRoute = false;
  public isOlbRoute = false;

  public icon: Icon = {
    name: "ic_backarrow.svg",
    src: `${this.environmentService.env.assetPath}/icons/ic_backarrow.svg`,
    ngTemplate: "cgIcon"
  };

  private _route: Route;
  private _mainNavigation: MainNavigation;

  public ngOnInit(): void {
    this.breakpointService.isUntilWidescreen$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLgDown: boolean) => {
        this.isLgDown = isLgDown;
        this.cdr.markForCheck();
      });

    this._registerResizeListener();
  }

  public ngOnDestroy(): void {
    if (!this.isServer) {
      window.removeEventListener("resize", this._handleResize.bind(this));
    }
  }

  public isAppointmentChangeOnOLB(item: MainNavigationItem): boolean {
    return this.route.url === "/olb" && item.title === "<strong>Mein</strong>Carglass<sup>®</sup>";
  }

  public setCurrentMenu(menu: MainNavigationItem[]): void {
    if (this.route) {
      const reg = /^\/(\w*)/;
      let found = this.route.url.toString().match(reg)[0];

      if (found === "/autoglas") {
        found = "/";
      }

      const tempMenu = menu.find((item: MainNavigationItem) => item.href === found);
      if (tempMenu) {
        this.levels[0] = menu.findIndex((item: MainNavigationItem) => item.href === found) as Level;
      }

      if (tempMenu && (!this.activeMenuItem || this.activeMenuItem !== menu)) {
        this.activeMenuItem = tempMenu;
      } else if (!this.activeMenuItem) {
        this.activeMenuItem = menu.find((item: MainNavigationItem) => item.href === "/");
      }
    }
  }

  public onOpen(): void {
    if (this.currentNavigation.currentNavPath.path.length > 1) {
      this.restoreNavState(1, this.mainNavigation.mainNavigationItems[this.levels[0]].items);
    }
    this.visible = true;
    this._preventScrolling();
  }

  public restoreNavState(level: number, currentItem: MainNavigationItem[]): void {
    if (currentItem && this.currentNavigation.currentNavPath.path) {
      const tempItemIndex = currentItem.findIndex(
        (item: MainNavigationItem) => item.href === this.currentNavigation.currentNavPath.path[level].href
      );
      this.levels[level] = tempItemIndex as Level;
      if (level < this.currentNavigation.currentNavPath.path.length - 1) {
        this.restoreNavState(level + 1, currentItem[tempItemIndex].items);
      }
    }
  }

  public onClose(): void {
    this.visible = false;
    this._allowScrolling();
  }

  @HostListener("document:click")
  public clickOut(): void {
    if (!this.isInside) {
      this.onClose();
    }

    this.isInside = false;
  }

  public handleHover(level: number, navItem: number, event: MouseEvent): void {
    if (this.isLgDown || this.hoverEventType === event.type) {
      return;
    }

    this.hoverEventType = event.type;

    this.toggleSubnav(level, navItem);
  }

  public toggleSubnav(level: number, index: number): void {
    try {
      this.levels[level] = this.levels[level] === index && level !== 0 ? -1 : (index as Level);

      if (level === 0) {
        this.levels[1] = -1;
      }
      if (level === 1) {
        this.levels[2] = -1;
      }
      if (level === 2) {
        this.levels[3] = -1;
      }
    } catch (e) {
      return void 0;
    }
  }

  public navigateToNextLevel(url: string, level: number, index: number): void {
    const navigateToItem = this._findNavItemByURL(this.mainNavigation.mainNavigationItems, url);
    if (this.levels[level] === index || navigateToItem?.items?.length === 0 || !navigateToItem?.items) {
      this.router.navigate([url]);
      return;
    }

    this.toggleSubnav(level, index);
  }

  public isActive(level: number, index: number): boolean {
    return this.levels[level] === index;
  }

  public isActiveMenu(item: MainNavigationItem): boolean {
    if (this.currentNavigation?.currentNavPath?.path?.length) {
      return this.currentNavigation.currentNavPath.path[0].href === item.href;
    }

    if (item && item.href === "/") {
      return true;
    }
  }

  public isActiveItem(item: MainNavigationItem): boolean {
    if (!this.route || !this.route.url) {
      return false;
    }

    return (
      this.route.url.includes(item.href) ||
      (item.href.includes("my-carglass") && this.route.url.includes("my-carglass"))
    );
  }

  public findActiveNavItem(items: MainNavigationItem[]): void {
    if (!this.route) {
      return;
    }
    for (const item of items) {
      if (item.href === this.route.url) {
        return;
      }
      if (item.items) {
        this.findActiveNavItem(item.items);
      }
    }
  }

  public setParentNavItems(): void {
    if (!this.mainNavigation) {
      return;
    }

    this.activeNavItem = null;
    this.findActiveNavItem(this.mainNavigation.mainNavigationItems);
    this.activeNavItemParents = [];
  }

  public hideNavIfNoChildren(): void {
    this.visible = false;
  }

  private _allowScrolling(): void {
    this.document.body.style.overflow = "auto";
  }

  private _preventScrolling(): void {
    this.document.body.style.overflow = "hidden";
  }

  private _handleResize(): void {
    if (window.innerWidth > 768) {
      this._allowScrolling();
      return;
    }
    if (this.visible) {
      this._preventScrolling();
    } else {
      this._allowScrolling();
    }
  }

  private _registerResizeListener(): void {
    if (this.isServer) {
      return;
    }

    window.addEventListener("resize", this._handleResize.bind(this));
  }

  private _findNavItemByURL(items: MainNavigationItem[], url: string): MainNavigationItem | null {
    for (const item of items) {
      if (item.href === url) {
        return item;
      }
      if (item.items) {
        const foundItem = this._findNavItemByURL(item.items, url);
        if (foundItem) {
          return foundItem;
        }
      }
    }

    return null;
  }
}
