import { inject, Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { CurrentNavigation } from "../interfaces/current-navigation.interface";
import * as NavigationActions from "./navigation.actions";
import * as fromNavigation from "./navigation.reducer";
import * as NavigationSelectors from "./navigation.selectors";

@Injectable({
  providedIn: "root"
})
export class NavigationFacade {
  private store = inject(Store<fromNavigation.NavigationPartialState>);

  public header$ = this.store.pipe(select(NavigationSelectors.getNavigationHeader));
  public headerError$ = this.store.pipe(select(NavigationSelectors.getNavigationHeaderError));
  public footer$ = this.store.pipe(select(NavigationSelectors.getNavigationFooter));
  public footerError$ = this.store.pipe(select(NavigationSelectors.getNavigationFooterError));

  public getHeaderNavigationStructure() {
    this.store.dispatch(NavigationActions.getHeaderNavigationStructure());
  }

  public getFooterNavigationStructure() {
    this.store.dispatch(NavigationActions.getFooterNavigationStructure());
  }

  // TODO
  public setCurrentNavigation(param: { currentNavPath: CurrentNavigation }) {
    this.store.dispatch(NavigationActions.setCurrentNavigation({ payload: param }));
  }
}
