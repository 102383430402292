/* eslint-disable sonarjs/no-duplicate-string */
import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { filter, map, tap, withLatestFrom } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { CarIdentificationService } from "@cg/olb/services";
import { DamageFacade, InsuranceActions, ProcessActions, ProcessFacade, ProductFacade } from "@cg/olb/state";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { EnvironmentService } from "@cg/environments";
import { InsuranceResponse, isDirectResumeFn } from "@cg/olb/shared";
import { DamageLocation, DamageType, eventRequiredServiceMapping, ProcessId, RequiredService } from "@cg/shared";
import { ApplicationName } from "@cg/core/enums";

@Injectable()
export class InsuranceTrackingEffects {
  private readonly actions$ = inject(Actions);
  private readonly trackingService = inject(TrackingService);
  private readonly damageFacade = inject(DamageFacade);
  private readonly productFacade = inject(ProductFacade);
  private readonly processFacade = inject(ProcessFacade);
  private readonly carIdentificationService = inject(CarIdentificationService);
  private readonly olbConfig: OlbConfiguration = inject(OLB_CONFIG);
  private readonly environmentService = inject(EnvironmentService);

  private brandWithInsuranceSelection: (ApplicationName | string)[] = [
    ApplicationName.CARGLASS,
    ApplicationName.ATU_BROKERPORTAL,
    ApplicationName.ATU_COBRANDED,
    ApplicationName.CCC_BROKERPORTAL,
    ApplicationName.CG4P_BROKERPORTAL
  ];

  public preselectedInsurance$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProcessActions.goForwardSuccess),
        filter(() => this.doesCurrentBrandPreselectInsurance()),
        withLatestFrom(
          this.damageFacade.damageLocation$,
          this.damageFacade.damageType$,
          this.damageFacade.requiredService$,
          this.productFacade.productsCount$,
          this.productFacade.selectedManufacturer$,
          this.productFacade.selectedModel$,
          this.productFacade.selectedBuildDate$
        ),
        filter(
          ([{ payload }, location, type, _requiredService, _productsCount, _brand, _model, _buildDate]: [
            { payload: { processId: ProcessId } },
            DamageLocation,
            DamageType,
            RequiredService,
            number,
            string,
            string,
            string
          ]) =>
            (payload.processId === "damage-type" && type !== DamageType.MINOR) ||
            (payload.processId === "damage-location" && location !== DamageLocation.OTHER) ||
            payload.processId === "damage-size"
        ),
        tap(
          ([{ payload: _payload }, _location, _type, requiredService, _productsCount, _brand, _model, _buildDate]: [
            { payload: { processId: ProcessId } },
            DamageLocation,
            DamageType,
            RequiredService,
            number,
            string,
            string,
            string
          ]) => {
            const jobType = eventRequiredServiceMapping.get(requiredService);
            this.trackingService.trackEvent({
              eventAction: "job-type",
              eventLabel: jobType,
              damage: {
                "job-type": jobType
              }
            } as Partial<TrackingEvent>);
            this.trackingService.trackEvent({
              eventAction: "insurance-type-selection",
              eventLabel: "comprehensive-or-partial-coverage-insurance",
              insurance: {
                type: "comprehensive-or-partial-coverage-insurance"
              }
            } as Partial<TrackingEvent>);

            if (isDirectResumeFn(this.olbConfig.entryChannel)) {
              this.trackingService.trackEvent({
                eventAction: "gdv-check",
                eventLabel: "not-necessary",
                "validation-process": {
                  "opt-in": "not-necessary"
                }
              } as Partial<TrackingEvent>);

              this.trackingService.trackEvent({
                eventAction: "coverage-validation",
                eventLabel: "covered",
                insurance: {
                  coverage: "covered"
                }
              } as Partial<TrackingEvent>);
            }
          }
        )
      ),
    { dispatch: false }
  );

  public getInsuranceSuccessOpportunityFunnel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InsuranceActions.getInsuranceSuccess),
        map(({ payload }: { payload: InsuranceResponse }) => payload),
        withLatestFrom(this.processFacade.currentProcessId$),
        tap(([payload, processId]: [InsuranceResponse, ProcessId]) => {
          if (processId !== "opportunity-funnel-appointment-request") {
            return;
          }

          const confirmedLabel = payload.coverNote ? "coverage-confirmed" : "coverage-unconfirmed";
          const carIdentified = this.carIdentificationService.getCarIdentifiedProcessId(payload);
          const carIdentifiedLabel = payload.vin || payload.model ? carIdentified : "car-unidentified";
          const eventLabel = `${confirmedLabel}/${carIdentifiedLabel}`;

          this.trackingService.trackEvent({
            eventAction: "opportunity-insurance-result",
            eventLabel
          } as Partial<TrackingEvent>);

          const coverage = payload.coverNote ? "covered" : "uncovered";
          this.trackingService.trackEvent({
            eventAction: "opportunity-coverage-validation",
            eventLabel: coverage,
            opportunity: {
              coverage
            }
          } as Partial<TrackingEvent>);
        })
      ),
    { dispatch: false }
  );

  private doesCurrentBrandPreselectInsurance(): boolean {
    return this.brandWithInsuranceSelection.indexOf(this.environmentService.env.brand) === -1;
  }
}
