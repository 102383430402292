import { ChangeDetectionStrategy, Component, HostListener, inject, OnDestroy, OnInit } from "@angular/core";
import { ProcessFacade } from "@cg/olb/state";
import { IS_BROWSER_PLATFORM } from "@cg/core/utils";
import { MetaDataService } from "@cg/html-header";
import { InitService, ScrollService } from "@cg/olb/shared";

@Component({
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class OlbBaseComponent implements OnInit, OnDestroy {
  protected readonly process = inject(ProcessFacade);
  protected readonly scrollService = inject(ScrollService);
  protected readonly metaDataService = inject(MetaDataService);
  protected readonly initService = inject(InitService);
  protected readonly isBrowser: boolean = inject(IS_BROWSER_PLATFORM);

  @HostListener("window:resize")
  private onResize(): void {
    this.setVh();
  }

  public ngOnInit() {
    this.setVh();
    this.initService.init();
    this.setTitleAndMetaData();

    if (this.isBrowser) {
      this.scrollService.registerAppScrollContainer(window);
    }
    this.process.enterOlb();
  }

  public ngOnDestroy() {
    this.process.exitOlbDone();
  }

  private setTitleAndMetaData() {
    this.metaDataService.setDocumentTitle("Terminbuchung | Carglass®");
    this.metaDataService.addOrUpdateTag(
      "description",
      "Buchen Sie jetzt online Ihren Termin bei der Nummer 1 für Autoglas. Carglass® repariert und tauscht aus."
    );
  }

  // We need this function to set the viewport height to be used in max-h-fill-available class
  private setVh(): void {
    if (!this.isBrowser) {
      return;
    }
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }
}
