<cg-round-corner-tile>
  <div class="relative flex gap-14 px-6 py-4 d:px-8 d:py-6">
    <div class="flex flex-[1_1_0] flex-col">
      @if ({ hasDateChanged: hasDateChanged$ | async, hasLocationChanged: hasLocationChanged$ | async }; as data) {
        @if (data.hasDateChanged || data.hasLocationChanged) {
          <div class="absolute -left-2 top-0 -translate-y-1/2">
            <cg-my-carglass-badge [text]="'myCarglass.badge.appointment' | transloco"></cg-my-carglass-badge>
          </div>
        }
        @for (info of contentCards; track info.id; let i = $index) {
          <cg-content-card
            class="[&>div>div>div>div:last-child]:col-start-2 [&>div>div>div>div:last-child]:last:col-end-[-1] [&>div>div>div>div>cg-richtext>div]:text-[0.8125rem] [&>div>div>div>div>cg-richtext>div]:leading-[1.25rem] [&>div>div>div>div>cg-richtext>div]:text-cg-anthracite-light-30 [&>div>div]:first:pt-0 [&>div>div]:last:pb-0"
            [ngClass]="{
              '[&>div>div]:pb-0': i === 1,
              '[&>#content-card-date>div>div>div>cg-paragraph>p]:text-cg-green': data.hasDateChanged,
              '[&>#content-card-location>div>div>div>cg-paragraph>p]:text-cg-green': data.hasLocationChanged
            }"
            data-cs-mask
            [content]="info"
            [contentUnderSublineTemplateRef]="getContentTemplate(info)"
          >
          </cg-content-card>
        }
      }
    </div>

    @if ((isMobile$ | async) === false && serviceCenterLocation$ | async; as serviceCenterLocation) {
      <div class="w-0 flex-[1_1_0]">
        <cg-google-maps
          data-cs-mask
          class="h-full [&>div>google-map>.map-container]:!h-full [&>div]:h-full"
          [mapHeight]="'100%'"
          [useOwnStyle]="false"
          [zoom]="17"
          [showMultipleServiceCenters]="false"
          [infoWindowTemplateRef]="infoWindow"
          [serviceCenterLocations]="[serviceCenterLocation]"
          data-cs-mask
        ></cg-google-maps>
      </div>
    }
  </div>
</cg-round-corner-tile>

<ng-template #locationRoutePlanner>
  <a
    class="text-cg-anthracite underline"
    (click)="trackRoutePlaningEvent('text-link')"
    href="{{ googleMapsRouteHref }}"
    target="_blank"
    data-cy="my-carglass-booked-appointment-route-href"
    >{{ "myCarglass.detail.calculateRoute" | transloco }}</a
  >
</ng-template>

<ng-template #addToCalendar>
  <a
    class="text-cg-anthracite underline"
    (click)="trackCalendarEvent('my-carglass/detail')"
    href="{{ calendarHref }}"
    target="_blank"
    >{{ "appointmentConfirmation.ctas.calendar" | transloco }}</a
  >
</ng-template>

<ng-template #infoWindow>
  <strong>Carglass<sup>®</sup> {{ customerCase.appointment.city }}</strong>
  <br />
  {{ customerCase.appointment.street }}<br />
  {{ customerCase.appointment.postalCode }} {{ customerCase.appointment.city }}<br /><br />
  <a
    class="text-cg-anthracite underline"
    (click)="trackRoutePlaningEvent('maps-link')"
    href="{{ googleMapsRouteHref }}"
    target="_blank"
    >{{ "myCarglass.detail.calculateRoute" | transloco }}</a
  >
</ng-template>

<ng-template #openingHours>
  <cg-my-carglass-service-center-openings-hours></cg-my-carglass-service-center-openings-hours>
</ng-template>
