import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { GdvActions, ProductActions } from "@cg/olb/state";

@Injectable()
export class GdvEffects {
  private readonly actions$ = inject(Actions);

  public resetProductsAfterFetchChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GdvActions.setFetch),
      mergeMap(() => of(ProductActions.resetAllProducts()))
    )
  );
}
