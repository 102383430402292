import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { exitOverlayActions } from "./exit-overlay.actions";

@Injectable({
  providedIn: "root"
})
export class ExitOverlayFacade {
  private readonly store = inject(Store);

  public confirm(): void {
    this.store.dispatch(exitOverlayActions.confirm());
  }

  public close(): void {
    this.store.dispatch(exitOverlayActions.closeExitOverlay());
  }
}
