<div class="rounded-lg bg-white shadow-md">
  <cg-expansion-panel
    [maxHeight]="maxHeight"
    [contentTemplate]="selectionCard"
    [expanded]="expanded"
    [titleTemplate]="cardHeader"
    (accordionToggled)="emitAccordionToggle()"
  >
  </cg-expansion-panel>
</div>

<ng-template #cardHeader>
  <div class="flex w-full cursor-pointer flex-row items-center gap-3 px-3 py-2 t:x-[gap-6,px-6,py-4]">
    <cg-new-appointment-circle>
      <cg-new-appointment-distance-label [distance]="serviceCenter.distance"></cg-new-appointment-distance-label>
    </cg-new-appointment-circle>
    <div class="min-w-0">
      <div
        class="font-roboto-condensed text-cg-anthracite t:text-2xl"
        [ngClass]="{ 'overflow-hidden text-ellipsis whitespace-nowrap': !expanded }"
        [title]="serviceCenter.city + ' - ' + serviceCenter.sublocality"
      >
        <span class="font-bold">
          {{ serviceCenter.city }}
          @if (serviceCenter.sublocality) {
            -
          }
        </span>
        {{ serviceCenter.sublocality }}
      </div>
      @if (expanded) {
        <div class="cursor-pointer text-[13px] text-cg-anthracite-light-30 t:text-base">
          {{ serviceCenter.postalCode }} • {{ serviceCenter.street }}
        </div>
      } @else {
        <div class="flex flex-row font-roboto-condensed text-[13px] t:text-base">
          @if (firstAppointment) {
            <div
              class="mr-1 flex items-center gap-x-0.5 rounded bg-cg-anthracite-light-90 px-1 py-0.5 text-cg-anthracite t:gap-0"
            >
              <span
                [innerHTML]="
                  'newAppointment.selectCard.startingFrom'
                    | transloco
                      : {
                          day: firstAppointment.day | date: 'EE' | slice: 0 : 2,
                          date: firstAppointment.day | date: 'dd.MM'
                        }
                "
              ></span>
            </div>
          }
          @if (!firstAppointment) {
            <div
              class="rounded bg-cg-anthracite-light-90 px-1 py-0.5 text-cg-anthracite"
              [innerHTML]="'newAppointment.selectCard.overbooked' | transloco: { date: limitDate | date: 'dd.MM' }"
            ></div>
          }
        </div>
      }
    </div>
    <cg-icon
      class="ml-auto h-6 w-6 transition-all"
      [content]="accordionIconUp"
      [ngClass]="{ 'rotate-180': !expanded, 'rotate-0': expanded }"
    ></cg-icon>
  </div>
</ng-template>

<ng-template #selectionCard>
  @if (tabs.length) {
    @if (!hasOneAppointment) {
      <cg-tabs
        class="scroll block w-full part-[tab]:font-roboto part-[tab|.inactive]:text-[13px] part-[tab|.inactive]:font-light part-[tab|.inactive]:text-cg-anthracite-light-30 part-[tab|.inactive]:hover:text-cg-anthracite t:ml-auto d:part-[tabs]:ml-auto d:part-[tabs]:min-w-[482px] d:part-[tabs]:max-w-[482px] w:part-[tabs]:min-w-[512px]"
        [showSingleTabAsFull]="true"
        [tabs]="tabs"
        [translateTitle]="false"
        [scrollable]="true"
        [scrollItemCount]="1"
        (tabChanged)="deselectAppointment()"
      ></cg-tabs>
    } @else {
      <div class="flex w-full bg-cg-gray-light-80 t:min-h-44">
        @defer (on viewport) {
          <cg-static-map
            class="hidden size-full h-auto t:block"
            [latitude]="serviceCenter.coordinates.latitude"
            [longitude]="serviceCenter.coordinates.longitude"
          ></cg-static-map>
        } @placeholder {
          <div class="hidden h-auto w-full t:block"></div>
        }
        <cg-new-appointment-one-appointment-view
          class="w-full t:my-auto d:min-w-[482px] w:min-w-[512px]"
          [appointment]="oneAppointment"
          (appointmentSelected)="selectedAppointmentIdChanged($event)"
        ></cg-new-appointment-one-appointment-view>
      </div>

      @if (selectedAppointment && openingHour) {
        <cg-new-appointment-desktop-detail
          class="animate-cg-fade-in"
          [appointment]="selectedAppointment"
          [openHour]="openingHour"
        ></cg-new-appointment-desktop-detail>
      }

      <div
        class="relative grid w-full grid-cols-3 items-center justify-items-center gap-2 p-3 pt-2 t:x-[flex,flex-row-reverse,pt-3,mt-2,mb-3,min-h-12,px-6]"
      ></div>
    }
  } @else {
    <div class="flex w-full bg-cg-gray-light-80 t:min-h-44">
      @defer (on viewport) {
        <cg-static-map
          class="hidden size-full h-auto t:block"
          [latitude]="serviceCenter.coordinates.latitude"
          [longitude]="serviceCenter.coordinates.longitude"
        ></cg-static-map>
      } @placeholder {
        <div class="hidden h-auto w-full t:block"></div>
      }
      <div
        class="font-roboto-condense flex w-full flex-col items-center rounded-b-lg bg-cg-gray-light-80 p-4 t:my-auto d:min-w-[482px] w:min-w-[512px]"
      >
        <cg-icon class="mb-2 h-12 w-12" [content]="busyScIcon"></cg-icon>
        <div>
          <cg-paragraph
            class="font-bold [&>p]:text-center [&>p]:font-roboto-condensed [&>p]:text-[13px] [&>p]:text-cg-anthracite"
            >{{
              "newAppointment.appointmentSelection.noAppointments.title"
                | transloco: { date: limitDate | date: "dd.MM" }
            }}</cg-paragraph
          >
          <cg-paragraph
            class="[&>p]:text-center [&>p]:font-roboto-condensed [&>p]:text-[13px] [&>p]:text-cg-anthracite-light-30"
          >
            {{ "newAppointment.appointmentSelection.noAppointments.text" | transloco }}
          </cg-paragraph>
        </div>
      </div>
    </div>
    <div
      class="relative grid w-full grid-cols-3 items-center justify-items-center gap-2 p-3 pt-2 t:x-[flex,flex-row-reverse,pt-3,mt-2,mb-3,min-h-12,px-6]"
    ></div>
  }
  <ng-template #noAppointments>
    <div class="font-roboto-condense flex flex-col items-center rounded-b-lg bg-cg-gray-light-80 p-4">
      <cg-icon class="mb-2 h-12 w-12" [content]="busyScIcon"></cg-icon>
      <div>
        <cg-paragraph
          class="font-bold [&>p]:text-center [&>p]:font-roboto-condensed [&>p]:text-[13px] [&>p]:text-cg-anthracite"
          >{{
            "newAppointment.appointmentSelection.noAppointments.title" | transloco: { date: limitDate | date: "dd.MM" }
          }}</cg-paragraph
        >
        <cg-paragraph
          class="[&>p]:text-center [&>p]:font-roboto-condensed [&>p]:text-[13px] [&>p]:text-cg-anthracite-light-30"
        >
          {{ "newAppointment.appointmentSelection.noAppointments.text" | transloco }}
        </cg-paragraph>
      </div>
    </div>
  </ng-template>
</ng-template>

@for (weekAppointments of appointmentsPerWeek; track $index) {
  <ng-template #weekTab>
    <ng-container *ngTemplateOutlet="appointmentCard; context: { appointmentsPerDay: weekAppointments }"></ng-container>
  </ng-template>
}

<ng-template #appointmentCard let-appointmentsPerDay="appointmentsPerDay">
  <div class="flex w-full">
    @defer (on viewport) {
      <cg-static-map
        class="hidden h-auto w-full t:block"
        [latitude]="serviceCenter.coordinates.latitude"
        [longitude]="serviceCenter.coordinates.longitude"
      ></cg-static-map>
    } @placeholder {
      <div class="hidden h-auto w-full t:block"></div>
    }
    <cg-new-appointment-selection-grid
      class="block bg-cg-gray-light-80 px-3 py-4 t:p-6 d:min-w-[482px] w:min-w-[512px]"
      [appointments]="appointmentsPerDay"
      [selectedAppointmentId]="selectedAppointmentId"
      (selectedAppointmentIdChanged)="selectedAppointmentIdChanged($event)"
    ></cg-new-appointment-selection-grid>
  </div>

  @if (selectedAppointment && openingHour) {
    <cg-new-appointment-desktop-detail
      class="animate-cg-fade-in"
      [appointment]="selectedAppointment"
      [openHour]="openingHour"
    ></cg-new-appointment-desktop-detail>
  }

  <div
    class="relative grid w-full cursor-pointer grid-cols-3 items-center justify-items-center gap-2 p-3 pt-2 md:cursor-default"
    (click)="openAllAppointments(serviceCenter)"
  >
    @if (isMobile && appointmentsPerWeek.length > 1) {
      <cg-icon
        class="col-start-2 size-5 rotate-180 transition-transform duration-300 ease-in-out t:size-6"
        [content]="accordionIconUp"
      ></cg-icon>
      <span class="justify-self-end font-roboto-condensed text-sm font-medium underline t:font-bold">{{
        "newAppointment.appointmentSelection.allAppointments" | transloco
      }}</span>
    }
  </div>
</ng-template>
