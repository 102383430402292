/* eslint-disable max-params */
import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { combineLatest, Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";
import { ContactDataFacade, CustomerCaseFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { OptimizelyService, TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { CustomerDataEmailExitIds, OlbFooterComponent, OlbHeadlineComponent, ScrollService } from "@cg/olb/shared";
import {
  ComponentOverarchingChangeDetectionService,
  CtalinkComponent,
  CustomerContact,
  CustomerContactFormEarlyDataTestComponent,
  SplitViewComponent
} from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { additionalOptions } from "../customer-contact/models/additional-options.model";

// AB-Test: OLB_EARLY_CONTACT_DATA

@Component({
  selector: "cg-customer-contact-early-data-test",
  templateUrl: "./customer-contact-early-data-test.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    OlbHeadlineComponent,
    CustomerContactFormEarlyDataTestComponent,
    CtalinkComponent,
    SplitViewComponent,
    OlbFooterComponent,
    TranslocoPipe,
    AsyncPipe
  ],
  standalone: true
})
export class CustomerContactEarlyDataTestComponent
  extends BaseDirective<AddFormControls<CustomerContact>>
  implements OnInit
{
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly scrollService = inject(ScrollService);
  protected readonly trackingService = inject(TrackingService);
  private readonly optimizelyService = inject(OptimizelyService);
  private readonly contactDataFacade = inject(ContactDataFacade);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);
  private readonly fb = inject(FormBuilder);
  private readonly cdrService = inject(ComponentOverarchingChangeDetectionService);

  public privacyStatementCta = { ...additionalOptions.firstCtaLink };
  public customerCaseId$: Observable<string> = this.customerCaseFacade.customerCaseId$;
  public onlyMailVariantActive$: Observable<boolean>;
  public subHeadlineKey$: Observable<string>;
  public agreementKey$: Observable<string>;

  public declare form: FormGroup;

  public readonly isEmailOptional = false;

  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    this.onlyMailVariantActive$ = this.optimizelyService.isVariationOfExperimentActive(
      OptimizelyExperiment.OLB_EARLY_CONTACT_DATA_ONLY_MAIL
    );
    this.subHeadlineKey$ = this.onlyMailVariantActive$.pipe(
      map((onlyMailVariantActive: boolean) =>
        onlyMailVariantActive
          ? "customerContact.subHeadlineEarlyCustomerDataTestMailOnly"
          : "customerContact.subHeadlineEarlyCustomerDataTestAllData"
      )
    );
    this.agreementKey$ = this.onlyMailVariantActive$.pipe(
      map((onlyMailVariantActive: boolean) =>
        onlyMailVariantActive
          ? "customerContact.agreementEarlyCustomerDataTestMailOnly"
          : "customerContact.agreementEarlyCustomerDataTestAllData"
      )
    );
  }

  public initFormGroup(): void {
    this.form = this.fb.group({
      contact: this.fb.group({})
    });
  }

  public saveForm(): void {
    const { email, mobile } = this.form.controls.contact.value;

    if (mobile) this.contactDataFacade.setMobile(mobile);

    this.contactDataFacade.setEmail(email);
  }

  public setFormValues(): void {
    combineLatest([this.contactDataFacade.email$, this.contactDataFacade.mobile$])
      .pipe(takeUntilDestroyed(this.destroyRef), take(1))
      .subscribe(([email, mobile]: [string, string]) => {
        if (email) {
          this.form.get("contact.email").setValue(email);
        }
        if (mobile) {
          this.form.get("contact.mobile")?.setValue(mobile);
        }
      });
  }

  public getExitIdForSavedForm(): Observable<CustomerDataEmailExitIds> {
    return of("newCustomer" as CustomerDataEmailExitIds);
  }

  public onTrack(eventAction: string) {
    this.trackingService.trackEvent({
      eventAction: eventAction,
      eventLabel: "customer-contact-early-data-test"
    } as Partial<TrackingEvent>);
  }

  public goForwardFailure(): void {
    super.goForwardFailure();
    this.cdrService.changeDetectionRequest$.next();
  }
}
