import { effect, ErrorHandler, Inject, Injectable, isDevMode } from "@angular/core";
import { captureException, init } from "@sentry/angular-ivy";
import { ExtendedError } from "@sentry/types";
import { EnvironmentService } from "@cg/environments";
import { SentryConfiguration } from "../interfaces/sentry-configutation.interface";
import { SENTRY_CONFIGURATION } from "../token/sentry-configuration.token";

@Injectable({ providedIn: "root" })
export class SentryErrorHandlerService implements ErrorHandler {
  public constructor(
    @Inject(SENTRY_CONFIGURATION) private readonly configuration: SentryConfiguration,
    private readonly environmentService: EnvironmentService
  ) {
    effect(() => {
      if (this.environmentService.hasLoaded()) {
        this._initSentry();
      }
    });
  }

  public get devMode(): boolean {
    return isDevMode();
  }

  public handleError(error: ExtendedError): void {
    if (this.devMode) {
      console.error(error.originalError ?? error);
      return;
    }
    captureException(error.originalError ?? error);
  }

  private _initSentry(): void {
    let sentryRelease;

    try {
      // SENTRY_APP_RELEASE gets replace on compile time by vite (see carglass-sentry-vite-plugin)
      // gets only rewritten in compiled source and not in source map
      sentryRelease = SENTRY_APP_RELEASE;
    } catch {
      // used in serve mode
      sentryRelease = "dev";
    }

    init({
      dsn: this.configuration.dsn,
      debug: !this.environmentService.env.production,
      environment: `${this.environmentService.env.brand} @ ${this.environmentService.env.envName}`,
      release: sentryRelease,
      enabled: this.configuration.enabled ?? true,
      autoSessionTracking: false,
      ignoreErrors: [
        "Non-Error exception captured with keys: qa",
        "Non-Error exception captured with keys: message",
        "Non-Error exception captured with keys: error, headers, message, name, ok"
      ]
    });
  }
}
