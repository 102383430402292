import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { tap, withLatestFrom } from "rxjs/operators";
import { OptimizelyService } from "@cg/analytics";
import { OverlayService } from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ExitOverlayVariantComponent } from "../component/exit-overlay-exit intent-variant/exit-overlay-variant.component";
import { ExitOverlayComponent } from "../component/exit-overlay-original/exit-overlay.component";
import * as ExitOverlayActions from "./exit-overlay.actions";

@Injectable()
export class ExitOverlayEffects {
  private readonly actions$ = inject(Actions);
  private readonly overlayService = inject(OverlayService);
  private readonly optimizelyService = inject(OptimizelyService);

  public openExitOverlay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExitOverlayActions.openExitOverlay),
        withLatestFrom(
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.OLB_EXIT_INTENT_SURVEY)
        ),
        tap(([_, isActive]: [Action<"[Exit Overlay] Open">, boolean]) => {
          if (isActive) {
            this.overlayService.open(ExitOverlayVariantComponent);
          } else {
            this.overlayService.open(ExitOverlayComponent);
          }
        })
      ),
    {
      dispatch: false
    }
  );

  public closeExitOverlay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ExitOverlayActions.closeExitOverlay),
        tap(() => {
          this.overlayService.close();
        })
      ),
    {
      dispatch: false
    }
  );
}
