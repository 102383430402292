import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostBinding,
  inject,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { filter } from "rxjs/operators";
import { ChannelSwitchFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { OlbHeadlineComponent, ScrollService } from "@cg/olb/shared";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { channelSwitchAnimation } from "../../animations/channel-switch.animation";
import { CallbackTimePipe } from "../../pipes/callback-time.pipe";
import { ChannelSwitchBaseComponent } from "../channel-switch-base/channel-switch-base.component";

@Component({
  selector: "cg-channel-switch-countdown",
  templateUrl: "./channel-switch-countdown.component.html",
  styleUrls: ["./channel-switch-countdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [channelSwitchAnimation],
  standalone: true,
  imports: [TranslocoPipe, CallbackTimePipe, OlbHeadlineComponent]
})
export class ChannelSwitchCountdownComponent extends ChannelSwitchBaseComponent implements OnInit {
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly trackingService = inject(TrackingService);
  protected readonly scrollService = inject(ScrollService);
  private readonly channelSwitchFacade = inject(ChannelSwitchFacade);
  public readonly destroyRef = inject(DestroyRef);

  public countDown: number;
  @HostBinding("@fadeIn") protected fadeIn = true;

  public async ngOnInit() {
    await super.ngOnInit();
    this.handleCountdownUI();
  }

  private handleCountdownUI() {
    this.channelSwitchFacade.countDown$
      .pipe(takeUntilDestroyed(this.destroyRef), filter(Boolean))
      .subscribe((countDown: number) => {
        this.countDown = countDown;
        this.cdr.markForCheck();
      });
  }
}
