import { NgClass, NgForOf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { combineLatest, Observable, of } from "rxjs";
import { filter, take } from "rxjs/operators";
import { OLB_CONFIG } from "@cg/olb/configuration";
import {
  AppointmentFacade,
  ContactDataFacade,
  CustomerCaseFacade,
  DamageFacade,
  InsuranceFacade,
  OlbFacade,
  ProcessFacade
} from "@cg/olb/state";
import { ToastMessage, ToastMessageComponent } from "@cg/toast";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { AppointmentDateInfoComponent, CarBringingScheduleTextTextComponent } from "@cg/appointment-ui";
import { ConfigFacade } from "@cg/config";
import { ProtectConfig, ServiceCenterLocation } from "@cg/core/interfaces";
import { ParagraphComponent, RichtextComponent } from "@cg/core/ui";
import { isOpportunityFunnel } from "@cg/core/utils";
import { EnvironmentService } from "@cg/environments";
import { infoIcon } from "@cg/icon";
import { GoogleMapsComponent, LocationsFacade } from "@cg/locations";
import {
  DamagedRearWindowCount,
  DamagedSideWindowCount,
  Driver,
  Insurance,
  InsuranceHolder,
  isDirectResumeFn,
  OlbForwardComponent,
  OlbHeadlineComponent,
  OpportunityFunnelSummaryExitIds,
  ScrollService
} from "@cg/olb/shared";
import {
  Appointment,
  BreakpointService,
  Button,
  ContentCard,
  ContentCardComponent,
  CustomerCase,
  CustomerContactForm,
  DamageWindow,
  EditCustomerContactComponent,
  OverlayService,
  ProcessId,
  ProcessMetadata,
  RequiredService,
  SplitViewComponent
} from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { OpportunityFunnelSummaryService } from "./services/opportunity-funnel-summary.service";

@Component({
  selector: "cg-summary",
  templateUrl: "./summary.component.html",
  styleUrl: "./summary.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgForOf,
    TranslocoPipe,
    OlbHeadlineComponent,
    ParagraphComponent,
    ContentCardComponent,
    OlbForwardComponent,
    SplitViewComponent,
    GoogleMapsComponent,
    CarBringingScheduleTextTextComponent,
    AppointmentDateInfoComponent,
    RichtextComponent,
    ToastMessageComponent,
    NgClass
  ]
})
export class SummaryComponent extends BaseDirective<null> implements OnInit {
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly trackingService = inject(TrackingService);
  protected readonly scrollService = inject(ScrollService);
  protected readonly overlayService = inject(OverlayService);
  protected readonly olbFacade = inject(OlbFacade);
  private readonly translocoService = inject(TranslocoService);
  private readonly damageFacade = inject(DamageFacade);
  private readonly appointmentFacade = inject(AppointmentFacade);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);
  private readonly contactDataFacade = inject(ContactDataFacade);
  private readonly insuranceFacade = inject(InsuranceFacade);
  private readonly configFacade = inject(ConfigFacade);
  private readonly breakpointService = inject(BreakpointService);
  private readonly olbConfig = inject(OLB_CONFIG);
  private readonly opportunityFunnelService = inject(OpportunityFunnelSummaryService);
  private readonly locationsFacade = inject(LocationsFacade);
  private readonly environmentService = inject(EnvironmentService);

  public consentText: string;
  public cards: ContentCard[] = [];
  public appointmentCards: ContentCard[] = [];
  public requiredService: RequiredService;
  public serviceCenterText: string;
  public isCalibration: boolean;

  public forwardBtnContent: Button = {
    id: "forward-btn",
    text: ""
  };

  public contentCardsCreated = false;
  public isOpportunityFunnel = false;
  public readonly toastMessage: ToastMessage = {
    message: this.translocoService.translate("summary.opportunityFunnel.toastMessage"),
    color: "#FFDC00",
    icon: infoIcon,
    roundedCorners: true
  };

  private isDirectResume = false;
  private summaryFeatureConfiguration = this.environmentService.env.features.summary;
  private summaryDirectResumeFeatureConfiguration = this.environmentService.env.features.summaryDirectResume;

  @HostListener("document:click", ["$event"])
  public onPrivacyPolicyClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.tagName.toLowerCase() === "a" && target.id === "privacy-policy-link") {
      const trackingEvent: Partial<TrackingEvent> = {
        eventAction: "privacy-statement",
        eventLabel: "summary"
      };
      this.trackingService.trackEvent(trackingEvent);
    }
  }

  public async ngOnInit() {
    this.isDirectResume = isDirectResumeFn(this.olbConfig.entryChannel);

    this.processFacade.processMetaData$.pipe(take(1)).subscribe((processMetaData: ProcessMetadata[]) => {
      this.isOpportunityFunnel = isOpportunityFunnel(processMetaData);

      if (this.isOpportunityFunnel) {
        this.forwardBtnContent.text = "summary.confirmation.buttonTextOpportunityFunnel";
      } else {
        this.forwardBtnContent.text = "summary.confirmation.buttonTextDefault";
      }

      this.createContentCards();
      this.cdr.markForCheck();
    });

    this.consentText = this.translocoService.translate("summary.confirmation.consent", {
      buttonText: this.translocoService.translate(this.forwardBtnContent.text),
      privacyUrl: this.getCorrectDomain() + "/datenschutz"
    });
  }

  public initFormGroup(): void {}
  public setFormValues(): void {}
  public saveForm(): void {}

  public getExitIdForSavedForm(): Observable<OpportunityFunnelSummaryExitIds | "appointmentSuccess"> {
    if (this.isOpportunityFunnel) {
      return this.opportunityFunnelService.getExitId$();
    }

    return of("appointmentSuccess");
  }

  public goForward(): void {
    if (this.isOpportunityFunnel) {
      this.opportunityFunnelService.goForwardOfOpportunityFunnel();
    }

    super.goForward();
  }

  public getCorrectDomain(): string {
    const currentDomain = window.location.origin;

    if (currentDomain.includes("local")) {
      return "https://www.dev.services.carglass.de";
    }

    if (currentDomain.includes(".dev.") || currentDomain.includes(".uat.")) {
      const env = currentDomain.split(".")[1];
      return `https://www.${env}.services.carglass.de`;
    }

    return "https://www.carglass.de";
  }

  private createContentCards(): void {
    this.addDamageDescriptionCard();
    this.addProductCardIfNeccessary();
    this.addInsuranceCardIfNeccessary();
    this.addCarCardIfNeccessary();
    this.addContactCardIfNeccessary();
    this.addAddressCardIfNeccessary();
    this.addCallTimeCardIfNecessary();
    this.addAppointmentCards();

    this.contentCardsCreated = true;
  }

  private addDamageDescriptionCard(): void {
    combineLatest([
      this.damageFacade.selectedDamage$,
      this.damageFacade.requiredService$,
      this.damageFacade.damagedRearWindowCount$,
      this.damageFacade.damagedSideWindowCount$
    ])
      .pipe(take(1))
      .subscribe(
        ([damageWindow, requiredService, damagedRearWindowCount, damagedSideWindowCount]: [
          DamageWindow,
          RequiredService,
          DamagedRearWindowCount,
          DamagedSideWindowCount
        ]) => {
          this.requiredService = requiredService;
          let damageWindowText: string;

          if (this.isOpportunityFunnel) {
            damageWindowText = this.translocoService.translate(
              this.opportunityFunnelService.getDamageDescTransKey(
                damageWindow,
                damagedSideWindowCount,
                damagedRearWindowCount
              )
            );
          } else {
            damageWindowText = this.translocoService.translate(
              "summary.damageDescription." + damageWindow.toLowerCase()
            );
          }

          const damageService = this.translocoService.translate(
            "summary.damageDescription." + this.requiredService.toLowerCase()
          );

          this.cards.push(
            this.createContentCard(
              this.translocoService.translate("summary.damageDescription.title"),
              `${damageWindowText} - <strong>${damageService}</strong>`,
              "damageCard",
              () => this.rewindToExistingProcessId("damage-window")
            )
          );
        }
      );
  }

  private addProductCardIfNeccessary(): void {
    if (
      this.isOpportunityFunnel ||
      !this.summaryFeatureConfiguration.visible.additionalProduct ||
      (this.isDirectResume && !this.summaryDirectResumeFeatureConfiguration.visible.additionalProduct)
    ) {
      return;
    }

    combineLatest([this.customerCaseFacade.protectProduct$, this.configFacade.protectConfig$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([hasProtect, protectConfig]: [boolean, ProtectConfig]) => {
        if (!hasProtect) {
          this.cards = this.cards.filter((card: ContentCard) => card.id !== "additionalProductCard");
        } else {
          const product = "Protect";
          const price = protectConfig.price;
          const card = this.createContentCard(
            this.translocoService.translate("summary.additionalProduct.title"),
            this.translocoService.translate("summary.additionalProduct.description", { product, price }),
            "additionalProductCard",
            () => this.rewindToExistingProcessId("vaps-offer")
          );

          const indexToInsert = 1;
          this.cards.splice(indexToInsert, 0, card);
        }
      });
  }

  private addInsuranceCardIfNeccessary(): void {
    if (
      this.isOpportunityFunnel ||
      !this.summaryFeatureConfiguration.visible.insurance ||
      (this.isDirectResume && !this.summaryDirectResumeFeatureConfiguration.visible.insurance)
    ) {
      return;
    }

    const isEditable = this.isDirectResume
      ? this.summaryDirectResumeFeatureConfiguration.editable.insurance
      : this.summaryFeatureConfiguration.editable.insurance;

    this.insuranceFacade.selectedInsurance$.pipe(take(1)).subscribe((insurance: Insurance) => {
      const insuranceText =
        insurance.number === "-1"
          ? this.translocoService.translate("summary.insurance.insuranceType.unknown")
          : this.translocoService.translate("summary.insurance.insuranceType.full", {
              insuranceName: insurance.name
            });

      this.cards.push(
        this.createContentCard(
          this.translocoService.translate("summary.insurance.title"),
          `${insuranceText}`,
          "insuranceCard",
          isEditable ? () => this.rewindToExistingProcessId("insurance-type") : null
        )
      );
    });
  }

  private addCarCardIfNeccessary(): void {
    if (
      !this.summaryFeatureConfiguration.visible.car ||
      (this.isDirectResume && !this.summaryDirectResumeFeatureConfiguration.visible.car)
    ) {
      return;
    }

    const isEditable = this.isDirectResume
      ? this.summaryDirectResumeFeatureConfiguration.editable.car
      : this.summaryFeatureConfiguration.editable.car;

    combineLatest([this.damageFacade.rawLpn$, this.processFacade.processMetaData$])
      .pipe(take(1))
      .subscribe(([rawLpn, processMetaData]: [string, ProcessMetadata[]]) => {
        const licensePlateMetaData = processMetaData.find((process: ProcessMetadata) =>
          process.id.includes("license-plate")
        );

        this.cards.push(
          this.createContentCard(
            this.translocoService.translate("summary.car"),
            `${rawLpn.toUpperCase()}`,
            "carCard",
            isEditable ? () => this.rewindToExistingProcessId(licensePlateMetaData.id) : null
          )
        );
      });
  }

  private addCallTimeCardIfNecessary(): void {
    if (!this.isOpportunityFunnel) {
      return;
    }

    this.opportunityFunnelService
      .getCallTimeText$()
      .pipe(take(1))
      .subscribe((callTime: string) => {
        this.cards.push({
          id: "contact-time",
          title: "summary.opportunityFunnel.callTime.title",
          description: `<strong>${callTime}</strong>`,
          hasSeparatorLine: false,
          onEditClick: () => {
            this.processFacade.rewindToExistingProcessId("opportunity-funnel-contact-time");
          }
        });
      });
  }

  private addContactCardIfNeccessary(): void {
    if (
      !this.summaryFeatureConfiguration.visible.contact ||
      (this.isDirectResume && !this.environmentService.env.features.summaryDirectResume.visible.contact)
    ) {
      return;
    }

    combineLatest([
      this.contactDataFacade.driverFirstname$,
      this.contactDataFacade.driverLastname$,
      this.contactDataFacade.email$,
      this.contactDataFacade.mobile$,
      this.customerCaseFacade.customerCase$
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([firstName, LastName, email, mobile, customerCase]: [string, string, string, string, CustomerCase]) => {
          const description = this.isOpportunityFunnel
            ? `${firstName} ${LastName}<br/>${email}<br/>${mobile}`
            : `${email}<br/>${mobile}`;
          const card = this.createContentCard(
            this.translocoService.translate("summary.contactData"),
            description,
            "contactDataCard",
            () => this.onEditClickOnContactCard(email, mobile, customerCase)
          );

          if (!this.contentCardsCreated) {
            this.cards.push(card);
          } else {
            this.cards = this.cards.map((obj: ContentCard) => (obj.id === card.id ? card : obj));
            this.cdr.detectChanges();
          }
        }
      );
  }

  private addAddressCardIfNeccessary(): void {
    if (
      this.isOpportunityFunnel ||
      !this.summaryFeatureConfiguration.visible.customer ||
      (this.isDirectResume && !this.summaryDirectResumeFeatureConfiguration.visible.customer)
    ) {
      return;
    }

    combineLatest([
      this.contactDataFacade.driver$,
      this.contactDataFacade.insuranceHolder$,
      this.processFacade.processMetaData$
    ])
      .pipe(take(1))
      .subscribe(([driver, insuranceHolder, processMetaData]: [Driver, InsuranceHolder, ProcessMetadata[]]) => {
        const firstName = insuranceHolder?.firstname ?? driver.firstname;
        const lastName = insuranceHolder?.lastname ?? driver.lastname;
        const streetData = insuranceHolder?.street ?? driver.street;
        const zip = insuranceHolder?.zip ?? driver.zip;
        const city = insuranceHolder?.city ?? driver.city;
        const country = insuranceHolder?.country ?? driver.country;
        const hasAddressTileInProcessFlow = processMetaData.some(
          (value: ProcessMetadata) => value.id === "customer-address"
        );

        this.cards.push(
          this.createContentCard(
            this.translocoService.translate("summary.customerData"),
            `${firstName} ${lastName}<br/>${streetData}<br/>${zip} ${city}<br/>${country}`,
            "customerDataCard",
            this.isDirectResume && !hasAddressTileInProcessFlow
              ? null
              : () => this.rewindToExistingProcessId("customer-address")
          )
        );
      });
  }

  private addAppointmentCards(): void {
    combineLatest([
      this.appointmentFacade.currentAppointment$,
      this.appointmentFacade.isCalibration$,
      this.locationsFacade.selectedServiceCenter$,
      this.breakpointService.isTouch$
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        ([appointment, isCalibration, sc, isTouch]: [Appointment, boolean, ServiceCenterLocation, boolean]) => {
          this.appointmentCards = [];
          this.isCalibration = isCalibration;

          const processId = this.isOpportunityFunnel ? "opportunity-funnel-appointment-request" : "appointment";
          const postalCode = this.isOpportunityFunnel ? sc.postalCode : appointment.postalCode;
          const city = this.isOpportunityFunnel ? sc.city : appointment.city;
          const street = this.isOpportunityFunnel ? sc.street : appointment.street;
          const sublocality = this.isOpportunityFunnel ? sc.sublocality : appointment.sublocality;

          const scName = sublocality ? `Carglass<sup>®</sup> ${city}-${sublocality}` : `Carglass<sup>®</sup> ${city}`;

          this.serviceCenterText = `<strong>Service-Center</strong></br>${scName}<br/>${street}<br/>${postalCode} ${city}`;
          const cardHeadline = this.isOpportunityFunnel
            ? "summary.opportunityFunnel.appointment.desiredAppointmentHeadline"
            : "summary.appointment.appointmentHeadline";

          this.appointmentCards.push(
            this.createContentCard(
              this.translocoService.translate(cardHeadline),
              "",
              "appointmentTimeInfoCard",
              isTouch ? () => this.rewindToExistingProcessId(processId) : null
            )
          );
          if (!isTouch) {
            this.appointmentCards.push(
              this.createContentCard(
                "<span style='color: transparent'>PLACEHOLDER</span>",
                this.serviceCenterText,
                "appointmentServiceCenterInfoCard",
                !isTouch ? () => this.rewindToExistingProcessId(processId) : null
              )
            );
          }
        }
      );
  }

  private createContentCard(title: string, description: string, id: string, onEditClickFn: () => void): ContentCard {
    return {
      title,
      description,
      id,
      isSubtitleVisibleOnMobile: true,
      hasSeparatorLine: false,
      onEditClick: onEditClickFn ? onEditClickFn : null
    };
  }

  private rewindToExistingProcessId(processId: ProcessId): void {
    this.processFacade.rewindToExistingProcessId(processId);
    this.trackAdjustEvent(processId);
  }

  private onEditClickOnContactCard(email: string, mobile: string, customerCase: CustomerCase): void {
    const saveClicked = (contactForm: CustomerContactForm) => this.updateCustomerContact(contactForm);
    const customer = customerCase.customer;
    let hasContactData = customer.contactEmail && (customer.contactPhone1 || customer.contactPhone2);
    if (!hasContactData) {
      hasContactData = customer.customerEmail && (customer.customerPhone1 || customer.customerPhone2);
    }
    if (hasContactData) {
      this.overlayService.open(EditCustomerContactComponent, {
        email,
        mobile,
        customerCaseId: customerCase.id,
        showLandline: false,
        saveClicked
      });
      this.trackAdjustEvent("customer-contact");
    } else {
      this.processFacade.rewindToExistingProcessId("customer-contact");
    }
  }

  private trackAdjustEvent(processId: ProcessId): void {
    const event: Partial<TrackingEvent> = {
      eventAction: "adjust",
      eventLabel: processId
    };
    this.trackingService.trackEvent(event);
  }

  private updateCustomerContact(contactForm: CustomerContactForm): void {
    this.contactDataFacade.setEmail(contactForm.email);
    this.contactDataFacade.setMobile(contactForm.mobile);

    combineLatest([this.contactDataFacade.email$, this.contactDataFacade.mobile$])
      .pipe(
        filter(
          ([updatedEmail, updatedMobile]: [string, string]) =>
            updatedEmail === contactForm.email || updatedMobile === contactForm.mobile
        ),
        take(1)
      )
      .subscribe(() => this.olbFacade.updateCustomerContact());
  }
}
