import { Component, inject, OnInit } from "@angular/core";
import { CockpitComponent } from "@cg/cockpit";
import { StageSubComponent } from "@cg/stage";
import { GoogleTagManagerService } from "@cg/analytics";
import { Cockpit, StageSubpage } from "@cg/content-api/typescript-interfaces";
import { EnvironmentService } from "@cg/environments";
import { timeCheckedIcon, windscreenQuality2Icon, windscreenQualityIcon } from "@cg/icon";
import { ProtectBookingFormComponent } from "../protect-booking-form/protect-booking-form.component";

@Component({
  selector: "cg-protect-booking-page",
  standalone: true,
  imports: [ProtectBookingFormComponent, CockpitComponent, StageSubComponent],
  templateUrl: "./protect-booking-page.component.html"
})
export class ProtectBookingPageComponent implements OnInit {
  private readonly gtmService = inject(GoogleTagManagerService);
  private readonly environmentService = inject(EnvironmentService);

  // TODO: add correct image
  public readonly stage: StageSubpage = {
    picture: {
      alt: "Ein Monteur, der eine Windschutzscheibe zum Auto trägt",
      sizes: [],
      source: `${this.environmentService.env.assetPath}/protect-booking/monteur.webp`,
      ngTemplate: "cgPicture"
    },
    ngTemplate: "cgStageSubpage"
  };

  public readonly cockpit: Cockpit = {
    badge: {
      variation: "secondary",
      title: "protectBookingPage.cockpit.badge"
    },
    items: [
      {
        icon: windscreenQuality2Icon,
        title: "protectBookingPage.cockpit.items.1"
      },
      {
        icon: timeCheckedIcon,
        title: "protectBookingPage.cockpit.items.2"
      },
      {
        icon: windscreenQualityIcon,
        title: "protectBookingPage.cockpit.items.3"
      }
    ],
    ngTemplate: "cockpit"
  };

  public ngOnInit(): void {
    this.trackProtectPageView();
  }

  private trackProtectPageView(): void {
    this.gtmService.trackPageView({
      name: "protect-buchen",
      type: "services",
      variant: "default"
    });
  }
}
