<div class="mt-4 w-full bg-cg-white text-cg-anthracite shadow-card t:mt-0 t:w-[440px]" data-cy="location-no-results">
  <div class="inline-flex items-center justify-start gap-3 p-4">
    <div class="h-9 w-9">
      <cg-icon [content]="pinNoIcon" class="[&_#Fill-1]:stroke-cg-red [&_#Line-1]:first-child:stroke-cg-red"></cg-icon>
    </div>
    <div class="shrink grow basis-0" [innerHtml]="'location.noResults.errorMessage' | transloco"></div>
  </div>
  <div class="border-b border-t border-cg-anthracite-light-80 p-4">
    {{ "location.noResults.title" | transloco }}
  </div>
  @if (alternativeServiceCenters?.length) {
    <div>
      @for (serviceCenter of alternativeServiceCenters; track serviceCenter.costCenter) {
        <div>
          <cg-location-result-list-item
            [content]="serviceCenter"
            (click)="clicked(serviceCenter)"
          ></cg-location-result-list-item>
        </div>
      }
    </div>
  }
</div>
