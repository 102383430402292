<section
  class="mb-step-xs flex flex-col font-roboto text-base leading-6 text-cg-anthracite m:mb-step-mobile t:mb-step-tablet d:mb-step-desktop w:mb-step-widescreen fhd:mb-step-fullhd [&>cg-split-view>div>div]:pb-8 [&>cg-split-view>div>div]:pt-8"
  data-cy="car-identified"
>
  <ng-template #master>
    <div>
      <div class="flex flex-col items-center d:items-start">
        <div class="flex max-w-[375px] flex-col items-center gap-6 d:max-w-full d:flex-row">
          <cg-icon class="h-12 w-12" [content]="likeIcon"></cg-icon>
          <div class="flex flex-col gap-1">
            <cg-olb-headline [type]="'h2'" [title]="'carIdentified.headline' | transloco: { brand }"></cg-olb-headline>
            <div class="flex items-center justify-center gap-[8px] d:justify-start">
              <p
                class="after:content['/0000a0'] !m-0 max-w-[207px] overflow-hidden text-ellipsis whitespace-nowrap pt-0.5 text-[16px] leading-5 after:inline-block after:w-0 d:max-w-[255px]"
                data-cy="car-identified-car-name"
                data-cs-mask
              >
                {{ car }}
              </p>
              <cg-ctalink
                class="[&>div]:h-fit"
                [content]="changeCarLink"
                (clickedFn)="changeCar($event)"
                data-cy="car-identified-change-car-link"
              ></cg-ctalink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <cg-split-view [master]="master" [showBranding]="false" [overflowVisible]="true"></cg-split-view>
</section>
