import { AsyncPipe, NgClass, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { combineLatest, Observable } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { CustomerCaseFacade, DamageFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { OptimizelyService, TrackingService } from "@cg/analytics";
import { ConfigFacade } from "@cg/config";
import { Cta, Picture } from "@cg/content-api/typescript-interfaces";
import { ProtectConfig } from "@cg/core/interfaces";
import { AddFormControls } from "@cg/core/types";
import { HeadlineComponent, ParagraphComponent, PictureComponent } from "@cg/core/ui";
import { EnvironmentService } from "@cg/environments";
import { arrowsIcon } from "@cg/icon";
import {
  isDirectResumeFn,
  OlbFooterComponent,
  OlbHeadlineComponent,
  ScrollService,
  VAPsEventFlag,
  VapsOfferExitIds
} from "@cg/olb/shared";
import {
  AdditionalProduct,
  Button,
  ErrorMessageComponent,
  InfoButtonComponent,
  OverlayService,
  PriceComponent,
  SplitViewComponent
} from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { BaseDirective } from "../core/directives/base/base.directive";
import { VapsProtectSecondChanceComponent } from "../vaps-protect-second-chance/vaps-protect-second-chance.component";

@Component({
  selector: "cg-vaps-offer",
  templateUrl: "./vaps-offer.component.html",
  styleUrls: ["./vaps-offer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    InfoButtonComponent,
    PictureComponent,
    PriceComponent,
    PictureComponent,
    PriceComponent,
    ErrorMessageComponent,
    SplitViewComponent,
    OlbFooterComponent,
    ParagraphComponent,
    OlbFooterComponent,
    HeadlineComponent
  ]
})
export class VapsOfferComponent extends BaseDirective<null> implements OnInit {
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly trackingService = inject(TrackingService);
  protected readonly scrollService = inject(ScrollService);
  private readonly translocoService = inject(TranslocoService);
  private readonly damageFacade = inject(DamageFacade);
  private readonly optimizelyService = inject(OptimizelyService);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);
  private readonly configFacade = inject(ConfigFacade);
  private readonly overlayService = inject(OverlayService);
  private readonly _olbConfig: OlbConfiguration = inject(OLB_CONFIG);
  private readonly environmentService = inject(EnvironmentService);

  public cta: Cta;
  public declare form: FormGroup;
  public translationTexts = {
    title: "",
    subtitle: "",
    noProtectTitle: "",
    protectTitle: ""
  };

  public forwardBtnContent: Button = {
    id: "forward-btn",
    icon: arrowsIcon,
    iconPosition: "right",
    text: ""
  };

  public readonly noCarglassProtectPicture: Picture = {
    sizes: [],
    source: `${this.environmentService.env.assetPath}/olb/vaps-offer/no-carglass-protect.webp`,
    alt: this.translationTexts.noProtectTitle,
    ngTemplate: "cgPicture"
  };

  public readonly carglassProtectPicture: Picture = {
    sizes: [],
    source: `${this.environmentService.env.assetPath}/olb/vaps-offer/carglass-protect.webp`,
    alt: this.translationTexts.protectTitle,
    ngTemplate: "cgPicture"
  };

  public get protectConfig$(): Observable<ProtectConfig> {
    return this.configFacade.protectConfig$.pipe(
      map((config: ProtectConfig) => {
        const configClone = { ...config };

        if (config?.price_reduction_percent) {
          configClone.price_reduction_percent = config.price_reduction_percent.split(".")[0];
        }

        if (config?.price) {
          configClone.price = config.price.replace(".", ",");
        }

        if (config?.price_reduced) {
          configClone.price_reduced = config.price_reduced.replace(".", ",");
        }

        return configClone;
      })
    );
  }

  public get protectPrice$(): Observable<string> {
    return this.protectConfig$.pipe(map((config: ProtectConfig) => config.price));
  }

  public get hasSelectedProtect(): boolean {
    return this.form.get("hasProtect").value;
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();

    this.form = new FormGroup<AddFormControls<{ hasProtect: boolean }>>({
      hasProtect: new FormControl<boolean>(null, Validators.required)
    });

    this.setForwardBtnText();
    this.setTranslationTexts();

    this.customerCaseFacade.offerVAP(AdditionalProduct.PROTECT);
  }

  public initFormGroup(): void {}
  public setFormValues(): void {
    this.customerCaseFacade.protectProduct$
      .pipe(
        take(1),
        filter((hasProtect: boolean) => hasProtect !== null),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((hasProtect: boolean) => {
        this.setProtectOption(hasProtect, false);
      });
  }

  public saveForm(): void {
    if (this.form.invalid) {
      return;
    }

    if (this.form.get("hasProtect").value) {
      // SILENT flag, since protect dialogs are not yet implemented and wanted
      this.customerCaseFacade.addVAP(AdditionalProduct.PROTECT, [VAPsEventFlag.SILENT]);
    } else {
      this.customerCaseFacade.removeVAP(AdditionalProduct.PROTECT, [VAPsEventFlag.SILENT]);
    }
  }

  public getExitIdForSavedForm(): Observable<VapsOfferExitIds> {
    return this.getExitId();
  }

  public setForwardBtnText(): void {
    this.optimizelyService
      .isVariationOfExperimentActive(OptimizelyExperiment.OLB_SUMMARY)
      .pipe(take(1))
      .subscribe((summaryExerimentActive: boolean) => {
        this.forwardBtnContent.text =
          summaryExerimentActive || isDirectResumeFn(this._olbConfig.entryChannel)
            ? "vapsTwoChoicesABTest.goToSummary"
            : "vapsTwoChoicesABTest.finishButton";
        this.cdr.markForCheck();
      });
  }

  public setTranslationTexts(): void {
    this.translocoService
      .selectTranslateObject("vapsTwoChoicesABTest")
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (translations: {
          title: string;
          subtitle: string;
          noProtectChoice: { title: string };
          protectChoice: { title: string };
        }) => {
          this.translationTexts = {
            title: translations.title,
            subtitle: translations.subtitle,
            noProtectTitle: translations.noProtectChoice.title,
            protectTitle: translations.protectChoice.title
          };
        }
      );
  }

  public setProtectOption(option: boolean, triggerOverlay: boolean = true): void {
    this.form.get("hasProtect").setValue(option);
    this.form.markAsTouched();

    if (!option && triggerOverlay) {
      this.overlayService.afterClosed$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe((add: boolean) => {
        this.form.get("hasProtect").setValue(add);
        this.form.markAsTouched();

        if (!add) {
          this.goForward();
        }

        this.cdr.markForCheck();
      });

      this.overlayService.open(VapsProtectSecondChanceComponent);
    }
  }

  private getExitId(): Observable<VapsOfferExitIds> {
    return combineLatest([
      this.damageFacade.requiredServiceIsReplace$,
      this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.BINDING_BOOKING),
      this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.OLB_SUMMARY),
      this.customerCaseFacade.protectProduct$
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(
        ([_isReplace, _bindingBookingExperimentActive, _isSummaryTestActive, protectProduct]: [
          boolean,
          boolean,
          boolean,
          boolean
        ]) => protectProduct !== null
      ),
      map(([isReplace, bindingBookingExperimentActive, isSummaryTestActive]: [boolean, boolean, boolean, boolean]) => {
        if (isSummaryTestActive) {
          return "summary";
        }

        if (isReplace && bindingBookingExperimentActive) {
          return "bindingBooking";
        }

        if (isDirectResumeFn(this._olbConfig.entryChannel)) {
          return "directResume";
        }

        return "appointmentSuccess";
      })
    );
  }
}
