<nav class="navbar navbar-toggleable-md" (click)="isInside = true">
  @if (mainNavigation.myCarglassLogout && !isOlbRoute && isMyCarglassDetailRoute) {
    <cg-logout-item
      class="navbar--logout-item"
      [item]="mainNavigation.myCarglassLogout"
      [hideLinkElement]="true"
    ></cg-logout-item>
  }
  <cg-toggle-button
    class="absolute right-0 top-1/2 block h-4 w-4 -translate-y-1/2 w:hidden"
    (clickOpen)="onOpen()"
    (clickClose)="onClose()"
    [visible]="visible"
  ></cg-toggle-button>
  <a
    routerLink="{{ mainNavigation?.brand?.link?.href }}"
    class="navbar-brand"
    target="{{ mainNavigation?.brand?.link?.target || '_self' }}"
  >
    <img
      class="logo-image"
      src="{{ mainNavigation?.brand?.image?.src }}"
      alt="{{ mainNavigation?.brand?.image?.alt }}"
    />
  </a>
  <div class="animation-wrapper" [class.is-collapsed]="!visible">
    <div class="navbar-main-nav navbar-collapse justify-content-end">
      <cg-meta-navigation
        class="meta-nav-screen d-lg-flex justify-content-end"
        [metaNavigation]="mainNavigation.mainNavigationItems"
        [route]="route.url"
      ></cg-meta-navigation>
      @if (!isOlbRoute && !isMyCarglassDetailRoute) {
        <ul class="navbar-nav nav-level-0 meta-nav-mobile">
          @if (mainNavigation.repair) {
            <li class="nav-item nav-item-repair">
              <a
                routerLink="{{ mainNavigation.repair.href }}"
                class="nav-link nav-link-meta"
                target="{{ mainNavigation.repair.target }}"
                [class.active]="mainNavigation.repair.href === route.url"
                [innerHtml]="mainNavigation.repair.text | safeContent"
              ></a>
            </li>
          }
        </ul>
      }
      @for (item0 of mainNavigation.mainNavigationItems; track item0.href; let i = $index) {
        <ul class="navbar-nav nav-level-0" [class.active]="isActiveMenu(item0)">
          @if (item0.items) {
            <a
              routerLink="{{ item0.href }}"
              class="nav-link nav-link-meta"
              [class.active]="isActiveMenu(item0)"
              [innerHtml]="item0.text | safeContent"
            ></a>
          }
          @if (!item0.items) {
            <a
              routerLink="{{ item0.href }}"
              class="nav-link nav-link-meta"
              target="{{ item0.target }}"
              [class.active]="item0.href === route.url"
              [innerHtml]="item0.text | safeContent"
            ></a>
          }
          @for (item1 of item0.items; track item1.href; let j = $index) {
            @if (!isAppointmentChangeOnOLB(item1)) {
              <li
                class="nav-item nav-level-0-item dropdown"
                [class.active]="isActive(1, j)"
                (mouseenter)="handleHover(1, j, $event)"
                (mouseleave)="handleHover(1, j, $event)"
                [attr.data-cy]="'main-navigation-' + item1.text"
              >
                @if (item1.items && isLgDown) {
                  <div class="nav-overlay nav-overlay-level-0"></div>
                }
                @if (item1.items) {
                  <a
                    class="nav-link nav-level-0-item dropdown"
                    [class.active]="isActive(1, j)"
                    [class.marked]="isActiveItem(item1)"
                    (click)="navigateToNextLevel(item1.href, 1, j)"
                    [innerHtml]="item1.text | safeContent"
                  ></a>
                }
                @if (!item1.items && item1.href.includes("http")) {
                  <a
                    class="nav-link nav-level-0-item"
                    [class.active]="isActive(1, j)"
                    [class.marked]="isActiveItem(item1)"
                    (click)="navigateToNextLevel(item1.href, 1, j)"
                    [innerHtml]="item1.text | safeContent"
                  ></a>
                }
                @if (!item1.items && !item1.href.includes("http")) {
                  <a
                    class="nav-link nav-level-0-item"
                    [class.marked]="isActiveItem(item1)"
                    (click)="navigateToNextLevel(item1.href, 1, j)"
                  >
                    <cg-navigation-link-text [text]="item1.text"></cg-navigation-link-text>
                  </a>
                }
                @if (item1.items) {
                  <ul
                    [@flyIn]="isActive(1, j) + '' + isLgDown"
                    class="nav-level-2 navbar-nav-sub flex flex-row gap-4 p-4"
                    [class.active]="isActive(1, j)"
                  >
                    @if (item1.items && isLgDown) {
                      <li class="nav-item nav-item-level-2 nav-item-back-top">
                        <a class="nav-link nav-link-back-top flex items-center" (click)="toggleSubnav(1, j)">
                          <cg-icon class="back-icon mr-2" [content]="icon"></cg-icon>
                          {{ "navigation.back" | transloco }}
                        </a>
                      </li>
                    }
                    @if (isLgDown) {
                      <li class="nav-item nav-item-level-2 nav-item-back">
                        <a
                          class="nav-link"
                          target="{{ item1.target }}"
                          [class.marked]="isActiveItem(item0)"
                          (click)="navigateToNextLevel(item1.href, 1, j)"
                          [innerHtml]="item1.text | safeContent"
                        >
                        </a>
                      </li>
                    }
                    @for (item2 of item1.items; track item2.href; let k = $index) {
                      <li class="nav-item nav-item-level-2 flex flex-col" [class.active]="isActive(2, k)">
                        @if (item2.items && isLgDown) {
                          <div class="nav-overlay nav-overlay-level-1"></div>
                        }
                        <a
                          routerLink="{{ item2.href }}"
                          class="nav-link"
                          [class.marked]="isActiveItem(item2)"
                          (click)="navigateToNextLevel(item2.href, 2, k)"
                          [innerHtml]="item2.text | safeContent"
                        ></a>
                        @if (item2.items) {
                          <ul [@flyIn]="isActive(2, k) + '' + isLgDown" class="nav-level-3">
                            @if (item2.items && isLgDown) {
                              <li class="nav-item nav-item-level-3 nav-item-back-top">
                                <a
                                  class="nav-link nav-link-level-3 nav-link-back-top flex items-center"
                                  (click)="toggleSubnav(2, k)"
                                >
                                  <cg-icon class="back-icon mr-2" [content]="icon"></cg-icon>
                                  {{ "navigation.back" | transloco }}
                                </a>
                              </li>
                            }
                            <li class="nav-item nav-item-level-3 nav-item-back">
                              <a
                                class="nav-link"
                                target="{{ item2.target }}"
                                [class.marked]="isActiveItem(item2)"
                                (click)="navigateToNextLevel(item2.href, 2, k)"
                                [innerHtml]="item2.text | safeContent"
                              ></a>
                            </li>
                            @for (item3 of item2.items; track item3.text) {
                              <li class="nav-item nav-item-level-3">
                                <a
                                  routerLink="{{ item3.href }}"
                                  class="nav-link nav-link-level-3"
                                  target="{{ item3.target }}"
                                  [class.marked]="isActiveItem(item3)"
                                  [innerHtml]="item3.text | safeContent"
                                ></a>
                              </li>
                            }
                          </ul>
                        }
                      </li>
                    }
                  </ul>
                }
              </li>
            }
          }
          @if (mainNavigation.repair && !isOlbRoute && !isMyCarglassDetailRoute) {
            <li class="nav-item nav-item-repair">
              <a
                routerLink="{{ mainNavigation.repair.href }}"
                routerLinkActive="active"
                class="nav-link nav-link-cta"
                target="{{ mainNavigation.repair.target || '_self' }}"
              >
                <div>{{ mainNavigation.repair.text }}</div>
              </a>
            </li>
          }
          @if (mainNavigation.myCarglassLogout && !isOlbRoute && isMyCarglassDetailRoute) {
            <li class="nav-item nav-item-mycarglass-logout">
              <cg-logout-item [item]="mainNavigation.myCarglassLogout"></cg-logout-item>
            </li>
          }
        </ul>
      }
    </div>
  </div>
</nav>
