<ol class="flex flex-col gap-2">
  @for (item of content().paragraphItems; track $index) {
    <li class="flex flex-row gap-2">
      @if (content().type === "icon") {
        <cgx-icon [content]="content().icon"></cgx-icon>
      } @else if (content().type === "numeric") {
        <cgx-paragraph [content]="generateNumericListParagraph($index)"></cgx-paragraph>
      }
      <cgx-paragraph [content]="item"></cgx-paragraph>
    </li>
  }
</ol>
