import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule, UntypedFormControl, Validators } from "@angular/forms";
import { Actions, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { combineLatest, iif, Observable, of } from "rxjs";
import { filter, first, mergeMap, take } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { DamageActions, DamageFacade, DamageState, InsuranceFacade, ProcessFacade, ProductFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingEvent, TrackingService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import {
  ChooseCrackDamageTypeExitIds,
  ExitIdService,
  Insurance,
  isDirectResumeFn,
  OlbHeadlineComponent,
  Product,
  ScrollService
} from "@cg/olb/shared";
import { DamageChipCount, DamageType, ProcessId, SplitViewComponent } from "@cg/shared";
import { ExitNodeResolverService } from "../../services/exit-node-resolver.service";
import { DamageAssessmentTileComponent } from "../../tiles/damage-assessment-tile/damage-assessment-tile.component";
import { BaseDirective } from "../core/directives/base/base.directive";
import { DamageTypeForm } from "./interfaces/damage-type.interface";
import { damageTileMultipleChipsContent } from "./models/damage-tile-multiple-chips-content.model";
import { eventDamageTypeMultipleChipsMapping } from "./models/damage-type-tracking-mapping.model";

@Component({
  selector: "cg-damage-type",
  templateUrl: "./damage-type.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, ReactiveFormsModule, OlbHeadlineComponent, DamageAssessmentTileComponent, SplitViewComponent]
})
export class DamageTypeComponent extends BaseDirective<AddFormControls<DamageTypeForm>> implements OnInit, OnDestroy {
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly scrollService = inject(ScrollService);
  protected readonly trackingService = inject(TrackingService);
  private readonly damageFacade = inject(DamageFacade);
  private readonly insuranceFacade = inject(InsuranceFacade);
  private readonly exitIdService = inject(ExitIdService);
  private readonly productFacade = inject(ProductFacade);
  private readonly actions$ = inject(Actions);
  private readonly _olbConfig: OlbConfiguration = inject(OLB_CONFIG);

  public damageTileContent = damageTileMultipleChipsContent;

  public get crackAmount(): DamageType {
    return this.form.controls.damageType.value;
  }

  public async ngOnInit() {
    await super.ngOnInit();

    this.processFacade.editOverlayClosed$
      .pipe(
        filter(({ processId }: { processId: ProcessId }) => processId === this.processId),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.form.reset();

        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.damageFacade.setCrackAmount(null);
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<DamageTypeForm>>({
      damageType: new UntypedFormControl("", Validators.required)
    });
  }

  public setFormValues() {
    this.damageFacade.damageType$
      .pipe(
        filter((damageType: DamageType) => !!damageType),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((damageType: DamageType) => {
        this.form.controls.damageType.setValue(damageType);
        this.cdr.markForCheck();
      });
  }

  public getExitIdForSavedForm(): Observable<ChooseCrackDamageTypeExitIds> {
    if (this.crackAmount === DamageType.MAJOR) {
      return of("multipleChipsDamageType");
    }

    const isDirectResume = isDirectResumeFn(this._olbConfig.entryChannel);

    return combineLatest([
      iif(
        () => this.crackAmount !== DamageType.CRACK,
        of(null),
        this.actions$.pipe(ofType(DamageActions.updateDamageSuccess), take(1))
      ),
      this.insuranceFacade.selectedInsurance$,
      this.productFacade.products$,
      this.insuranceFacade.vin$
    ]).pipe(
      filter(([_action, _, products, _vin]: [Action, Insurance, Product[], string]) => !isDirectResume || !!products),
      take(1),
      mergeMap(([_action, insurance, products, vin]: [Action, Insurance, Product[], string]) =>
        this.exitIdService.getExitIdForDamageType$(this.crackAmount, insurance, isDirectResume, products, vin)
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  public goForward() {
    if (this.form.valid) {
      this.trackDamageType();
    }

    // current process id is checked so the forward is not triggered on restore with resume id
    this.processFacade.currentProcessId$
      .pipe(
        first(),
        filter((id: ProcessId) => id === "damage-type"),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => super.goForward());
  }

  public saveForm() {
    const damageType = this.form.controls.damageType.value;
    this.damageFacade.setCrackAmount(damageType);
    if (damageType === DamageType.MINOR) {
      this.damageFacade.setDamageChipCount(DamageChipCount.ONE);
    } else if (damageType === DamageType.CRACK) {
      this.damageFacade.setDamageChipCount(DamageChipCount.NONE);
    }
  }

  public override postSaveForm() {
    super.postSaveForm();

    if (this.crackAmount === DamageType.CRACK) {
      this.damageFacade.damageState$
        .pipe(
          filter((damageState: DamageState) => this.crackAmount === damageState.damageType),
          take(1),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((damageState: DamageState) => this.damageFacade.updateDamage(damageState));
    }
  }

  private trackDamageType() {
    const damageType = this.form.controls.damageType.value;
    const eventDamageType = eventDamageTypeMultipleChipsMapping.get(damageType);

    this.trackingService.trackEvent({
      eventAction: "damage-type-selection",
      eventLabel: eventDamageType,
      damage: {
        type: eventDamageType
      }
    } as Partial<TrackingEvent>);
  }

  protected restoreFromEntryState() {
    this.damageFacade.damageType$.pipe(take(1)).subscribe((damageType: DamageType) => {
      setTimeout(() => {
        this.form.controls.damageType.setValue(damageType);
        this.cdr.markForCheck();
        this.goForward();
      }, 100);
    });
  }
}
