/* eslint-disable sonarjs/no-duplicate-string */
import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { distinctUntilChanged, distinctUntilKeyChanged, filter, tap, withLatestFrom } from "rxjs/operators";
import {
  AppointmentActions,
  AppointmentFacade,
  ContactDataFacade,
  CustomerCaseActions,
  DamageFacade,
  ProcessFacade
} from "@cg/olb/state";
import differenceInDays from "date-fns/differenceInDays";
import { OptimizelyService, TrackingEvent, TrackingService } from "@cg/analytics";
import { PLACEHOLDER } from "@cg/core/ui";
import { isOpportunityFunnel } from "@cg/core/utils";
import { EnvironmentService } from "@cg/environments";
import {
  Appointment,
  AppointmentPayload,
  AppointmentResponse,
  AptModel,
  AvailableServiceCenters,
  CustomerCase,
  HashService,
  ProcessMetadata,
  RequiredService,
  SetAppointmentPayload
} from "@cg/shared";
import { OptimizelyExperiment } from "@cg/core/enums";

interface Pload {
  lat: number;
  lng: number;
  formattedAddress: string;
  locality: string;
  timerangeBegin?: string;
}

@Injectable()
export class AppointmentTrackingEffects {
  private readonly actions$ = inject(Actions);
  private readonly trackingService = inject(TrackingService);
  private readonly appointmentFacade = inject(AppointmentFacade);
  private readonly damageFacade = inject(DamageFacade);
  private readonly processFacade = inject(ProcessFacade);
  private readonly contactDataFacade = inject(ContactDataFacade);
  private readonly optimizelyService = inject(OptimizelyService);
  private readonly hashService = inject(HashService);
  private readonly environmentService = inject(EnvironmentService);

  private initialDistanceOffered: number;

  public get isB2b(): boolean {
    return !!this.environmentService.env.b2b;
  }

  public getAppointmentsSuccessTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppointmentActions.getAppointmentsSuccess),
        withLatestFrom(
          this.appointmentFacade.availableAppointments$,
          this.appointmentFacade.availableServiceCenters$,
          this.appointmentFacade.startDate$,
          this.appointmentFacade.formattedAddress$,
          this.appointmentFacade.aptModel$,
          this.appointmentFacade.selectedServiceCenterIds$,
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE),
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
        ),
        tap(
          ([
            _,
            appointments,
            branches,
            startDate,
            formattedAddress,
            aptModel,
            selectedServiceCenterIds,
            appointmentTileExperimentActive,
            appointmentTileDesktopExperimentActive
          ]: [
            { payload: AppointmentResponse },
            Appointment[],
            AvailableServiceCenters[],
            string,
            string,
            AptModel,
            string[],
            boolean,
            boolean
          ]) => {
            // TODO: check whole array
            if (
              this.isTeslaCreateScDropDownFetch(aptModel, selectedServiceCenterIds?.[0] ?? null) ||
              appointmentTileExperimentActive ||
              appointmentTileDesktopExperimentActive
            ) {
              return;
            }

            this.trackAppointmentResult(formattedAddress, branches, appointments, startDate);
          }
        )
      ),
    { dispatch: false }
  );

  public appointmentResultTrackingNewAppointmentTile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppointmentActions.setSearchClicked),
        distinctUntilKeyChanged("payload"),
        filter(({ payload }: { payload: boolean }) => !!payload),
        withLatestFrom(
          this.appointmentFacade.availableAppointments$,
          this.appointmentFacade.availableServiceCenters$,
          this.appointmentFacade.startDate$,
          this.appointmentFacade.formattedAddress$,
          this.appointmentFacade.aptModel$,
          this.appointmentFacade.selectedServiceCenterIds$,
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE),
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
        ),
        tap(
          ([
            _,
            appointments,
            branches,
            startDate,
            formattedAddress,
            aptModel,
            selectedServiceCenterIds,
            appointmentTileExperimentActive,
            appointmentTileDesktopExperimentActive
          ]: [
            { payload: boolean },
            Appointment[],
            AvailableServiceCenters[],
            string,
            string,
            AptModel,
            string[],
            boolean,
            boolean
          ]) => {
            if (
              this.isTeslaCreateScDropDownFetch(aptModel, selectedServiceCenterIds?.[0] ?? null) ||
              (!appointmentTileExperimentActive && !appointmentTileDesktopExperimentActive)
            ) {
              return;
            }

            this.trackAppointmentResult(formattedAddress, branches, appointments, startDate);
          }
        )
      ),
    { dispatch: false }
  );

  public searchLocationChangedTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppointmentActions.saveAutoCompleteResult),
        distinctUntilChanged(
          ({ payload: prevPayload }: { payload: Pload }, { payload }: { payload: Pload }) =>
            prevPayload.lat === payload.lat && prevPayload.lng === payload.lng
        ),
        withLatestFrom(
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE),
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
        ),
        tap(
          ([_, newAppointmentAbTestActive, newAppointmentDesktopAbTestActive]: [
            { payload: Pload },
            boolean,
            boolean
          ]) => {
            if (newAppointmentAbTestActive || newAppointmentDesktopAbTestActive) {
              return;
            }

            this.trackingService.trackEvent({
              event: "ga-event",
              eventCategory: "olb",
              eventAction: "appointment-filter",
              eventLabel: "location"
            });
          }
        )
      ),
    { dispatch: false }
  );

  public searchLocationChangedTrackingNewAppointment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppointmentActions.setSearchClicked),
        distinctUntilChanged(
          ({ payload: prev }: { payload: boolean }, { payload: curr }: { payload: boolean }) => prev === curr
        ),
        withLatestFrom(
          this.appointmentFacade.firstSearch$,
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE),
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
        ),
        filter(
          ([{ payload }, _firstSeach, newAppointmentAbTestActive, newAppointmentDesktopAbTestActive]: [
            { payload: boolean },
            boolean,
            boolean,
            boolean
          ]) => payload && (newAppointmentAbTestActive || newAppointmentDesktopAbTestActive)
        ),
        tap(
          ([_action, firstSearch, _newAppointmentAbTestActive, _newAppointmentDesktopAbTestActive]: [
            { payload: boolean },
            boolean,
            boolean,
            boolean
          ]) => {
            this.trackingService.trackEvent({
              event: "ga-event",
              eventCategory: "olb",
              eventAction: firstSearch ? "appointment-filter-initial" : "appointment-filter-change",
              eventLabel: "location"
            });
          }
        )
      ),
    { dispatch: false }
  );

  // TODO: likely can be removed when new appointment tile is fully rolled out
  public changedBranchTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppointmentActions.getAppointments),
        withLatestFrom(
          this.appointmentFacade.selectedServiceCenterIds$,
          this.appointmentFacade.availableServiceCenters$
        ),
        tap(
          ([_, scIds, availableServiceCenters]: [
            { payload: AppointmentPayload; type: string },
            string[],
            AvailableServiceCenters[]
          ]) => {
            if (
              scIds &&
              scIds.length > 0 &&
              scIds[0] !== PLACEHOLDER &&
              scIds.length !== availableServiceCenters?.length
            ) {
              this.trackingService.trackEvent({
                event: "ga-event",
                eventCategory: "olb",
                eventAction: "appointment-filter",
                eventLabel: "branch"
              });
            }
          }
        )
      ),
    { dispatch: false }
  );

  public currentAppointmentTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppointmentActions.confirmAppointmentSuccess),
        withLatestFrom(
          this.appointmentFacade.isCalibration$,
          this.damageFacade.requiredService$,
          this.processFacade.processMetaData$
        ),
        tap(
          ([action, isCalibration, requiredService, processMetaData]: [
            { payload: SetAppointmentPayload; type: string },
            boolean,
            RequiredService,
            ProcessMetadata[]
          ]) => {
            const { serviceCenter, availabilityPeriodStart, city, postalCode, street, state } = action.payload;

            const today = new Date(Date.now());
            today.setHours(0, 0, 0, 0);
            const dateAppointment = new Date(availabilityPeriodStart);
            dateAppointment.setHours(0, 0, 0, 0);

            const daysUntil = differenceInDays(dateAppointment, today);

            if (isOpportunityFunnel(processMetaData)) {
              return;
            }

            this.trackingService.trackEvent({
              event: "ga-event",
              eventCategory: "olb",
              eventAction: "appointment-selection",
              eventLabel: this.determineAppointmentSelectionEventLabel(isCalibration, requiredService),
              appointment: {
                "days-until": daysUntil,
                city,
                region: state,
                branch: {
                  address: `${street}, ${postalCode} ${city}`,
                  id: serviceCenter
                }
              }
            });
          }
        )
      ),
    { dispatch: false }
  );

  public appointmentConfirmationRepairType$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CustomerCaseActions.confirmCustomerCaseSuccess),
        withLatestFrom(
          this.damageFacade.requiredService$,
          this.appointmentFacade.isCalibration$,
          this.processFacade.processMetaData$,
          this.contactDataFacade.email$
        ),
        tap(
          ([_, requiredService, isCalibration, processMetaData, email]: [
            { payload: CustomerCase; type: string },
            RequiredService,
            boolean,
            ProcessMetadata[],
            string
          ]) => {
            let eventAction = "appointment-confirmation-repair";

            if (requiredService === RequiredService.REPLACE) {
              eventAction = "appointment-confirmation-replace-" + (isCalibration ? "adas" : "non-adas");
            }

            // should only track if user is not in opportunity-funnel
            if (!isOpportunityFunnel(processMetaData)) {
              this.trackingService.trackEvent({
                event: "ga-event",
                eventCategory: "olb",
                eventLabel: "appointment-confirmation",
                eventAction,
                clientMail: this.hashService.hashString(email)
              });
            }

            if (this.isB2b) {
              this.trackingService.endSession();
            }
          }
        )
      ),
    { dispatch: false }
  );

  public appointmentTypeTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppointmentActions.getAppointmentsSuccess),
        withLatestFrom(
          this.appointmentFacade.isCalibration$,
          this.damageFacade.requiredService$,
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE),
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
        ),
        tap(
          ([
            _action,
            isCalibration,
            requiredService,
            appointmentTileExperimentActive,
            appointmentTileDesktopExperimentActive
          ]: [{ payload: AppointmentResponse; type: string }, boolean, RequiredService, boolean, boolean]) => {
            if (appointmentTileExperimentActive || appointmentTileDesktopExperimentActive) {
              return;
            }

            this.trackingService.trackEvent({
              event: "ga-event",
              eventCategory: "olb",
              eventAction: "appointment-type",
              eventLabel: this.determineAppointmentSelectionEventLabel(isCalibration, requiredService)
            });
          }
        )
      ),
    { dispatch: false }
  );

  public appointmentTypeTrackingNewAppointmentTile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppointmentActions.setSearchClicked),
        distinctUntilKeyChanged("payload"),
        filter(({ payload }: { payload: boolean }) => !!payload),
        withLatestFrom(
          this.appointmentFacade.isCalibration$,
          this.damageFacade.requiredService$,
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE),
          this.optimizelyService.isVariationOfExperimentActive(OptimizelyExperiment.NEW_APPOINTMENT_TILE_DESKTOP)
        ),
        tap(
          ([
            _action,
            isCalibration,
            requiredService,
            appointmentTileExperimentActive,
            appointmentTileDesktopExperimentActive
          ]: [{ payload: boolean }, boolean, RequiredService, boolean, boolean]) => {
            if (!appointmentTileExperimentActive && !appointmentTileDesktopExperimentActive) {
              return;
            }

            this.trackingService.trackEvent({
              event: "ga-event",
              eventCategory: "olb",
              eventAction: "appointment-type",
              eventLabel: this.determineAppointmentSelectionEventLabel(isCalibration, requiredService)
            });
          }
        )
      ),
    { dispatch: false }
  );
  private trackAppointmentResult(
    formattedAddress: string,
    branches: AvailableServiceCenters[],
    appointments: Appointment[],
    startDate: string
  ) {
    const searchCity = this.formatSearchCityForAnalytics(formattedAddress);
    let eventLabel = "result";

    if (!branches || branches.length === 0) {
      eventLabel = "no-branches";
    } else if (!appointments || appointments.length === 0) {
      eventLabel = "no-appointment";
    }

    let appointment = null;

    if (eventLabel === "result") {
      const today = new Date(Date.now());
      today.setHours(0, 0, 0, 0);
      const firstDate = new Date(startDate);
      firstDate.setHours(0, 0, 0, 0);

      const daysUntil = differenceInDays(firstDate, today);
      const nearestDistance = this.getNearestDistance(branches, appointments);

      if (!this.initialDistanceOffered) {
        this.initialDistanceOffered = nearestDistance;
      }

      appointment = {
        "first-appointment-available-days-until": daysUntil,
        "available-appointments": appointments.length,
        "search-city": searchCity,
        "initial-distance-offered": this.initialDistanceOffered,
        "distance-offered": nearestDistance
      };
    } else {
      appointment = {
        "search-city": searchCity
      };
    }

    const payload: Partial<TrackingEvent> = {
      event: "ga-event",
      eventCategory: "olb",
      eventAction: "appointment-result",
      eventLabel
    };

    // eslint-disable-next-line @typescript-eslint/no-extra-parens
    this.trackingService.trackEvent({ ...payload, ...(appointment && { appointment }) });
  }

  private getNearestDistance(branches: AvailableServiceCenters[], appointments: Appointment[]) {
    const BIG_INITIAL_DISTANCE = 1000000;
    return branches.reduce((acc: number, curr: AvailableServiceCenters) => {
      if (curr.distance < acc) {
        const scHasAppointments = appointments.some((app: Appointment) => app.serviceCenter === curr.serviceCenter);
        if (scHasAppointments) {
          return curr.distance;
        }
      }

      return acc;
    }, BIG_INITIAL_DISTANCE);
  }

  private determineAppointmentSelectionEventLabel(isCalibration: boolean, requiredService: RequiredService): string {
    if (isCalibration) {
      return "replace-adas";
    } else if (!isCalibration && requiredService === RequiredService.REPLACE) {
      return "replace-non-adas";
    }

    return "repair";
  }

  private formatSearchCityForAnalytics(address: string | null): string {
    if (!address) {
      return null;
    }
    const city = address.split(",").splice(-2);
    city[0] = city[0].replace(/\d{5} /, "");
    return city.join(",").trim();
  }

  private isTeslaCreateScDropDownFetch(aptModel: AptModel, selectedServiceCenterId: string) {
    return aptModel === AptModel.TESLA && !selectedServiceCenterId;
  }
}
