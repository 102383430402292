import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LOCATIONS_FEATURE_KEY, type LocationsState } from "./locations.reducer";

// Lookup the 'Locations' feature state managed by NgRx
const getLocationsState = createFeatureSelector<LocationsState>(LOCATIONS_FEATURE_KEY);

export const getUserPosition = createSelector(getLocationsState, (state: LocationsState) => state.userPosition);
export const getSearch = createSelector(getLocationsState, (state: LocationsState) => state.search);
export const getCurrentPage = createSelector(getLocationsState, (state: LocationsState) => state.currentPage);

export const getServiceCenterLocations = createSelector(
  getLocationsState,
  (state: LocationsState) => state?.serviceCenterLocations ?? []
);

export const hasLoaded = createSelector(getLocationsState, (state: LocationsState) => state.loaded);

export const getToggleServiceCenterLocation = createSelector(
  getLocationsState,
  (state: LocationsState) => state.toggledServiceCenter
);

export const getShowServiceCenterDetails = createSelector(
  getLocationsState,
  (state: LocationsState) => state.showServiceCenterDetails
);

export const getSelectedServiceCenter = createSelector(
  getLocationsState,
  (state: LocationsState) => state.selectedServiceCenter
);

export const getIsServiceCenterClosed = createSelector(
  getLocationsState,
  (state: LocationsState) => state.isServiceCenterClosed
);
