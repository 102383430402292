<div class="flex flex-col gap-y-2 cgxT:gap-y-4 cgxD:gap-y-6">
  @for (item of content().items; track item.paragraph) {
    <div
      class="flex w-[var(--width,328px)] gap-x-4 rounded-lg bg-cg-gray-light-80 p-4 cgxT:w-[var(--touch-width,328px)] cgxT:p-6 cgxD:w-[var(--desktop-width,472px)]"
    >
      <cgx-icon class="[--icon-size:48px]" [content]="item.icon"></cgx-icon>
      <div class="flex flex-col gap-y-1">
        <cgx-paragraph [content]="item.paragraph"></cgx-paragraph>

        @if (item.showOpeningHours) {
          <cgx-ccc-opening-hours></cgx-ccc-opening-hours>
        }
      </div>
    </div>
  }
</div>
