<cg-drawer [showCloseIcon]="true" [headline]="headline" [footer]="footer" [content]="content"></cg-drawer>

<ng-template #headline>
  <cg-olb-headline data-cy="base-info-headline" [title]="'vapsProtectSecondChance.title' | transloco"></cg-olb-headline>
</ng-template>

<ng-template #content>
  <div class="flex flex-col gap-4">
    <cg-paragraph>
      {{ paragraphTextKey() | transloco }}
    </cg-paragraph>

    <div class="flex flex-col gap-4 d:flex-row">
      @for (item of listItems; track item.text) {
        <div class="flex flex-row items-center gap-4 d:w-full d:flex-col d:items-center">
          <cg-icon class="h-16 w-16" [content]="item.icon"></cg-icon>
          <cg-paragraph
            [content]="item.text"
            class="d:text-center [&>p]:text-[13px] [&>p]:leading-5 d:[&>p]:text-base d:[&>p]:leading-6"
          ></cg-paragraph>
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #footer>
  <div class="flex flex-col gap-2 t:flex-row t:gap-4">
    <button
      data-cy="btn-add-protect"
      cg-base-button
      class="w-full part-[icon-text-wrapper]:px-4"
      [btnText]="'vapsProtectSecondChance.addBtnText' | transloco"
      (click)="close(true)"
    ></button>

    <button
      data-cy="btn-no-protect"
      cg-base-button
      class="w-full part-[icon-text-wrapper]:x-[bg-transparent,px-4] part-[text]:x-[text-cg-anthracite-light-30,underline] part-[icon-text-wrapper]:hover:bg-transparent"
      [btnText]="'vapsProtectSecondChance.noBtnText' | transloco"
      (click)="close(false)"
    ></button>
  </div>
</ng-template>
