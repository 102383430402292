import { animate, style, transition, trigger } from "@angular/animations";
import { AsyncPipe, DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  isDevMode,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { catchError, Observable, of, withLatestFrom } from "rxjs";
import { delay, filter, map, mergeMap, take, tap } from "rxjs/operators";
import { SaveDialogComponent } from "@cg/olb/save-and-restore";
import { CustomerCaseFacade, DamageFacade, InsuranceFacade, OlbFacade, ProcessFacade, YextFacade } from "@cg/olb/state";
import { SpinnerComponent } from "@cg/spinner";
import {
  AnalyticsEventContainerComponent,
  AnalyticsEventService,
  CleverreachService,
  OptimizelyFeEvent,
  TrackingService
} from "@cg/analytics";
import { BiProcessorService } from "@cg/bi-processor";
import { ServiceCenter, ServiceCenterAddress } from "@cg/core/interfaces";
import { UnifiedError } from "@cg/core/types";
import { errorToString, IS_SERVER_PLATFORM } from "@cg/core/utils";
import { EnvironmentService } from "@cg/environments";
import {
  InsuranceResponse,
  VAPsEventFlag,
  VAPsFailureEventData,
  VAPsSuccessEventData,
  VAPsTriggerEventData
} from "@cg/olb/shared";
import {
  AdditionalProduct,
  CustomerCaseService,
  OverlayService,
  ProcessId,
  ProcessMetadata,
  ProgressbarComponent,
  SaveButtonComponent,
  ServiceCenterService
} from "@cg/shared";
import { OlbStepComponent } from "../olb-step/olb-step.component";

export const MESSAGE_ERROR_WIPER_NOT_ALLOWED_IN_REPAIR_CASE = "Add wiper is only allowed in replace case! ";
export const MESSAGE_ERROR_PRODUCT_TYPE_INVALID = "Event product type was not valid! ";

/**
 * the delay is for the animation to the next tile. it looks better when the
 * progressbar disappear when the next tile is nearly visible.
 */
const HIDE_PROCESS_BAR_DELAY = 500;

type OptimizelyBiProcessorTrackingEvent = CustomEvent<{
  message: string;
}>;

@Component({
  selector: "cg-olb-flow",
  templateUrl: "./olb-flow.component.html",
  animations: [
    trigger("hideAnimation", [
      transition(":leave", [
        style({ transform: "translateY(0)" }),
        animate("500ms", style({ transform: "translateY(-100px)" }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, ProgressbarComponent, SaveButtonComponent, OlbStepComponent, SpinnerComponent]
})
export class OlbFlowComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly processFacade = inject(ProcessFacade);
  private readonly olbFacade = inject(OlbFacade);
  private readonly overlayService = inject(OverlayService);
  private readonly trackingService = inject(TrackingService);
  private readonly biProcessorService = inject(BiProcessorService);
  private readonly customerCaseFacade = inject(CustomerCaseFacade);
  private readonly isServer: boolean = inject(IS_SERVER_PLATFORM);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly analyticsEventService = inject(AnalyticsEventService);
  private readonly cleverreachService = inject(CleverreachService);
  private readonly yextFacade = inject(YextFacade);
  private readonly serviceCenterService = inject(ServiceCenterService);
  private readonly damageFacade = inject(DamageFacade);
  private readonly customerCaseService = inject(CustomerCaseService);
  private readonly document: Document = inject(DOCUMENT);
  private readonly environmentService = inject(EnvironmentService);
  private readonly insuranceFacade = inject(InsuranceFacade);

  @ViewChild("olbFlow") public olbFlow: ElementRef;
  public processPercentage$: Observable<number>;
  public processMetaData$ = this.processFacade.processMetaData$;
  public processBarVisible = true;

  private biProcessorTrackingSentMessageSet: Set<string> = new Set<string>();

  public get activeSaveAndRestore(): boolean {
    return this.environmentService.env.features.saveAndRestore;
  }

  public ngOnInit() {
    this.olbFacade.initOLB();

    this.processPercentage$ = this.processFacade.getProgressPercentage();

    this.processFacade.currentProcessId$
      .pipe(delay(HIDE_PROCESS_BAR_DELAY), takeUntilDestroyed(this.destroyRef))
      .subscribe((processId: ProcessId) => {
        this.processBarVisible =
          processId !== "channel-switch" &&
          processId !== "appointment-confirmation" &&
          processId !== "opportunity-funnel-success";
        this.cdr.detectChanges();
      });
  }

  public ngAfterViewInit() {
    if (this.isServer) {
      return;
    }

    this.addEventHandler();
  }

  public ngOnDestroy(): void {
    if (this.isServer) {
      return;
    }

    this.removeEventHandler();
  }

  public trackByFn(_initialValueindex: number, item: ProcessMetadata): ProcessId {
    return item.id;
  }

  public onSaveClick() {
    this.trackingService.trackEvent({
      eventAction: "my-carglass",
      eventLabel: "save-progress-intention"
    });

    this.overlayService.open(SaveDialogComponent, null, { positionByBreakpoints: OverlayService.POSITION_M_CENTER });
  }

  private sendBiProcessorTracking(event: OptimizelyBiProcessorTrackingEvent) {
    const message = event.detail.message;
    if (this.biProcessorTrackingSentMessageSet.has(message)) {
      if (isDevMode()) {
        // eslint-disable-next-line no-console
        console.warn("BiProcessor tracking already sent");
      }
      return;
    }

    this.customerCaseFacade.customerCaseId$
      .pipe(
        filter((customerCaseId: string) => Boolean(customerCaseId)),
        take(1),
        mergeMap((customerCaseId: string) =>
          this.biProcessorService.sendABVariations(message, customerCaseId).pipe(map(() => customerCaseId))
        ),
        tap(() => this.biProcessorTrackingSentMessageSet.add(message)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private sendCleverreachTracking(event: CustomEvent<{ email: string; groupId: string; confirmId: string }>): void {
    this.customerCaseFacade.customerCaseId$
      .pipe(
        map((customerCaseId: string) => ({
          customerCaseId: customerCaseId,
          email: event.detail.email,
          groupId: event.detail.groupId,
          confirmId: event.detail.confirmId
        })),
        mergeMap((payload: { customerCaseId: string; email: string; groupId: string; confirmId: string }) =>
          this.cleverreachService.send(payload)
        )
      )
      .subscribe();
  }

  private getYextScInfos() {
    this.yextFacade.infos$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((infos: string) => !!infos),
        take(1)
      )
      .subscribe((infos: string) => {
        if (!/^[0-9]*$/.test(infos)) {
          if (isDevMode) {
            // eslint-disable-next-line no-console
            console.warn(`Currently only supports kst numbers but '${infos}' was given`);
          }
          return;
        }

        this.serviceCenterService
          .getServiceCenters([infos])
          .pipe(take(1))
          .subscribe((res: ServiceCenter[]) => {
            this.document
              .querySelector(`#${AnalyticsEventContainerComponent.ANALYTICS_EVENT_CONTAINER_ID}`)
              .dispatchEvent(
                new CustomEvent<{ address: ServiceCenterAddress }>(OptimizelyFeEvent.GET_YEXT_SC_INFOS_SUCCESS, {
                  detail: { address: res?.[0].address }
                })
              );
          });
      });
  }

  private offerVAP(event: CustomEvent<VAPsTriggerEventData>): void {
    this.customerCaseFacade.offerVAP(event.detail.product, event.detail.flags || []);
  }

  private addVAP(event: CustomEvent<VAPsTriggerEventData>): void {
    this.toggleVAP(event, true).subscribe();
  }

  private removeVAP(event: CustomEvent<VAPsTriggerEventData>): void {
    this.toggleVAP(event, false).subscribe();
  }

  // This does not yet support the new logic used in facade
  //  (already added, cant be added, already removed cant be removed, ...)!
  // This does not yet support wiper for repair!
  //  (see line 246 - ...product === AdditionalProduct.WIPER && !isReplaceCase)
  private toggleVAP(event: CustomEvent<VAPsTriggerEventData>, add: boolean): Observable<boolean> {
    const flags: VAPsEventFlag[] = event.detail?.flags || [];
    const product: AdditionalProduct = event.detail?.product;

    return this.customerCaseFacade.customerCaseId$.pipe(
      withLatestFrom(this.damageFacade.requiredServiceIsReplace$, this.insuranceFacade.insuranceResponse$),
      take(1),
      mergeMap(
        ([customerCaseId, isReplaceCase, insuranceResponse]: [
          string,
          boolean,
          InsuranceResponse
        ]): Observable<boolean> => {
          if (add && product === AdditionalProduct.WIPER && !isReplaceCase)
            return of(
              this.dispatchVAPsFailureEvent(
                customerCaseId,
                AdditionalProduct.WIPER,
                new Error(MESSAGE_ERROR_WIPER_NOT_ALLOWED_IN_REPAIR_CASE),
                add,
                flags
              )
            );

          const productType: AdditionalProduct = product;
          // This validation check could be removed, to allow unknown products
          if (!Object.values<string>(AdditionalProduct).includes(product)) {
            return of(
              this.dispatchVAPsFailureEvent(
                customerCaseId,
                productType,
                new Error(MESSAGE_ERROR_PRODUCT_TYPE_INVALID),
                add,
                flags
              )
            );
          }

          const serviceCallObservable = add
            ? this.customerCaseService.addProduct$(customerCaseId, productType, {
                hsn: insuranceResponse?.hsn,
                tsn: insuranceResponse?.tsn
              })
            : this.customerCaseService.removeProduct$(customerCaseId, productType);

          return serviceCallObservable.pipe(
            map(() => this.dispatchVAPsSuccessEvent(customerCaseId, productType, add, flags)),
            catchError((error: UnifiedError) =>
              of(this.dispatchVAPsFailureEvent(customerCaseId, productType, error, add, flags))
            )
          );
        }
      )
    );
  }

  private dispatchVAPsSuccessEvent(
    customerCaseId: string,
    product: AdditionalProduct,
    add: boolean,
    flags: VAPsEventFlag[]
  ): boolean {
    const flagsExtended = [...flags, VAPsEventFlag.TRIGGERED_BY_AB_TEST];
    if (add) {
      this.customerCaseFacade.addVAPSuccess(product, customerCaseId, flagsExtended);
    } else {
      this.customerCaseFacade.removeVAPSuccess(product, customerCaseId, flagsExtended);
    }

    return this.document
      .querySelector(`#${AnalyticsEventContainerComponent.ANALYTICS_EVENT_CONTAINER_ID}`)
      .dispatchEvent(
        new CustomEvent<VAPsSuccessEventData>(
          add ? OptimizelyFeEvent.VAPS_ADD_SUCCESS : OptimizelyFeEvent.VAPS_REMOVE_SUCCESS,
          {
            detail: {
              product,
              customerCaseId,
              flags: flagsExtended
            }
          }
        )
      );
  }

  private dispatchVAPsFailureEvent(
    customerCaseId: string,
    product: AdditionalProduct,
    error: UnifiedError,
    add: boolean,
    flags: VAPsEventFlag[]
  ): boolean {
    const flagsExtended = [...flags, VAPsEventFlag.TRIGGERED_BY_AB_TEST];
    const errorMessageExtended =
      (add ? "ADD" : "REMOVE") + " of product " + product + " failed: " + errorToString(error);

    if (add) {
      this.customerCaseFacade.addVAPFailure(product, new Error(errorMessageExtended), customerCaseId, flagsExtended);
    } else {
      this.customerCaseFacade.removeVAPFailure(product, new Error(errorMessageExtended), customerCaseId, flagsExtended);
    }

    return this.document
      .querySelector(`#${AnalyticsEventContainerComponent.ANALYTICS_EVENT_CONTAINER_ID}`)
      .dispatchEvent(
        new CustomEvent<VAPsFailureEventData>(
          add ? OptimizelyFeEvent.VAPS_ADD_FAILURE : OptimizelyFeEvent.VAPS_REMOVE_FAILURE,
          {
            detail: {
              product,
              customerCaseId,
              errorMessage: errorMessageExtended,
              flags: flagsExtended
            }
          }
        )
      );
  }

  private addEventHandler() {
    const eventService: AnalyticsEventService = this.analyticsEventService;
    eventService.addEventHandler(OptimizelyFeEvent.BI_PROCESSOR, this.sendBiProcessorTracking.bind(this));
    eventService.addEventHandler(OptimizelyFeEvent.CLEVERREACH, this.sendCleverreachTracking.bind(this));
    eventService.addEventHandler(OptimizelyFeEvent.GET_YEXT_SC_INFOS, this.getYextScInfos.bind(this));
    eventService.addEventHandler(OptimizelyFeEvent.VAPS_OFFER, this.offerVAP.bind(this));
    eventService.addEventHandler(OptimizelyFeEvent.VAPS_ADD, this.addVAP.bind(this));
    eventService.addEventHandler(OptimizelyFeEvent.VAPS_REMOVE, this.removeVAP.bind(this));
  }

  private removeEventHandler() {
    const eventService: AnalyticsEventService = this.analyticsEventService;
    eventService.removeEventHandler(OptimizelyFeEvent.BI_PROCESSOR, this.sendBiProcessorTracking.bind(this));
    eventService.removeEventHandler(OptimizelyFeEvent.CLEVERREACH, this.sendCleverreachTracking.bind(this));
    eventService.removeEventHandler(OptimizelyFeEvent.GET_YEXT_SC_INFOS, this.getYextScInfos.bind(this));
    eventService.removeEventHandler(OptimizelyFeEvent.VAPS_OFFER, this.offerVAP.bind(this));
    eventService.removeEventHandler(OptimizelyFeEvent.VAPS_ADD, this.addVAP.bind(this));
    eventService.removeEventHandler(OptimizelyFeEvent.VAPS_REMOVE, this.removeVAP.bind(this));
  }
}
