<div class="invisible flex flex-row t:visible">
  @for (item of data?.currentNavPath?.path; track item.href; let i = $index; let last = $last) {
    <cg-breadcrumbs-text-link
      class="z-[1001] inline-flex flex-row text-cg-anthracite"
      [ngClass]="color === 'white' ? 'white' : 'black'"
      [content]="{
        id: i,
        link: {
          text: item.text,
          href: item.href,
          title: item.title,
          target: item.target,
          routerLink: true
        },
        icon: icon
      }"
      [last]="last"
    >
    </cg-breadcrumbs-text-link>
  }
</div>
