import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, inject, OnInit } from "@angular/core";
import { ChannelSwitchFacade, ProcessFacade } from "@cg/olb/state";
import { TranslocoPipe } from "@jsverse/transloco";
import { TrackingService } from "@cg/analytics";
import { Icon } from "@cg/content-api/typescript-interfaces";
import { IconComponent } from "@cg/core/ui";
import { cccAgentIcon } from "@cg/icon";
import { OlbHeadlineComponent, ScrollService } from "@cg/olb/shared";
import { ExitNodeResolverService } from "../../../../services/exit-node-resolver.service";
import { channelSwitchAnimation } from "../../animations/channel-switch.animation";
import { ChannelSwitchBaseComponent } from "../channel-switch-base/channel-switch-base.component";

@Component({
  selector: "cg-channel-switch-connected-to-agent",
  templateUrl: "./channel-switch-connected-to-agent.component.html",
  styleUrls: ["./channel-switch-connected-to-agent.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [channelSwitchAnimation],
  standalone: true,
  imports: [TranslocoPipe, OlbHeadlineComponent, IconComponent]
})
export class ChannelSwitchConnectedToAgentComponent extends ChannelSwitchBaseComponent implements OnInit {
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly processFacade = inject(ProcessFacade);
  protected readonly exitNodeResolver = inject(ExitNodeResolverService);
  protected readonly trackingService = inject(TrackingService);
  protected readonly scrollService = inject(ScrollService);
  private readonly channelSwitchFacade = inject(ChannelSwitchFacade);

  public cccAgentIcon: Icon = cccAgentIcon;
  @HostBinding("@fadeIn") protected fadeIn = true;
  private readonly TIMEOUT_IN_MILISECONDS = 20000;

  public async ngOnInit() {
    await super.ngOnInit();
    setTimeout(() => {
      this.channelSwitchFacade.setIsCallbackLate(true);
    }, this.TIMEOUT_IN_MILISECONDS);
  }
}
