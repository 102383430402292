import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { CgxList, CgxParagraph } from "@cg/content-api/typescript-interfaces";
import { IconComponent } from "../icon/icon.component";
import { ParagraphComponent } from "../paragraph/paragraph.component";

@Component({
  selector: "cgx-list",
  templateUrl: "./list.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ParagraphComponent, ParagraphComponent, IconComponent]
})
export class ListComponent {
  public content = input.required<CgxList & { paragraphItems: CgxParagraph[] }, CgxList>({
    transform: (value: CgxList) => ({
      ...value,
      paragraphItems: value.items.map((item: string) => ({
        alignment: "left",
        condensed: false,
        size: "l",
        text: item,
        ngTemplate: "cgxParagraph"
      }))
    })
  });

  public generateNumericListParagraph(index: number): CgxParagraph {
    return {
      alignment: "left",
      condensed: false,
      size: "l",
      text: `${index + 1}.`,
      ngTemplate: "cgxParagraph"
    };
  }
}
